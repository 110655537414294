import React, { useState, useEffect,useMemo } from "react";
import $ from 'jquery';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'; // Import datetime picker JS
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css'; // Import datetime picker CSS

import Axios from "axios";
const moment = require('moment')
import Select from "react-select";
import Popup from "reactjs-popup";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonConstants } from "../../../_constants/common.constants";


toast.configure();


  const EditFilter = ({ options,data,AppliedFilter,CloseModel,IsSaveButton,ContactTags,ClientID,UserID,CUserID,FilterID,UpdateFilterDropDown,ApplySaveAsFilterProperties,ContactStatus,IsDefault}) => {

    const initialFilterProperty = {
      _id:Date.now(),
      FilterPropertyName: options[0]?.FieldValue || '',
      ExpressionName: options[0]?.Options[0]?.value || '',
      IsCustom: options[0]?.IsCustom || false,
      IsSelect: options[0]?.IsSelect || false,
      FilterPropertyValue1: '',
      FilterPropertyValue2: '',
      selectValue: null,
      CustomFieldID: options[0]?.IsCustom ? options[0]?.CustomFieldID : null
    };
  
    const [filterProperties, setFilterProperties] = useState([initialFilterProperty]);
    const [TimeZone] = useState([
      {value:"AT",label:"AT"},
      {value:"CA",label:"CA"},
      {value:"CT",label:"CT"},
      {value:"ET",label:"ET"},
      {value:"MT",label:"MT"},
      {value:"PT",label:"PT"},
    ]);

    const [LastCallResultDropDown, SetLastCallResultDropDown] = useState([
      { label: 'Never Been Called', value: 'never been called' },
      { label: 'Call Back Later', value: 'call back later' },
      { label: 'Called', value: 'called' },
      { label: 'Voicemail 1', value: 'voicemail 1' },
      { label: 'Voicemail 2', value: 'voicemail 2' },
      { label: 'Voicemail 3', value: 'voicemail 3' },
      { label: 'Email Only No Voicemail', value: 'email only no voicemail'},
      { label: 'Not Available', value: 'not available' },
      { label: 'Leave No Message / Email', value: 'leave no message / email'},
      { label: 'Asked for Info / Follow Up Later', value: 'asked for info/follow up later'},
      { label: 'Not Interested', value: 'not interested' },
      { label: 'Not The Right Person', value: 'not the right person' },
      { label: 'Bad Data', value: 'bad data' },
      { label: 'Prospect Hang Up', value: 'prospect hang up' },
      { label: 'Registry Complete + Gen Info', value: 'registry complete + gen info' },
      { label: 'Meeting Set', value: 'meeting set' },

    ]);
    const CallResultArr = [
      { label: 'Never Been Called', value: 'Never Been Called' },
      { label: 'Call Back Later', value: 'Call Back Later' },
      { label: 'Called', value: 'Called' },
      { label: 'Voicemail 1', value: 'Voicemail 1' },
      { label: 'Voicemail 2', value: 'Voicemail 2' },
      { label: 'Voicemail 3', value: 'Voicemail 3' },
      { label: 'Email Only No Voicemail', value: 'Email Only No Voicemail'},
      { label: 'Not Available', value: 'Not Available' },
      { label: 'Leave No Message / Email', value: 'Leave No Message / Email'},
      { label: 'Asked for Info / Follow Up Later', value: 'Asked for Info / Follow Up Later'},
      { label: 'Not Interested', value: 'Not Interested' },
      { label: 'Not The Right Person', value: 'Not The Right Person' },
      { label: 'Bad Data', value: 'Bad Data' },
      { label: 'Prospect Hang Up', value: 'Prospect Hang Up' },
      { label: 'Registry Complete + Gen Info', value: 'Registry Complete + Gen Info' },
      { label: 'Meeting Set', value: 'Meeting Set' },

    ]
    const [ContactTag, setContactTag] = useState(ContactTags);
    const [ClientId,SetClientId] = useState(ClientID);
    const [UserId,SetUserId] = useState(UserID);
    const [FilterId,SetFilterId] = useState(FilterID);
    const [DeletedProperties,SetDeletedProperties] = useState([]);
    const [SaveAsPopup,SetSaveAsPopup] = useState(false)
    const [isFilterNameValid, setIsFilterNameValid] = useState(true);
    const [filterName, setFilterName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  
    // Calculate the filtered properties memoized value
    const filteredProperties = useMemo(() => {
      let FilterProps;
      let FilterData =  data.map((dataItem) => {
        const IsSelect =
        dataItem.FilterPropertyName === 'LastCallResult' ||
        dataItem.FilterPropertyName === 'TimeZone' ||
        dataItem.FilterPropertyName === 'ContactTag' ||
        dataItem.FilterPropertyName === 'CallResult' ||
        dataItem.FilterPropertyName === 'ContactStatus';
        
        let filterPropertyValue1 = dataItem.FilterPropertyValue1;
        
        if (IsSelect) {
          // 
          if(dataItem.FilterPropertyName === 'ContactTag'){
            filterPropertyValue1 = dataItem.DefaultSelectIDs
          }else if(dataItem.FilterPropertyName === 'ContactStatus'){
            
            
            const filterPropertyValuePairs = filterPropertyValue1
              .split(",")
              .map((value) => {
                const statusObject = ContactStatus.find((status) => String(status.value) === value);
                
                // Assuming you want to use the name property as the label
                return {
                  value: value.trim(),
                  label: statusObject ? statusObject.label : value.trim(), // Use name property as label if found
                };
              });
              
              filterPropertyValue1 =  filterPropertyValuePairs;
          }else{
            if(filterPropertyValue1?.trim() !== ""){
               // Convert comma-separated values into an array of objects
              filterPropertyValue1 = filterPropertyValue1.split(',').map((value) => ({value: value.trim(),label: value.trim()}));
              // filterPropertyValue1.push({ label: "Called", value: "called" });
            }else{
              filterPropertyValue1 = []
            }
              
          }
       
        }
        
        return {
          _id:dataItem._id || 0,
          FilterPropertyName: dataItem.FilterPropertyName,
          ExpressionName: dataItem.ExpressionName,
          IsCustom: dataItem.FieldType === 'Text',
          IsSelect: IsSelect,
          IsCustom:dataItem.IsCustom,
          FilterPropertyValue1: dataItem.FilterPropertyValue1,
          FilterPropertyValue2: dataItem.FilterPropertyValue2,
          selectValue: filterPropertyValue1,
          IsDefault:dataItem.IsDefault
        };
      });
      console.log(IsDefault)
      if(IsDefault){
        FilterProps = FilterData.filter(item => !(item.FilterPropertyName === "ContactTag" && item.ExpressionName === "NotEqualTo"));
        return FilterProps;
      }
      return FilterData

    }, [data]);

        // Track the number of elements in filteredProperties
  const [filteredPropertiesCount, setFilteredPropertiesCount] = useState(filterProperties.length);
  
    // Update filterProperties and ContactTag only when filteredProperties or ContactTags changes
    useEffect(() => {
      setFilterProperties(filteredProperties);
      setContactTag(ContactTags);
      SetFilterId(FilterID)
      console.log({ options,data,AppliedFilter,CloseModel,IsSaveButton,ContactTags,ClientID,UserID,CUserID,FilterID,UpdateFilterDropDown,ApplySaveAsFilterProperties,ContactStatus,IsDefault})
    }, [filteredProperties, ContactTags,FilterID]);

    useEffect(()=>{
      filterProperties.forEach((el)=>{
        
        $(`.datetimepicker${el._id}`).each(function() {
          
          $(this).datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
          });
        });
      })
      
    },[filteredPropertiesCount,filterProperties])

  
    const addFilterProperty = () => { setFilterProperties([...filterProperties, { ...initialFilterProperty }]); };
  
    const removeFilterProperty = (index) => {
      if (filterProperties.length === 1) {
        toast.error(<div className="toastsize"> Filter<br />Required filter property at least one</div>);
        return;
      }
      const updatedFilterProperties = [...filterProperties];
      const deletedId = updatedFilterProperties[index]._id; // Access the _id of the deleted object
      const deletedFilterProperty = updatedFilterProperties.splice(index, 1); // Get the deleted object
      SetDeletedProperties((prevDeletedProperties) => [...prevDeletedProperties, deletedId]);
      setFilterProperties(updatedFilterProperties);
    };
    
    const handleFieldChange = (index, selectedFieldValue) => {
      const updatedFilterProperties = [...filterProperties];
      updatedFilterProperties[index].FilterPropertyName = selectedFieldValue;
      // Retrieve the option for the selected field
      const selectedOption = options.find((option) => option.FieldValue === selectedFieldValue);
      
      
      // Update properties based on the selected field value
      updatedFilterProperties[index].IsCustom = selectedOption?.IsCustom || false;
      updatedFilterProperties[index].IsSelect = selectedOption?.IsSelect || false;
      updatedFilterProperties[index].CustomFieldID = selectedOption?.IsCustom ? selectedOption.CustomFieldID : null;
      
      // Automatically update expression based on the selected field
      updatedFilterProperties[index].ExpressionName = selectedOption?.Options[0]?.value || '';
      
      // Handle special case for "IsBlacklisted"
      if (selectedFieldValue === "IsBlacklisted") {
        updatedFilterProperties[index].FilterPropertyValue1 = "y";
      }
      
      setFilterProperties(updatedFilterProperties);
    };
    
    const handleSelectChange = (index, selectedOption) => {
      const updatedFilterProperties = [...filterProperties];
      
      updatedFilterProperties[index].selectValue = selectedOption;
    
      // Check if selectedOption is defined before accessing its properties
      if (selectedOption) {
        // Create a comma-separated string from selected values
        const selectedValues = selectedOption.map((option) => option.value);
        updatedFilterProperties[index].FilterPropertyValue1 = selectedValues.join(',');
      } else {
        // Handle the case when selectedOption is undefined (e.g., no options selected)
        updatedFilterProperties[index].FilterPropertyValue1 = '';
      }
    
      setFilterProperties(updatedFilterProperties);
    };

    //Data model method
    const DataModel = () => {
       // Check if any search value is empty excluding "ContactTag" properties
        const hasEmptySearchValue = filterProperties.some((filterProperty) => {
          // Skip validation for properties with FilterPropertyName as "ContactTag"
          if (filterProperty.FilterPropertyName === "ContactTag" || filterProperty.FilterPropertyName === "TimeZone") {
            return false;
          }
          if(filterProperty.ExpressionName === "IsBlank" || filterProperty.ExpressionName === "IsNotBlank"){
            filterProperty.FilterPropertyValue1 = "y"
          }

          if(filterProperty.FilterPropertyName === "LastSendDate" || 
            filterProperty.FilterPropertyName === "EventStartDate" ||
            filterProperty.FilterPropertyName === "LastValidationDate" || 
            filterProperty.FilterPropertyName === "CreatedDate" || 
            filterProperty.FilterPropertyName === "FollowUpDate"|| 
            filterProperty.FilterPropertyName === "LastCallDate"){
              if(filterProperty.ExpressionName === "between" ){
                filterProperty.FilterPropertyValue1=document.getElementById("searchvalue" + filterProperty._id).value;
                filterProperty.FilterPropertyValue2=document.getElementById("EndsWith" + filterProperty._id).value;
              }else if(filterProperty.ExpressionName != "IsBlank"  && filterProperty.ExpressionName != "IsNotBlank"){
                filterProperty.FilterPropertyValue1 = document.getElementById("searchvalue" + filterProperty._id).value;
              }
            }
          // Check for empty FilterPropertyValue1 for other properties
          return filterProperty.FilterPropertyValue1 === "";
        });
        if (hasEmptySearchValue) {
          toast.error(<div className="toastsize">Filter <br/> Property value can not be an empty</div>);
          return;
        }

        // Create an array of filter objects
       const filters = filterProperties.map((filterProperty) => {
          const selectedOption = options.find((option) => option.FieldValue === filterProperty.FilterPropertyName);
          
          return {
            _id: filterProperty._id,
            FilterPropertyName: filterProperty.FilterPropertyName,
            ExpressionName: filterProperty.ExpressionName,
            FilterPropertyValue1: filterProperty.FilterPropertyValue1,
            FilterPropertyValue2: filterProperty.FilterPropertyValue2,
            FieldType: "empty", // You can set the FieldType based on your requirements
            IsCustom: selectedOption.IsCustom, // Set IsCustom based on the field's IsCustom value
            IsSelect: selectedOption.IsSelect, // Include IsSelect in the filter object
            CustomFieldID: selectedOption?.CustomFieldID || null
          };
        });
        
        return filters;
    }

    //Filter validations
const FilterValidation = () => {
  // Initialize an array to store validation results
  let validationArr = [];
  filterProperties.forEach((newvalue) => {
    if(newvalue.FilterPropertyName === "LastSendDate" || 
      newvalue.FilterPropertyName === "EventStartDate" ||
      newvalue.FilterPropertyName === "LastValidationDate" || 
      newvalue.FilterPropertyName === "CreatedDate" || 
      newvalue.FilterPropertyName === "FollowUpDate"|| 
      newvalue.FilterPropertyName === "LastCallDate"){
        if(newvalue.ExpressionName === "between" ){
          newvalue.FilterPropertyValue1=document.getElementById("searchvalue" + newvalue._id).value;
          newvalue.FilterPropertyValue2=document.getElementById("EndsWith" + newvalue._id).value;
        }else if(newvalue.ExpressionName != "IsBlank"  && newvalue.ExpressionName != "IsNotBlank"){
          newvalue.FilterPropertyValue1 = document.getElementById("searchvalue" + newvalue._id).value;
        }
      }
    const selectedOption = options.find((option) => option.FieldValue === newvalue.FilterPropertyName);
    newvalue.FilterPropertyName= newvalue.FilterPropertyName,
    newvalue.ExpressionName= newvalue.ExpressionName,
    newvalue.FilterPropertyValue1= newvalue.FilterPropertyValue1,
    newvalue.FilterPropertyValue2= newvalue.FilterPropertyValue2,
    newvalue.FieldType= "empty", // You can set the FieldType based on your requirements
    newvalue.IsCustom= selectedOption.IsCustom, // Set IsCustom based on the field's IsCustom value
    newvalue.IsSelect= selectedOption.IsSelect, // Include IsSelect in the filter object
    newvalue.CustomFieldID= selectedOption?.CustomFieldID || null
    
    if (!newvalue.IsBool && !newvalue.IsSelect) {
      
      // Check if the search value is empty
      if (newvalue.FilterPropertyValue1 === "") {
        validationArr.push(false);
      } else {
        validationArr.push(true);
      }
      
    
      
      // Check if the expression is "between" and validate the second value
      if (newvalue.ExpressionName === "between" && !newvalue.IsSelect) {
        
        // Check if the second value is empty
        if (newvalue.FilterPropertyName2 === "") {
          validationArr.push(false);
        } else {
          validationArr.push(true);
        }
      }
    }else if(newvalue.FilterPropertyName === "LastCallResult"){
      if(newvalue.FilterPropertyValue1?.trim() === ""){
        validationArr.push(false);

      }else{
        validationArr.push(true);

      }
    }
  });
  
  // Check if any validation result is false (i.e., there is an invalid filter condition)
  return validationArr.includes(false);
};

    // Apply Filter 
      const handleSubmit = () => {
        if (!FilterValidation()) {
          let FilterPropertiesData = DataModel()
          AppliedFilter(FilterPropertiesData,FilterID)
        }else{
          toast.error(<div className="toastsize"> Filter<br/>Property value cannot be empty</div>);
        }
        
      };

      //Save Filter
      const SaveFilter = async() => {
        if (!FilterValidation()) {
          let FilterPropertiesData = DataModel()
          if(FilterPropertiesData != undefined && FilterPropertiesData != null){
            const InputParameter = {
              ClientID:ClientId,
              UserID:UserId,
              LastUpdatedBy:CUserID,
              FilterID:FilterId,
              FilterParameters: FilterPropertiesData,
              DeletedProperties:DeletedProperties,
              LastUpdatedDate:new Date()
          }
          let res = await Axios({
            url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/UpdateColdCallingContactFilter",
            method: "POST",
            data: InputParameter,
          });

          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Filter <br/> Filter updated successFully.</div>)
            CloseModelBox(false)
            UpdateFilterDropDown(true)
          AppliedFilter(FilterPropertiesData,FilterID)

          } else {
            toast.error("Something went wrong in filter name exists api");
          }
          }
         
        }else{
          toast.error(<div className="toastsize"> Filter<br/>Property value cannot be empty</div>);
        }
      }

      //Save As Filter
      const OpenSaveAsFilter = () => {
        SetSaveAsPopup(true)
        // CloseModelBox(false)
      }

      const handleFilterNameBlur = async () => {
        const trimmedFilterName = filterName.trim();
        
        if (trimmedFilterName === "") {
          setErrorMessage("Please enter filter name");
          setIsFilterNameValid(false);
        } else {
          const isValid = await CheckFilterName(trimmedFilterName);
          setIsFilterNameValid(isValid);
          
          if (!isValid) {
            setErrorMessage("Contact filter already exists.");
          setIsFilterNameValid(false);

          } else {
            setErrorMessage("");
          setIsFilterNameValid(true);
          }
        }
      };

      const CheckFilterName = async (filterName) => {
        try {
          const InputParameters = {
            ClientID: ClientID, // Assuming ClientID is defined elsewhere
            FilterName: filterName,
               };
      
          const response = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/CheckColdCallingContactFilterName", InputParameters);
      
          if (response.data.StatusMessage === "SUCCESS") {
            

            return response.data.Data <= 0;
          }
      
          toast.error("Something went wrong in filter name exists API");
        } catch (error) {
          console.error("An error occurred:", error);
        }
      
        return false;
      };

             //Close the edit filter model
      const CloseModelBox = () => {
              CloseModel(false)
      }

      //Save As 
      const SaveAsFilter = async () => {
        try {
          const trimmedFilterName = filterName.trim();
        
          if (trimmedFilterName === "") {
            setErrorMessage("Please enter filter name");
            setIsFilterNameValid(false);
          } else {
            const isValid = await CheckFilterName(trimmedFilterName);
            setIsFilterNameValid(isValid);
            
            if (!isValid) {
              setErrorMessage("Contact filter already exists.");
            setIsFilterNameValid(false);
  
            } else {
              setErrorMessage("");
            setIsFilterNameValid(true);
            if ( DataModel() ) {
              const Mappdata = DataModel().map((el) => {
                return {
                  FilterPropertyName: el.FilterPropertyName,
                  ExpressionName: el.ExpressionName,
                  FilterPropertyValue1: el.FilterPropertyValue1,
                  FilterPropertyValue2: el.FilterPropertyValue2,
                  FieldType: el.FieldType,
                  IsCustom:el.IsCustom,
                  IsSelect:el.IsSelect,
                  CustomFieldID: el.CustomFieldID || undefined
                };
              });
        
              const InputParameters = {
                ClientID: ClientID, // Assuming ClientID is defined elsewhere
                FilterName: filterName,
                FilterParameters: Mappdata,
                CreatedDate: new Date(),
                CreatedBy:CUserID,
                UserID: UserID, // Assuming UserID is defined elsewhere
              };
        
              try {
                const res = await Axios.post(
                  CommonConstants.MOL_APIURL + "/cold_calling_contacts/SaveColdCallingContactFilter",
                  InputParameters
                );
        
                if (res.data.StatusMessage === "SUCCESS") {
                  ApplySaveAsFilterProperties(res.data.Data?._id,Mappdata)
                  SetSaveAsPopup(false);
                  setFilterName("");
                  CloseModel(false);
                  toast.success(
                    <div className="toastsize">Filter<br />Filter Added Successfully.</div>
                  );
                } else {
                  toast.error("Error in saving the filter");
                }
              } catch (error) {
                console.error("An error occurred while saving the filter:", error);
                toast.error("Error in saving the filter");
              }
            }
            }
          }
      
         
        } catch (error) {
          console.error("An error occurred while handling filter name blur:", error);
          toast.error("Error in handling filter name blur");
        }
      };

      //Filter Date Formattor 
      const FilterDateFormattor = (date) => {
        if (date instanceof Date) {
          date = moment(date).format("MM/DD/YYYY"); // Convert to 'DD/MM/YYYY' format
        }
        
        if (date?.includes("/")) {
          return moment(date)?.format("MM/DD/YYYY");
        } else if (date?.includes("-")) {
          const [year, month, day] = date?.split("-");
          return `${month}/${day}/${year}`;
        } else {
          return null;
        }
      };

    
    return (
        <>
                                          <div className="p-3">
                                            <div className="modal-body">
                                             
                                              <div className="table-bordered table-scroll"> 
                                                  <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell> Property{" "} </TableCell>
                                                        <TableCell align="right"> {" "}</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {filterProperties.map((filterProperty, index) => (
                                                      <TableRow sx={{"&:last-child td, &:last-child th":{ border: 0 },}} key={filterProperty?._id}>
                                                            
                                                            <TableCell align="right">
                                                            <select
                                                                className="form-control m-input" autocomplete="off"
                                                                value={filterProperty.FilterPropertyName}
                                                                onChange={(e) => handleFieldChange(index, e.target.value)}
                                                                // disabled={IsDefault}
                                                                >
                                                                {options.map((option) => (
                                                                    <option key={option.FieldName} value={option.FieldValue}>{option.ColumnName === "Last Call Result" ? "Disposition Status"   : option.ColumnName === "PRL Lead ID (SalesForce ID)"
                                                                      ? "External ID"  : option.ColumnName}</option>
                                                                ))}
                                                            </select>
                                                            </TableCell>
                                                            <TableCell>
                                                              {filterProperty.IsCustom == true ? (
                                                                <select className="form-control m-input" autocomplete="off">
                                                                  <option id="text" value="Text"> {" "}Text </option>
                                                                </select>
                                                              ) : null} 
                                                            </TableCell>
                                                            <TableCell align="right">
                                                              
                                                            <select
                                                                className="form-control m-input" autocomplete="off"
                                                                value={filterProperty.ExpressionName}
                                                                onChange={(e) => {
                                                                    const updatedFilterProperties = [...filterProperties];
                                                                    updatedFilterProperties[index].ExpressionName = e.target.value;
                                                                    if(e.target.value == "IsBlank" || e.target.value == "IsNotBlank"){
                                                                      updatedFilterProperties[index].FilterPropertyValue1 = "y"
                                                                    }
                                                                    setFilterProperties(updatedFilterProperties);
                                                                }}
                                                                >
                                                                {options.find((option) =>option.FieldValue === filterProperty.FilterPropertyName )
                                                                    ?.Options.map((expressionOption) => (
                                                                    <option key={expressionOption.value} value={expressionOption.value}> {expressionOption.label} </option>
                                                                    ))}
                                                                </select>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                            {["True", "False", "IsBlank", "IsNotBlank"].includes(filterProperty.ExpressionName) ? (
                                                            <span></span> ) : filterProperty.ExpressionName === "between" ? (
                                                            <>
                                                                {filterProperty.IsSelect ? (
                                                                  <>
                                                                  
                                                                <Select
                                                                    options={ filterProperty.FilterPropertyName === "TimeZone" ? TimeZone :
                                                                      filterProperty.FilterPropertyName === "LastCallResult" ? LastCallResultDropDown :
                                                                      filterProperty.FilterPropertyName === "ContactTag" ? ContactTag :
                                                                      filterProperty.FilterPropertyName === "CallResult" ? CallResultArr : 
                                                                      []}
                                                                    isMulti
                                                                    value={filterProperty.selectValue}
                                                                    onChange={(selectedOption) =>{
                                                                      handleSelectChange(index, selectedOption)
                                                                    }}
                                                                    />
                                                                  </>
                                                                    
                                                                ) : (
                                                                <div className="d-flex gap-10">

                                                                  {
                                                                      filterProperty.FilterPropertyName === "LastSendDate" || 
                                                                      filterProperty.FilterPropertyName === "EventStartDate" ||
                                                                      filterProperty.FilterPropertyName === "LastValidationDate" || 
                                                                      filterProperty.FilterPropertyName === "CreatedDate" || 
                                                                      filterProperty.FilterPropertyName === "FollowUpDate"|| 
                                                                      filterProperty.FilterPropertyName === "LastCallDate" ? 
                                                                      <>
                                                                           <div className="col-lg-6 timedatepikker date-input">
                                                                                <input
                                                                                    type='text'
                                                                                    defaultValue={FilterDateFormattor(filterProperty.FilterPropertyValue1)}
                                                                                    id={"searchvalue" + filterProperty._id}
                                                                                    onChange={(e) => {
                                                                                      const updatedFilterProperties = [...filterProperties];
                                                                                      updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                                      setFilterProperties(updatedFilterProperties);
                                                                                    }}
                                                                                    className={`datetimepicker${filterProperty._id} form-control`}
                                                                                  />
                                                                            </div>
                                                                            <div className="col-lg-6 timedatepikker date-input">
                                                                              <input
                                                                                  type='text'
                                                                                  defaultValue={FilterDateFormattor(filterProperty.FilterPropertyValue2)}
                                                                                  id={"EndsWith" + filterProperty._id}
                                                                                  onChange={(e) => {
                                                                                    const updatedFilterProperties = [...filterProperties];
                                                                                    updatedFilterProperties[index].FilterPropertyValue2 = e.target.value;
                                                                                    setFilterProperties(updatedFilterProperties);
                                                                                  }}
                                                                                  className={`datetimepicker${filterProperty._id} form-control`}
                                                                                />
                                                                            </div>

                                                                          {/* <ReactDatePicker
                                                                           
                                                                            selected={FilterDateFormattor(filterProperty.FilterPropertyValue1)}
                                                                            className="form-control datepicker"
                                                                            onChange={(e) => {
                                                                                  const updatedFilterProperties = [...filterProperties];
                                                                                  updatedFilterProperties[index].FilterPropertyValue1 = e;
                                                                                  setFilterProperties(updatedFilterProperties);
                                                                                  }}
                                                                            dateFormat="MM/dd/yyyy" // Specify the format you want
                                                                            
                                                                          />
                                                                          <ReactDatePicker
                                                                             
                                                                              selected={FilterDateFormattor(filterProperty.FilterPropertyValue2)}
                                                                              className="form-control datepicker"
                                                                              onChange={(e) => {
                                                                                    const updatedFilterProperties = [...filterProperties];
                                                                                    updatedFilterProperties[index].FilterPropertyValue2 = e;
                                                                                    setFilterProperties(updatedFilterProperties);
                                                                                    }}
                                                                              dateFormat="MM/dd/yyyy" // Specify the format you want
                                                                          
                                                                        /> */}
                                                                      </>
                                                                      :
                                                                      <>
                                                                         <input
                                                                      className="form-control m-input"
                                                                      type={"text"}
                                                                      value={filterProperty.FilterPropertyValue1}
                                                                      onChange={(e) => {
                                                                          const updatedFilterProperties = [...filterProperties];
                                                                          updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                          setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    />

                                                                    <input          
                                                                      className="form-control m-input"
                                                                      type={ "text"}
                                                                      value={filterProperty.FilterPropertyValue2}
                                                                      onChange={(e) => {
                                                                          const updatedFilterProperties = [...filterProperties];
                                                                          updatedFilterProperties[index].FilterPropertyValue2 = e.target.value;
                                                                          setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    />
                                                                      </>
                                                                  }
                                                                    {/* <input
                                                                      className="form-control m-input"
                                                                      type={filterProperty.FilterPropertyName ==="LastSendDate" ||filterProperty.FilterPropertyName ==="LastValidationDate" || filterProperty.FilterPropertyName === "CreatedDate" || filterProperty.FilterPropertyName === "FollowUpDate"|| filterProperty.FilterPropertyName === "LastCallDate"
                                                                          ? "date": "text"}
                                                                      value={filterProperty.FilterPropertyValue1}
                                                                      onChange={(e) => {
                                                                          const updatedFilterProperties = [...filterProperties];
                                                                          updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                          setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    />
                                                                   
                                                                    <input
                                                                      className="form-control m-input"
                                                                        type={ filterProperty.FilterPropertyName === "LastSendDate" || filterProperty.FilterPropertyName === "EventStartDate" ||
                                                                        filterProperty.FilterPropertyName ==="LastValidationDate" || filterProperty.FilterPropertyName === "CreatedDate"
                                                                        || filterProperty.FilterPropertyName === "FollowUpDate"|| filterProperty.FilterPropertyName === "LastCallDate"
                                                                        ? "date" : "text"
                                                                        }
                                                                        value={filterProperty.FilterPropertyValue2}
                                                                        onChange={(e) => { const updatedFilterProperties = [...filterProperties];
                                                                        updatedFilterProperties[index].FilterPropertyValue2 = e.target.value;
                                                                        setFilterProperties(updatedFilterProperties);
                                                                      }}
                                                                    /> */}
                                                                     
                                                                </div>
                                                                )}
                                                            </>
                                                            ) : filterProperty.IsSelect ? (
                                                              <>
                                                            <Select
                                                            options={  filterProperty.FilterPropertyName === "TimeZone" ? TimeZone :
                                                              filterProperty.FilterPropertyName === "LastCallResult" ? LastCallResultDropDown :
                                                              filterProperty.FilterPropertyName === "ContactTag" ? ContactTag :
                                                              filterProperty.FilterPropertyName === "CallResult" ? CallResultArr : 
                                                              []}
                                                                isMulti
                                                                value={filterProperty.selectValue}
                                                                onChange={(selectedOption) =>{handleSelectChange(index, selectedOption)}}
                                                            />
                                                            </>
                                                            ) : (
                                                              (
                                                                filterProperty.FilterPropertyName === "LastSendDate" || 
                                                                filterProperty.FilterPropertyName === "EventStartDate" ||
                                                                filterProperty.FilterPropertyName === "LastValidationDate" || 
                                                                filterProperty.FilterPropertyName === "CreatedDate" || 
                                                                filterProperty.FilterPropertyName === "FollowUpDate"|| 
                                                                filterProperty.FilterPropertyName === "LastCallDate" ?

                                                                <div className="col-lg-12 timedatepikker date-input">
                                                                <input
                                                                    type='text'
                                                                    defaultValue={FilterDateFormattor(filterProperty.FilterPropertyValue1)}
                                                                    id={"searchvalue" + filterProperty._id}
                                                                    onChange={(e) => {
                                                                      const updatedFilterProperties = [...filterProperties];
                                                                      updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                      setFilterProperties(updatedFilterProperties);
                                                                    }}
                                                                    onClick={(e) => {
                                                                      const updatedFilterProperties = [...filterProperties];
                                                                      updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                                      setFilterProperties(updatedFilterProperties);
                                                                    }}
                                                                    className={`datetimepicker${filterProperty._id} form-control`}
                                                                  />
                                                              </div>
                                                            //   <ReactDatePicker
                                                             
                                                            //   selected={FilterDateFormattor(filterProperty.FilterPropertyValue1)}
                                                            //   className="form-control datepicker"
                                                            //   onChange={(e) => {
                                                            //         const updatedFilterProperties = [...filterProperties];
                                                            //         updatedFilterProperties[index].FilterPropertyValue1 = e;
                                                            //         setFilterProperties(updatedFilterProperties);
                                                            //         }}
                                                            //   dateFormat="MM/dd/yyyy" // Specify the format you want
                                                              
                                                            // />

                                                              : <input
                                                              className="form-control m-input" type="text" value={filterProperty.FilterPropertyValue1}
                                                              onChange={(e) => {
                                                              const updatedFilterProperties = [...filterProperties];
                                                              updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                              setFilterProperties(updatedFilterProperties);
                                                              }} />
                                                              )
                                                            // <input
                                                            // className="form-control m-input"
                                                            //     type={
                                                            //     filterProperty.FilterPropertyName === "LastSendDate" || filterProperty.FilterPropertyName === "EventStartDate" ||
                                                            //     filterProperty.FilterPropertyName === "LastValidationDate" || filterProperty.FilterPropertyName === "CreatedDate" || filterProperty.FilterPropertyName === "FollowUpDate"|| filterProperty.FilterPropertyName === "LastCallDate" ? "date": "text"
                                                            //     }
                                                            //     value={filterProperty.FilterPropertyValue1}
                                                            //     onChange={(e) => {
                                                            //     const updatedFilterProperties = [...filterProperties];
                                                            //     updatedFilterProperties[index].FilterPropertyValue1 = e.target.value;
                                                            //     setFilterProperties(updatedFilterProperties);
                                                            //     }}
                                                            // />
                                                            )}
                                                            </TableCell>

                                                            <TableCell align="right">
                                                              {
                                                                filterProperty?.IsDefault ? <></> : <button type="button" className="btn btngroup btnhov-delete" onClick={() => removeFilterProperty(index)}>
                                                                <i className="la flaticon-delete-1"></i>
                                                              </button>
                                                              }
                                                            
                                                            </TableCell>
                                                            {/* <TableCell align="right"></TableCell> */}
                                                            {/* <TableCell align="right"></TableCell> */}
                                                          </TableRow>
                                                         ) 
                                                       )} 
                                                    </TableBody>
                                                  </Table> 
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-12 text-left">
                                                  <button
                                                    type="button"
                                                    className="btn btngroup"
                                                    onClick={addFilterProperty}
                                                  >
                                                    <i className="la la-plus la-icon-font-size-13"></i>{" "}
                                                    Add Property{" "}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="border-top modal-content text-right">
                                              <div className="row">
                                                <div className="col-lg-12 pull-right">
                                                  <button id="backtolist" className="btn btn-secondary mr-2" onClick={CloseModelBox} >
                                                    Cancel
                                                  </button>
                                                  <button id="submit" className="btn btn-primary btn-lightgreen mr-1" onClick={handleSubmit}>
                                                    Apply
                                                  </button>
                                                  {
                                                    IsDefault == false ? <button id="submit" className="btn btn-primary btn-lightgreen mr-1" onClick={SaveFilter}>
                                                    <i className="la la-save"></i>{" "}
                                                    Save
                                                  </button> : <></>
                                                  }
                                                  
                                                  <button id="submit" className="btn btn-primary btn-lightgreen mr-1"onClick={OpenSaveAsFilter} >
                                                    <i className="la la-save"></i>{" "}
                                                    Save As
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        
                                          <Popup open={SaveAsPopup}>
                                          <div className="modal-black"></div>
                                            <div className="filterPopup largerPopup1000">
                                              <div className="paddingboxTerms">
                                                <div className="modal-header py-4 px-3">
                                                  <div className="w-100 d-flex px-3">
                                                    <h5 className="mb-0">Filter</h5>
                                                    <button className="close" onClick={() => SetSaveAsPopup(false)}>
                                                      <span aria-hidden="true" className="la la-remove"></span>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="row px-4">
                                                    <div className="col-lg-8 row mb-4">
                                                      <label className="col-lg-3 col-form-label pl-0">
                                                        Filter Name
                                                      </label>
                                                      <div className="col-lg-9 d-flex">
                                                      <input
                                                          className={`form-control m-input `}
                                                          data-val-required="Please enter title"
                                                          id="FilterName"
                                                          name="FilterName"
                                                          placeholder="Filter Name"
                                                          type="text"
                                                          value={filterName}
                                                          onChange={(e) => setFilterName(e.target.value)}
                                                          onBlur={handleFilterNameBlur}
                                                        />
                                                      </div>
                                                        {errorMessage && <span style={{color:'red'}}>{errorMessage}</span>}
                                                    </div>
                                                  </div>

                                                
                                                
                                                </div>
                                                <div className="modal-footer">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => SetSaveAsPopup(false)}
                                                  >
                                                    Cancel
                                                  </button>
                                                
                                                  <button
                                                    id="m_emailInvite_submit"
                                                    href="javascript:void(0);"
                                                    className="btn btn-primary btnColor"
                                                    onClick={() => SaveAsFilter()}
                                                  >
                                                    <i className="la la-save mr-2"></i> Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Popup>
        </>
    );
};

export default EditFilter;