import React, {useState, useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import {AddDispositionsStatusPage } from '../../../user/DispositionsStatusPage/AddDispositionsStatus/AddDispositionsStatus.jsx';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../../_components/user/StickyHeader/StickyHeader.js";

export default function  DispositionsStatusTable(props) {
  const urlParams = window.location.pathname;
  const NavigationParams = urlParams === "/defaultphonesettings" ? "Default" : ""
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("DisplayName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("DisplayName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isAddingAndEdit, setIsAddingAndEdit] = useState(false);
  const [dispositionIdForEdit, setDispositionIdForEdit] = useState(null);
  const [isEditMode, setisEditMode] = useState(false);
  const [updatechild, setupdatechild] = useState(false); // State to hold data from the child
  const [PageTypeParams, SetPageTypeParams] = useState(false);
  useEffect(() => {
    document.title = 'Dispositions Status | SalesHive';
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

  
    DispositionsStatusGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage,updatechild]);

  //dispositions status get list
  const DispositionsStatusGet = (CID,UID,URole) => {
  
    let DispositionsStatusData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      PageType:NavigationParams
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusGet",
      method: "POST",
      data: DispositionsStatusData,
    });
    ApiResponse.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };
  // Callback function to update parent state
 const UpdateFromChild = (value) => {
    if(value == true){
      DispositionsStatusGet(ClientID,UserID,Role);
      setisEditMode(false)
      setIsAddingAndEdit(false)
    }
  }
  // Search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true);
      }
    }
  };
  // Delete function
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a dispositions status.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        
      
        let ContactTagData = {
          _id: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role: Role,
          ClientID:ClientID,
          UserID:UserID,
          PageType:NavigationParams
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusDelete",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Dispositions Status deleted successfully.",
                "success"
              );
              SetIsLoading(true);
              DispositionsStatusGet(ClientID,UserID,Role);
              SetPage(1)
              SetSearch("")
              document.getElementById("search").value = ""
              // props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // change page
  const HandleChangePage = (event,NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
       SetIsLoading(true);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };

  // edit button
  const EditBtn = (id) => {
    setDispositionIdForEdit(id); // Set the disposition ID to edit
    setIsAddingAndEdit(true); // Set the flag to indicate adding/editing
    setisEditMode(true)
  };
    // add button
    const AddBtn = (id) => {
      setDispositionIdForEdit(null); // Set the disposition ID to edit
      setIsAddingAndEdit(true); // Set the flag to indicate adding/editing
      setisEditMode(false)
    };

     //get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");

  let InputParameter = {
    Page: Page,
    RowsPerPage: RowsPerPage,
    Search: SearchedVal,
    Sort: true,
    Field: Field,
    SortBy: SortBy,
    Type: "User",
    ClientID: ClientID,
    UserID: UserID,
    Role: Role,
   PageType: NavigationParams
  };
  
  const ApiResponse = Axios({
    url:CommonConstants.MOL_APIURL +"/dispositionstatus/DispositionsStatusGet",
    method: "POST",
    data: InputParameter,
  });
  ApiResponse.then((Result) => {
    SetData(Result.data.PageData);
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    SetPage(1)
    SetIsLoading(false);
  });


};

  return (
    <div>
       {IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        {
          isAddingAndEdit ? 
          (       <AddDispositionsStatusPage
                isEditMode={isEditMode}
                dispositionIdForEdit ={dispositionIdForEdit}
                onUpdate={UpdateFromChild}
              />) : (
                <>
                <div className="row align-items-center border-bottom mx-0">
                    <div className="col">
                      <h4 className="headertitlebd xs-headertitle float-left ">Dispositions Status</h4>
                    </div>
                    <div className="col">
                      <div className="listing-li float-right">
                        <ul class='my-0'>
                          <li>
                            <a
                              onClick={() => {
                                AddBtn();
                      }}
                              // onClick={this.AddBtn}
                              className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            >
                              <i className="la la-icon-font-size-13 la-plus"></i>
                              <span>Add</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                <div class=''>
      <div className="row mx-0">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-4 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {
                      SortData("DisplayName");
                      setSortedColumn("DisplayName");
                    }}>
                        Display Name
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "DisplayName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "DisplayName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("DispositionsStatus");
                      setSortedColumn("DispositionsStatus");
                    }}>
                        Dispositions Status
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "DispositionsStatus" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "DispositionsStatus"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("ActionDetails.ActionName");
                      setSortedColumn("ActionDetails.ActionName");
                    }}>
                        Action 1
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "ActionDetails.ActionName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "ActionDetails.ActionName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("ActionDetails2.ActionName");
                      setSortedColumn("ActionDetails2.ActionName");
                    }}>
                        Action 2
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "ActionDetails2.ActionName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "ActionDetails2.ActionName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("ActionDetails3.ActionName");
                      setSortedColumn("ActionDetails3.ActionName");
                    }}>
                        Action 3
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "ActionDetails3.ActionName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "ActionDetails3.ActionName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("ContactStatusDetails.ContactStatusName");
                      setSortedColumn("ContactStatusDetails.ContactStatusName");
                    }}>
                       Contact Status
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContactStatusDetails.ContactStatusName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "ContactStatusDetails.ContactStatusName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("Email");
                      setSortedColumn("Email");
                    }}>
                        Email
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "Email"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("DispositionEmailDetails.DispositionEmailName");
                      setSortedColumn("DispositionEmailDetails.DispositionEmailName");
                    }}>
                      Disposition Email
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "DispositionEmailDetails.DispositionEmailName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "DispositionEmailDetails.DispositionEmailName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("VoiceMailRecordingDetails.VoiceMailRecordingName");
                      setSortedColumn("VoiceMailRecordingDetails.VoiceMailRecordingName");
                    }}>
                     VoiceMail Recording
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "VoiceMailRecordingDetails.VoiceMailRecordingName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "VoiceMailRecordingDetails.VoiceMailRecordingName"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("Note");
                      setSortedColumn("Note");
                    }}>
                       Note
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Note" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "Note"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("NextActionDetails?.NextActionName");
                      setSortedColumn("NextActionDetails?.NextActionName");
                    }}>
                       Next Action
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "NextActionDetails?.NextActionName" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "NextActionDetails?.NextActionName"  ? "active" : null} />
                          </span>
                </TableCell>

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
              Rows?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row?.DisplayName}</TableCell>
                  <TableCell>{row?.DispositionsStatus}</TableCell>
                  <TableCell>{row?.ActionDetails?.ActionName}</TableCell>
                  <TableCell>{row?.ActionDetails2?.ActionName}</TableCell>
                  <TableCell>{row?.ActionDetails3?.ActionName}</TableCell>
                  <TableCell>{row?.ContactStatusDetails?.ContactStatusName}</TableCell>
                  <TableCell>{row?.Email}</TableCell>
                  <TableCell>{row?.DispositionEmailDetails?.DispositionEmailName}</TableCell>
                  <TableCell>{row?.VoiceMailRecordingDetails?.VoiceMailRecordingName}</TableCell>
                  <TableCell>{row?.Note}</TableCell>
                  <TableCell>{row?.NextActionDetails?.NextActionName}</TableCell>
   
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                      title="Edit"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>

                    {
                      NavigationParams === "Default"? <></> : <a
                      onClick={() => {
    if (!row.IsStatusUsed) { // Only delete if IsMakeDefault is false
      DeleteBtn(row._id, row.StatusOrderDetails);
    }
  }}
  className={`btn-eyesicon ${row.IsStatusUsed ? "disabled-delete" : ""}`} // Add class for styling if disabled
  title={row.IsStatusUsed ? "Cannot delete default item" : "Delete"}
  style={{
    cursor: row.IsStatusUsed ? "not-allowed" : "pointer",
    pointerEvents: row.IsStatusUsed ? "none" : "auto", // Prevent click if IsMakeDefault is true
  }}
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                    }
          
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      </div>
     
        <div className="row pb-2 mx-0">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
        </div>
                </>
              )
        }

    </div>
  );
}
