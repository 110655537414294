import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { GetUserDetails } from "../../../_helpers/Utility";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

import Pagenations from "../../Pagenations";

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function SalesStrategistsTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("SalesHackerName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reload, SetReload] = React.useState(false);
  const [IsArchive, SetIsArchive] = React.useState(false);

  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [AID, SetAID] = React.useState("");
  const [IsLoading, SetLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("SalesHackerName");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "Sales Strategists | SalesHive";
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    SalesHackerMethod(GetUserData.ParentUserID, GetUserData.Role);
  }, [Search, Page, RowsPerPage, Reload, IsArchive]);

  //get sales hacker list
  const SalesHackerMethod = (UID, URole) => {
    SetLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
      IsArchive: IsArchive,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/sales_strategists_profile/GetSalesHackerList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetLoading(false);
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      UserID: UserID,
      Role: Role,
      IsArchive: IsArchive,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/sales_strategists_profile/GetSalesHackerList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
      SetLoading(false);
    });
  };

  //Search archive
  const ClickOnSearch = () => {
    var check = document.getElementById("include_Archive").checked;
    SetIsArchive(check);
  };

  //Find Edit
  const EditPage = (id) => {
    history.push("/editsalesstrategists", id + "");
  };

  //Handle click
  const HandleClick = (event, id) => {
    SetAID(id);
    SetAnchorEl(event.currentTarget);
  };

  const HandleClickElF = (event, id) => {
    SetAID(id);
    SetfAnchorEl(event.currentTarget); 
    setSelectedRowId(id);
  };

  
  //Handle close Second
  const HandleCloseSecond = () => {
    SetAID(null);
    SetfAnchorEl(null);
    setSelectedRowId(null);
  };

/*------------*/
  const HandleClickEl = (event, id) => {
    SetAID(id);
    SetAnchorEl(event.currentTarget); 
    setSelectedRowId(id);
  };


  const HandleCloseSecondEl = () => {
    SetAID(null);
    SetAnchorEl(null);
    setSelectedRowId(null);
  };

  /*------------ */
  //Handle close
  const HandleClose = () => {
    SetAID(null);
    SetAnchorEl(null);   
  };
  
  //Handle close one
  const HandleClickOne = (event, id) => {
    SetAID(id);
    SetfAnchorEl(event.currentTarget);
  };

  

  //Update Status
  const UpdateStatus = (ID, Flag) => {
    const InputParameters = {
      ID: ID,
      Flag: Flag,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/sales_strategists_profile/UpdateIsTwoStepVerification",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data.IsTwoStepVerification == true) {
            toast.success(
              <div className="toastsize">
                Sales strategists <br /> Two Step Verification Activated
                successfully.{" "}
              </div>
            );
          } else {
            toast.success(
              <div className="toastsize">
                Sales strategists <br /> Two Step Verification Deactivated
                successfully.{" "}
              </div>
            );
          }
          SalesHackerMethod(UserID, Role);
        } else {
        }
      }
    });
  };

  const SalesHackerRenderMethod = (UID, URole,IsArchive) => {
    SetLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
      IsArchive: IsArchive,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/sales_strategists_profile/GetSalesHackerList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetLoading(false);
    });
  };

  //Archive User
  const ArchivedUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Archive a Sales Strategists.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "Archive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/sales_strategists_profile/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Archived!",
                "Sales Strategists Archived successfully.",
                "success"
              );
              SetIsArchive(false)
              // document.getElementById("include_Archive").checked = false
              HandleClose();
              HandleCloseSecond();
              SalesHackerRenderMethod(UserID, Role,false);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  //Unarchive User
  const UnArchiveUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to unarchive a Sales Strategist.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unarchive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "UnArchive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/sales_strategists_profile/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Unarchived!",
                "Sales Strategists unarchived successfully.",
                "success"
              );
              SetIsArchive(false)
              HandleClose();
              HandleCloseSecond();
              // document.getElementById("include_Archive").checked = false
              SalesHackerRenderMethod(UserID, Role,false);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  //Delete user
  const DeleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a sales strategists.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "delete",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +"/sales_strategists_profile/DeleteSalesStrategists",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Sales strategists deleted successfully.",
                "success"
              );
              HandleClose();
              HandleCloseSecond();
              SalesHackerMethod(UserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row pt-3">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3 pt-0">
          <div className="fulleriger">
            <label className="check_bx floatrighter mr-3">
              <input id="include_Archive" type="checkbox" />
              <span className="checkmark"></span>
              Include Archived Sales Strategists
            </label>
            <button
              onClick={ClickOnSearch}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span>
                <i className="la flaticon-search"></i> &nbsp;
                <span>Search</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">
            <TableHead>
              <TableRow>
              <TableCell onClick={() => { SortData("IsTwoStepVerification"); setSortedColumn("IsTwoStepVerification");}}>

              Is Two Step Verification
              <span className="shorting">
                <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsTwoStepVerification" ? "active" : null} />
                <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsTwoStepVerification" ? "active" : null} />
              </span>
              </TableCell>
                <TableCell onClick={() => { SortData("SalesHackerName"); setSortedColumn("SalesHackerName");}}>
                    Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "SalesHackerName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "SalesHackerName" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("IsManager"); setSortedColumn("IsManager");}}>
                    Is Manager
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsManager" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsManager" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("SlackAccount"); setSortedColumn("SlackAccount");}}>
                    Slack Account
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "SlackAccount" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "SlackAccount" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    No data available in the table
                  </TableCell>
                </TableRow>
              ) : (
                Rows.map((row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell>
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          onChange={() => {
                            UpdateStatus(row._id, !row.IsTwoStepVerification);
                          }}
                          className="checktogle"
                          checked={row.IsTwoStepVerification}
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                    </TableCell>
                    <TableCell><div className="flexcontentpro">
                      <span class="carduser_pic mr-2">
                      <img
                        style={imgstyle}
                        src={
                          row.ProfileImage === undefined
                            ? CommonConstants.Image_url + "default.png"
                            : CommonConstants.Image_url + row.ProfileImage
                        }
                      /></span>
                      <span>{row?.FullName}</span>
                      </div>
                    </TableCell>
                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>{row.IsManager ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.SlackAccount}</TableCell>
                    <TableCell>
                      {row.IsArchive ? (
                        <div>
                          <a
                            className="btn-eyesicon dopdownwe"
                            onClick={(e) => {
                              HandleClick(e, row._id);
                            }}
                          >
                            <Button
                              // aria-controls="simple-menu"
                              // aria-haspopup="true"
                              aria-controls={`simple-menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => HandleClickEl(e, row._id)}
                              
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              className="droptable"
                              //id="simple-menu"
                              //anchorEl={AnchorEl}
                              //keepMounted
                              //open={Boolean(AnchorEl)}
                              //onClose={HandleClose}

                              anchorEl={AnchorEl}
                              open={Boolean(AnchorEl && row._id === selectedRowId)}
                              onClose={HandleCloseSecondEl}
                              id={`simple-menu-${index}`}
                            >
                              <MenuItem
                                onClick={() => {
                                  DeleteUser();
                                }}
                              >
                                <i className="la flaticon-delete-1 mr-3"></i>{" "}
                                Delete
                              </MenuItem>
                              <MenuItem
                                onClick={() => UnArchiveUser()}
                                color="black"
                              >
                                Unarchive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      ) : (
                        <div>
                          <a
                            onClick={() => {
                              EditPage(row._id);
                            }}
                            className="btn-eyesicon"
                            title="Edit"
                          >
                            <i className="la flaticon-edit-1 edit-icon"></i>
                          </a>
                          <a
                            className="btn-eyesicon dopdownwe"
                            onClick={(e) => {
                              HandleClickOne(e, row._id);
                            }}
                            title="Archive"
                          >
                            <Button
                              //aria-controls="simple-menu1"
                              //aria-haspopup="true"

                              aria-controls={`simple-menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => HandleClickElF(e, row._id)}
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              className="droptable"
                              //id="simple-menu1"
                              //anchorEl={AnchorElf}
                              //keepMounted
                              //open={Boolean(AnchorElf)}
                              //onClose={HandleCloseSecond} 

                              anchorEl={AnchorElf}
                              open={Boolean(AnchorElf && row._id === selectedRowId)}
                              onClose={HandleCloseSecond}
                              id={`simple-menu-${index}`}
                            >
                              <MenuItem onClick={() => ArchivedUser()}>
                                <i className="la flaticon-download mr-3"></i>{" "}
                                Archive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
        Sflag={Sflag}
        CountPage={CountPage}
        HandleChangePage={HandleChangePage}
        Rlen={Rlen}
        Page={Page}
        RowsPerPage={RowsPerPage}
        Flen={Flen}
      />
    </div>
  );
}
