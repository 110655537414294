import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useLocation } from 'react-router-dom';
import { GetUserDetails } from "../../../_helpers/Utility";
import DispositionsTabing from '../PhoneSetting/DispositionsTabing';
import Script from '../PhoneSetting/Script';
import DispositionEmail from '../PhoneSetting/DispositionsEmail';
import VoiceMailRecordings from '../PhoneSetting/VoiceMailRecordings';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default function SimpleTabs() {
  
  const location = useLocation();
  const classes = useStyles();
  const urlParams = location.pathname;
  // Determine the default tab based on the URL
  let IniValue = 0;
  let NavigationParams = "";
  if (urlParams === "/defaultphonesettings") {
    NavigationParams = true;
    IniValue= 1;
  } else if (urlParams === "/phonesettings") {
    NavigationParams = false;
    IniValue = 0; 
  }
  // State variables
  const [value, setValue] = React.useState(IniValue);  // Default to the calculated tab value
  const [UserID, setUserID] = React.useState("");
  const [Role, setRole] = React.useState("");
  const [ClientID, setClientID] = React.useState("");
  const [CUserID, setCUserID] = React.useState(0);
  const [IsLoading, setIsLoading] = React.useState(false);
  const [IsLoading1, setIsLoading1] = React.useState(false);
  // Fetch user details on component mount
  useEffect(() => {
    const Details = GetUserDetails();
    if (Details != null) {
      setUserID(Details.ParentUserID);
      setCUserID(Details.ChildUserID);
      setClientID(Details.ClientID);
      setRole(Details.Role);
    }
  }, []);
  // Handle tab changes
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  // Debugging log for current value
  useEffect(() => {
    console.log("Current tab value:", value);
  }, [value]);
  return (
    <div className="tabdes">
      {(IsLoading || IsLoading1) && (
        <div id="hideloding" className="loding-display">
          {/* Loading Spinner */}
        </div>
      )}
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}  // Controlled by the component state
            onChange={handleChange}  // Handle tab switching
            variant="scrollable"
            aria-label="simple tabs example"
          >
                <Tab label="Scripts" style={{ display: NavigationParams ? 'none' : 'block' }} {...a11yProps(0)} />
                <Tab label="Dispositions" {...a11yProps(1)} />
                <Tab label="Disposition Emails" style={{ display: NavigationParams ? 'none' : 'block' }} {...a11yProps(2)} />
                <Tab label="Voicemail Recordings" style={{ display: NavigationParams ? 'none' : 'block' }} {...a11yProps(3)} />
          </Tabs>
        </AppBar>
            <TabPanel value={value} index={0}>
              <Script />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DispositionsTabing />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DispositionEmail />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <VoiceMailRecordings />  
            </TabPanel>
      </div>
    </div>
  );
}