import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import Axios from "axios";
import parse from "html-react-parser";
import loadingicon from "../../../images/loading.gif";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import Popup from "reactjs-popup";
import "froala-editor/js/plugins.pkgd.min.js";

var CryptoJS = require("crypto-js");
const EmailTemplete = require("../../../_helpers/email-templete");
import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [TabValue, setTabValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [minutesBeforeMeeting, setMinutesBeforeMeeting] = useState([]);
  const [meetingRemainderEmailType, setMeetingRemainderEmailType] = useState(
    []
  );
  const [ClientID, setClientID] = useState();
  const [UserID, setUserID] = useState();
  const [SignatureSC1, setSignatureSC1] = useState({ data: "" });
  const [SignatureSC2, setSignatureSC2] = useState({ data: "" });
  const [MeetingReminderSettingID, SetMeetingReminderSettingID] = useState();
  const [MeetingReminderSettingIDSC2, SetMeetingReminderSettingIDSC2] =
    useState();
  const [BodyPerview, SetBodyPerview] = useState("");
  const [SubjectPerview, SetSubjectPerview] = useState("");
  const [Role, setRole] = useState("");
  const [SubOne, setSubOne] = useState(false);
  const [SubSecond, setSubSecond] = useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsButtonDisabled, SetIsButtonDisabled] = React.useState(false);
  const [IsButtonDisabledSecond, SetIsButtonDisabledSecond] =
    React.useState(false);
  const [HourOne, setHourOne] = useState("");
  const [HourSecond, setHourSecond] = useState("");
  const [MailCCSubOne, setMailCCSubOne] = useState(false);
  const [MailBCCSubOne, setMailBCCSubOne] = useState(false);
  const [MailCCSubtwo, setMailCCSubtwo] = useState(false);
  const [MailBCCSubtwo, setMailBCCSubtwo] = useState(false);
  const [MeetingReminderSendTest, SetMeetingReminderSendTest] = useState(false);
  const [SendTestAccount, SetSendTestAccount] = useState([]);
  const [SignatureSendTest, SetSignatureSendTest] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [Errors, SetErrors] = React.useState({});
  const [ErrorsOne, SetErrorsOne] = React.useState({});
  const [UnsubscribeText, SetUnsubscribeText] =useState("");

  var MeetingReminderVariable = [
    { name: "CONTACT.FIRSTNAME" },
    { name: "CONTACT.LASTNAME" },
    { name: "CONTACT.COMPANY" },
    { name: "MEETING.DATE" },
    { name: "MEETING.TIME" },
    { name: "OWNER.COMPANY" },
    { name: "OWNER.FIRSTNAME" },
    { name: "OWNER.CONFERENCELINE" },
    { name: "OWNER.FULLNAME" },
    { name: "REMINDERALIAS.FULLNAME" },
    { name: "REMINDERALIAS.TITLE" },
    { name: "MEETINGPLATFORMNAME"}

  ];
  var Vari = {};
  if (MeetingReminderVariable.length > 0) {
    for (var i = 0; i < MeetingReminderVariable.length; i++) {
      Vari["{" + MeetingReminderVariable[i].name + "}"] =
        MeetingReminderVariable[i].name;
    }
  }

  const MeetingReminderSchedulerTab = (event, newValue) => {
    setTabValue(newValue);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    document.title = `Meeting Reminder | SalesHive`;
    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
      // Callback on refresh.
      refresh: function ($btn) {
        //
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        //
      },
    });
    getMeetingRemainder(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID
    );
    getMeetingRemainderAccount(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      getaccountcatelist.Role
    );
  }, [TabValue, MeetingReminderSettingID]);

  const getMeetingRemainder = (ClientIDs, UserIDs) => {
     SetIsLoading(true)
    const MeetingData = {
      MinuteType: "MinuteBeforeMeeting",
      EmailType: "MeetingRemainderEmailType",
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/commonfiled/GetMinuteBeforeMeeting",
      method: "POST",
      data: MeetingData,
    }).then((resdata) => {
      if (resdata.statusText == "OK") {
        setMinutesBeforeMeeting(resdata?.data?.MinutesData);
        setMeetingRemainderEmailType(resdata?.data?.EmailTypeData);
        GetMeetingreminderSeeting(ClientIDs, UserIDs);
      }
    });
  };

  const getMeetingRemainderAccount = async (ClientIDs, UserIDs, Role) => {
    var VariableDroupdownsendtest = {
      ClientID: ClientIDs,
      UserID: UserIDs,
      Role: Role,
    };
    var resultsendtest = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/meetingremindersetting/MeetingReminderAccountGetSendTest",
      method: "POST",
      data: VariableDroupdownsendtest,
    });

    if (resultsendtest.data.StatusMessage === "SUCCESS") {
      SetSendTestAccount(resultsendtest.data.Data);
    }

    var str_inlist = {
      ClientID: ClientIDs,
      UserID: UserIDs,
      Role: Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureList",
      method: "POST",
      data: str_inlist,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          SetSignatureSendTest(resdata.data.Data[0].Signature);
          SetUnsubscribeText(resdata.data.Data[0].UnsubscribeText);

        }
      }
    });
  };

  const GetMeetingreminderSeeting = async (ClientIDs, UserIDs) => {
    // SetIsLoading(false)
    var StringData;
    if (TabValue === 0) {
      StringData = {
        ClientID: ClientIDs,
        UserID: UserIDs,
        IsMeetingReminderSC: false,
        Role: Role,
      };
    } else {
      StringData = {
        ClientID: ClientIDs,
        UserID: UserIDs,
        IsMeetingReminderSC: true,
        Role: Role,
      };
    }

    let resdata = await Axios({
      url:CommonConstants.MOL_APIURL +"/meetingremindersetting/MeetingReminderSettingList",
      method: "POST",
      data: StringData,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (TabValue === 0) {
          document.getElementById("HoursBeforeMeetingSC1").value =
            resdata.data.Data[0].HoursBeforeMeeting;
          document.getElementById("SubjectSC1").value =
            resdata.data.Data[0].Subject;
          document.getElementById("MailCCSC1").value =
            resdata.data.Data[0].MailCC;
          document.getElementById("MailBCCSC1").value =
            resdata.data.Data[0].MailBCC;
          document.getElementById("MinutesBeforeMeetingSC1").value =
            resdata.data.Data[0].MinuteBeforeMeetingID;
          document.getElementById("EmailTypeSC1").value =
            resdata.data.Data[0].EmailTypeID;
          document.getElementById("IssendMeetingReminderCheckedSC1").checked =
            resdata.data.Data[0].IsSendMeetingReminder;
          setSignatureSC1({ data: resdata.data.Data[0]?.MeetingReminderText });
          SetMeetingReminderSettingID(resdata.data.Data[0]?._id);
          await MeetingReminderPerview(
            resdata.data.Data[0]?.MeetingReminderText,
            resdata.data.Data[0].Subject,
            ClientIDs,
            UserIDs,
            resdata.data.Data[0].CF[0].FieldName
          );
        } else {
          document.getElementById("HoursBeforeMeetingSC2").value =
            resdata.data.Data[0].HoursBeforeMeeting;
          document.getElementById("SubjectSC2").value =
            resdata.data.Data[0].Subject;
          document.getElementById("MailCCSC2").value =
            resdata.data.Data[0].MailCC;
          document.getElementById("MailBCCSC2").value =
            resdata.data.Data[0].MailBCC;
          document.getElementById("MinutesBeforeMeetingSC2").value =
            resdata.data.Data[0].MinuteBeforeMeetingID;
          document.getElementById("EmailTypeSC2").value =
            resdata.data.Data[0].EmailTypeID;
          document.getElementById("IssendMeetingReminderCheckedSC2").checked =
            resdata.data.Data[0].IsSendMeetingReminder;
          setSignatureSC2({ data: resdata.data.Data[0]?.MeetingReminderText });
          SetMeetingReminderSettingIDSC2(resdata.data.Data[0]?._id);
          await MeetingReminderPerview(
            resdata.data.Data[0]?.MeetingReminderText,
            resdata.data.Data[0].Subject,
            ClientIDs,
            UserIDs,
            resdata.data.Data[0].CF[0].FieldName
          );
        }
      } else {
        setSignatureSC1({ data: "" });
        SetMeetingReminderSettingID("");
        setSignatureSC2({ data: "" });
        await MeetingReminderPerview("", "", ClientIDs, UserIDs, "HTML");
        try {
          if (TabValue === 0) {
            document.getElementById("HoursBeforeMeetingSC1").value = 24;
            document.getElementById("MinutesBeforeMeetingSC1").value = "1";
          } else {
            document.getElementById("HoursBeforeMeetingSC2").value = 0;
            document.getElementById("MinutesBeforeMeetingSC2").value = "1";
          }
        } catch (error) {
          
        }
      }
    }
  };

  const MeetingReminderSc1ModelChange = (model) => {
    setSignatureSC1({
      data: model,
    });
  };
  const MeetingReminderSc2ModelChange = (model) => {
    setSignatureSC2({
      data: model,
    });
  };

  let config = {
    placeholderText: "Edit Your Content Here!",
    charCounterCount: false,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "insertLink",
      "insertImage",
      "html",
      "Variable",
    ],
    //shortcutsEnabled: ["insertTemplateButton"],
    imageUploadURL:
      CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
    imageUploadRemoteUrls: false,
    enter: Froala.ENTER_BR,
    key:CommonConstants.EditorValidationKey,
    pastePlain: true,
    attribution: false
  };

  const SaveMeetingReminderSc1 = async (e) => {
     
    setSubOne(false);
    setMailCCSubOne(false);
    setMailBCCSubOne(false);
    setHourOne("");
    SetIsButtonDisabled(true);
    const HoursBeforeMeeting = document.getElementById(
      "HoursBeforeMeetingSC1"
    ).value;
    const Subject = document.getElementById("SubjectSC1").value;
    const MailCC = document.getElementById("MailCCSC1").value;
    const MailBCC = document.getElementById("MailBCCSC1").value;
    const MinutesBeforeMeetingID = document.getElementById("MinutesBeforeMeetingSC1").value;
    const EmailTypeID = document.getElementById("EmailTypeSC1").value;
    var IssendMeetingReminderCheck = document.getElementById("IssendMeetingReminderCheckedSC1").checked;
    const MailBCCSC1 = document.getElementById("MailBCCSC1").value.trim();
    const MailCCSC1 = document.getElementById("MailCCSC1").value.trim();

    const isEmptyOrHtmlEntities = !SignatureSC1?.data || /^(&nbsp;|\s|<br>)*$/.test(SignatureSC1.data);

    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var MailCCSC1flag = MailCCSC1.split(/[ ,]+/).filter(email => email).every(email => re.test(email));
    var MailBCCSC1flag = MailBCCSC1.split(/[ ,]+/).filter(email => email).every(email => re.test(email));
   
    if (Subject == "" || Subject == null) {
      setSubOne(true);
      SetIsButtonDisabled(false);
      return false;
    } else if ((!MailCCSC1flag && MailCCSC1 !== "") || (!MailBCCSC1flag && MailBCCSC1 !== "")) {
      // Run if either condition is true
      setMailCCSubOne(!MailCCSC1flag && MailCCSC1 !== ""); // Set MailCCSubOne based on MailCCSC1 conditions
      setMailBCCSubOne(!MailBCCSC1flag && MailBCCSC1 !== ""); // Set MailBCCSubOne based on MailBCCSC1 conditions
      SetIsButtonDisabled(false); // Enable the button
    } else if (
      HoursBeforeMeeting == "" ||
      HoursBeforeMeeting == NaN ||
      HoursBeforeMeeting == null
    ) {
      setHourOne("Please enter Hours Before Meeting");
      SetIsButtonDisabled(false);
      return false;
    } else if (HoursBeforeMeeting > 24) {
      setHourOne("Please enter a value less than or equal to 24.");
      SetIsButtonDisabled(false);
      return false;
    }else if(HoursBeforeMeeting==0 && MinutesBeforeMeetingID==1){
      toast.error(
        <div className="toastsize">
          Edit Meeting Reminder
          <br />
          Please enter a value greater than or equal to 1.!
        </div>
      );
      SetIsButtonDisabled(false)
      return false;
    }else if(isEmptyOrHtmlEntities){
      toast.error(
        <div className="toastsize">
          Edit Meeting Reminder
          <br />
          Reminder text is empty, fill it!
        </div>
      );
      SetIsButtonDisabled(false);
      return false;
    } else {
      const cleanEmails = (input) => {
        return input.split(/[ ,]+/).filter(email => email).map(email => email.trim()).join(' ,');                    
      };
      
      const MailCC = cleanEmails(document.getElementById("MailCCSC1").value);
      const MailBCC = cleanEmails(document.getElementById("MailBCCSC1").value);
      var data = {
        MeetingReminderSettingID: MeetingReminderSettingID,
        ClientID: ClientID,
        UserID: UserID,
        HoursBeforeMeeting: HoursBeforeMeeting,
        Subject: Subject,
        MailCC: MailCC,
        MailBCC: MailBCC,
        MeetingReminderText: SignatureSC1?.data,
        MinutesBeforeMeetingID: MinutesBeforeMeetingID,
        EmailTypeID: EmailTypeID,
        IsMeetingReminderSC: false,
        IsSendMeetingReminder: IssendMeetingReminderCheck,
        Role: Role,
      };
      let res = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingremindersetting/MeetingReminderSettingAdd",
        method: "POST",
        data: data,
      });

      if ((res.data.StatusMessage = "SUCCESS")) {
        SetMeetingReminderSettingID(res.data.Data._id);
        var EmailTypeText =
          res.data.Data.EmailTypeID == "1" ? "HTML" : "Text Only";
        await MeetingReminderPerview(
          res.data.Data.MeetingReminderText,
          res.data.Data.Subject,
          ClientID,
          UserID,
          EmailTypeText
        );
        toast.success(
          <div className="toastsize">
            Meeting Reminder Setting
            <br />
            Meeting Reminder Setting update successfully.
          </div>
        );
        SetIsLoading(false)
        SetIsButtonDisabled(false);
      } else {
        toast.error(
          <div className="toastsize">
            Meeting Reminder Setting
            <br />
            Error meeting reminder successfully.
          </div>
        );
        SetIsLoading(false)
        SetIsButtonDisabled(false);
      }
    }
  };

  const HandleHourBeforeOne = () => {
    const HoursBeforeMeeting = document.getElementById(
      "HoursBeforeMeetingSC1"
    ).value;
    if (
      HoursBeforeMeeting == "" ||
      HoursBeforeMeeting == NaN ||
      HoursBeforeMeeting == null
    ) {
      setHourOne("Please enter Hours Before Meeting");
    } else if (HoursBeforeMeeting > 24) {
      setHourOne("Please enter a value less than or equal to 24.");
    } else {
      setHourOne("");
    }
  };

  const HandleHourBeforeOneSubject = () => {
    const Subject = document.getElementById("SubjectSC1").value;
    if (Subject == "" || Subject == null) {
      setSubOne(true);
    } else {
      setSubOne(false);
    }
  };

  const HandleHourBeforeOneMailCC = () => {
    ;
    const MailCCSC1 = document.getElementById("MailCCSC1").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(MailCCSC1);
    if (Emailflg == false && MailCCSC1 != "") {
      setMailCCSubOne(true);
    } else {
      setMailCCSubOne(false);
    }
  };

  const HandleHourBeforeOneMailBCC = () => {
    const MailBCCSC1 = document.getElementById("MailBCCSC1").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(MailBCCSC1);
    if (Emailflg == false && MailBCCSC1 != "") {
      setMailBCCSubOne(true);
    } else {
      setMailBCCSubOne(false);
    }
  };


  const HandleHourBeforetwoMailCC = () => {
    ;
    const MailCCSC2 = document.getElementById("MailCCSC2").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(MailCCSC2);
    if (Emailflg == false && MailCCSC2 != "") {
      setMailCCSubtwo(true);
    } else {
      setMailCCSubtwo(false);
    }
  };

  const HandleHourBeforetwoMailBCC = () => {
    const MailBCCSC2 = document.getElementById("MailBCCSC2").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(MailBCCSC2);
    if (Emailflg == false && MailBCCSC2 != "") {
      setMailBCCSubtwo(true);
    } else {
      setMailBCCSubtwo(false);
    }
  };

  const SaveMeetingReminderSc2 = (e) => {
    // e.preventDefault();
    setHourSecond("");
    setSubSecond(false);
    setMailCCSubtwo(false);
    setMailBCCSubtwo(false);
    SetIsButtonDisabledSecond(true);
    const HoursBeforeMeeting = document.getElementById(
      "HoursBeforeMeetingSC2"
    ).value;
    const Subject = document.getElementById("SubjectSC2").value;
    const MailCC = document.getElementById("MailCCSC2").value;
    const MailBCC = document.getElementById("MailBCCSC2").value;
    const MinutesBeforeMeetingID = document.getElementById(
      "MinutesBeforeMeetingSC2"
    ).value;
    const EmailTypeID = document.getElementById("EmailTypeSC2").value;
    var IssendMeetingReminderCheck = document.getElementById(
      "IssendMeetingReminderCheckedSC2"
    ).checked;

    const MailBCCSC2 = document.getElementById("MailBCCSC2").value;
    const MailCCSC2 = document.getElementById("MailCCSC2").value;

    // if (Subject == "" || Subject == null) {
    //   // toast.error("Please enter subject");
    //   setSubSecond(true);
    //   SetIsButtonDisabledSecond(false);
    //   return false;
    // }

    const isEmptyOrHtmlEntities =
      !SignatureSC2?.data || /^(&nbsp;|\s|<br>)*$/.test(SignatureSC2.data);

    // if (isEmptyOrHtmlEntities) {
    //   toast.error(
    //     <div className="toastsize">
    //       Edit Meeting Reminder
    //       <br />
    //       Reminder text is empty, fill it!
    //     </div>
    //   );
    //   SetIsButtonDisabledSecond(false);
    //   return false;
    // }
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

  var MailCCSC2flag = MailCCSC2.split(/[ ,]+/).filter(email => email).every(email => re.test(email));
    var MailBCCSC2flag = MailBCCSC2.split(/[ ,]+/).filter(email => email).every(email => re.test(email));
   

    if (Subject == "" || Subject == null) {
      setSubSecond(true);
      SetIsButtonDisabledSecond(false);
      return false;
    }else if ((!MailCCSC2flag && MailCCSC2 !== "") || (!MailBCCSC2flag && MailBCCSC2 !== "")) {
      // Run if either condition is true
      setMailCCSubtwo(!MailCCSC2flag && MailCCSC2 !== ""); // Set MailCCSubOne based on MailCCSC1 conditions
      setMailBCCSubtwo(!MailBCCSC2flag && MailBCCSC2 !== ""); // Set MailBCCSubOne based on MailBCCSC1 conditions
      SetIsButtonDisabledSecond(false); // Enable the button
    }
    else if (
      HoursBeforeMeeting == "" ||
      HoursBeforeMeeting == NaN ||
      HoursBeforeMeeting == null
    ) {
      setHourSecond("Please enter Hours Before Meeting");
      SetIsButtonDisabledSecond(false);
      return false;
    } else if (HoursBeforeMeeting > 24) {
      setHourSecond("Please enter a value less than or equal to 24.");
      SetIsButtonDisabledSecond(false);
      return false;
    }else if(HoursBeforeMeeting==0 && MinutesBeforeMeetingID==1){
      toast.error(
        <div className="toastsize">
          Edit Meeting Reminder
          <br />
          Please enter a value greater than or equal to 1.!
        </div>
      );
      SetIsButtonDisabledSecond(false);
      return false;
    }
    else if(isEmptyOrHtmlEntities){
      toast.error(
        <div className="toastsize">
          Edit Meeting Reminder
          <br />
          Reminder text is empty, fill it!
        </div>
      );
      SetIsButtonDisabledSecond(false);
      return false;
    } else {
      const cleanEmails = (input) => {
        return input.split(/[ ,]+/).filter(email => email).map(email => email.trim()).join(' ,');                    
      };
      
      const MailCC = cleanEmails(document.getElementById("MailCCSC2").value);
      const MailBCC = cleanEmails(document.getElementById("MailBCCSC2").value);
      var data = {
        MeetingReminderSettingID: MeetingReminderSettingIDSC2,
        ClientID: ClientID,
        UserID: UserID,
        HoursBeforeMeeting: HoursBeforeMeeting,
        Subject: Subject,
        MailCC: MailCC,
        MailBCC: MailBCC,
        MeetingReminderText: SignatureSC2?.data,
        MinutesBeforeMeetingID: MinutesBeforeMeetingID,
        EmailTypeID: EmailTypeID,
        IsMeetingReminderSC: true,
        IsSendMeetingReminder: IssendMeetingReminderCheck,
        Role: Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingremindersetting/MeetingReminderSettingAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if ((res.data.StatusMessage = "SUCCESS")) {
          SetMeetingReminderSettingIDSC2(res.data.Data._id);
          var EmailTypeText =
            res.data.Data.EmailTypeID == "1" ? "HTML" : "Text Only";
          MeetingReminderPerview(
            res.data.Data.MeetingReminderText,
            res.data.Data.Subject,
            ClientID,
            UserID,
            EmailTypeText
          );
          toast.success(
            <div className="toastsize">
              Meeting Reminder Setting
              <br />
              Meeting Reminder Setting update successfully.
            </div>
          );
          SetIsLoading(false)
          SetIsButtonDisabledSecond(false);
        } else {
          toast.error(
            <div className="toastsize">
              Meeting Reminder Setting
              <br />
              Error meeting reminder successfully.
            </div>
          );
          SetIsLoading(false)
          SetIsButtonDisabledSecond(false);
        }
      });
    }
  };

  const HandleHourBeforeSecond = () => {
    const HoursBeforeMeeting = document.getElementById(
      "HoursBeforeMeetingSC2"
    ).value;
    if (
      HoursBeforeMeeting == "" ||
      HoursBeforeMeeting == NaN ||
      HoursBeforeMeeting == null
    ) {
      setHourSecond("Please enter Hours Before Meeting");
    } else if (HoursBeforeMeeting > 24) {
      setHourSecond("Please enter a value less than or equal to 24.");
    } else {
      setHourSecond("");
    }
  };

  const HandleHourBeforeSecondSubject = () => {
    const Subject = document.getElementById("SubjectSC2").value;
    if (Subject == "" || Subject == null) {
      setSubSecond(true);
    } else {
      setSubSecond(false);
    }
  };

  const MeetingReminderPerview = async (
    PreviewText,
    SubjectText,
    ClientID,
    UserID,
    EmailTypeText
  ) => {
     SetIsLoading(true)

    var data = {
      Body: PreviewText,
      Subject: SubjectText,
      ClientID: ClientID,
      UserID: UserID,
      EmailTypeText: EmailTypeText,
      Role: Role,
    };

    // console.time("start");
    let res = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/meetingremindersetting/MeetingReminderPerview",
      method: "POST",
      data: data,
    });
    console.timeEnd("end time");
    if ((res.data.StatusMessage = "SUCCESS")) {
      SetBodyPerview(res.data.BodyPerview);
      SetSubjectPerview(res.data.SubjectPerview);
      SetIsLoading(false)
    } else {
      toast.error(
        <div className="toastsize">
          Meeting Reminder Setting
          <br />
          Error meeting reminder successfully.
        </div>
      );
      SetIsLoading(false)
    }
    SetIsLoading(false)
  };

  const SendReminderHandleTest = () => {
    let SendTestEmailToIDs =
      document.getElementById("AddSendTestEmailToID")?.value.trim() || "";
    let SendTestEditSubject =
      document.getElementById("SubjectSC1")?.value.trim() || "";

    var SendTestEditBody = SignatureSC1.data || "";
    let sendTestEmailFromIDs = "";
    let sendTestRefersTokens = "";
    try {
      // Attempt to parse JSON
      let sendTestCommons = JSON.parse(
        document.getElementById("AddSendTestEmailFromID")?.value
      );
      // Use optional chaining and nullish coalescing to handle undefined or null values
      sendTestEmailFromIDs = sendTestCommons?.SMTPFromEmail || "";
      sendTestRefersTokens = sendTestCommons?.RefreshToken || "";
    } catch (error) {
      sendTestEmailFromIDs = "";
      sendTestRefersTokens = "";
    }

    //var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    let re = /^([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*)@([a-zA-Z0-9\-]+\.[a-zA-Z]{2,})$/;
    var Emailflg = re.test(SendTestEmailToIDs);
    let errors = {};
    if (SendTestEmailToIDs == "") {
     // toast.error("Please enter email!");
     errors["AddSendTestEmailToID"] = "Please enter receiver email";
     SetErrors(errors);
    } else if (SignatureSendTest == "") {
      toast.error("Please add email Signature!");
    } else if (sendTestEmailFromIDs == "") {
      toast.error("Please add email account!");
    } else if (Emailflg == false) {
     // toast.error("Please enter valid email!");
    } else {
       SetIsLoading(true)
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(SendTestEmailToIDs),
        "my-secret-key@123"
      ).toString();
      const MailCC = document.getElementById("MailCCSC1").value;
      const MailBCC =document.getElementById("MailBCCSC1").value;
      var EmailData = EmailTemplete.userEmailInvite(FinalEmail);
      var str_mail = {
        ClientID: ClientID,
        UserID: UserID,
        Email: EmailData,
        FromEmail: sendTestEmailFromIDs,
        ToEmail: SendTestEmailToIDs,
        RefresToken: sendTestRefersTokens,
        Html: SendTestEditBody,
        Subject: SendTestEditSubject,
        PageType: "MeetingReminderSendTest",
        Signature:SignatureSendTest,
        UnsubscribeText: UnsubscribeText,
        CC:MailCC,
        BCC:MailBCC
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingremindersetting/MeetingReminderEmailSendTest",
        method: "POST",
        data: str_mail,
      })
        .then((res) => {
          
          SetIsLoading(false)
          if(res.data=="variable not found"){
            toast.error( <div className="toastsize">
            SendTest
            <br />
            Variable value not available 
          </div>
        );
            SetMeetingReminderSendTest(false)
          }else{
            SetMeetingReminderSendTest(false)
            toast.success("Email Send Successfully!");
          }
        })
        .catch((err) => {
          SetIsLoading(false)
        });
    }
  };

  const SendReminderHandleTest2Tab = () => {
    let SendTestEmailToIDs =
      document.getElementById("AddSendTestEmailToIDtwo")?.value.trim() || "";
    let SendTestEditSubject =
      document.getElementById("SubjectSC2")?.value.trim() || "";

    var SendTestEditBody = SignatureSC2.data || "";
    let sendTestEmailFromIDs = "";
    let sendTestRefersTokens = "";
    try {
      // Attempt to parse JSON
      let sendTestCommons = JSON.parse(
        document.getElementById("AddSendTestEmailFromID")?.value
      );
      // Use optional chaining and nullish coalescing to handle undefined or null values
      sendTestEmailFromIDs = sendTestCommons?.SMTPFromEmail || "";
      sendTestRefersTokens = sendTestCommons?.RefreshToken || "";
    } catch (error) {
      sendTestEmailFromIDs = "";
      sendTestRefersTokens = "";
    }

    // var re =
    //   /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    let re = /^([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*)@([a-zA-Z0-9\-]+\.[a-zA-Z]{2,})$/;
    var Emailflg = re.test(SendTestEmailToIDs);
    let errors = {};
    if (SendTestEmailToIDs == "") {
      //toast.error("Please enter email!");
      errors["AddSendTestEmailToIDtwo"] = "Please enter receiver email";
      SetErrorsOne(errors);
    } else if (SignatureSendTest == "") {
      toast.error("Please add email Signature!");
    } else if (sendTestEmailFromIDs == "") {
      toast.error("Please add email account!");
    } else if (Emailflg == false) {
     // toast.error("Please enter valid email!");
    } else {
       SetIsLoading(true)
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(SendTestEmailToIDs),
        "my-secret-key@123"
      ).toString();

      var EmailData = EmailTemplete.userEmailInvite(FinalEmail);
      const MailCC2= document.getElementById("MailCCSC2").value;
      const MailBCC2 =document.getElementById("MailBCCSC2").value;
      var str_mail = {
        ClientID: ClientID,
        UserID: UserID,
        Email: EmailData,
        FromEmail: sendTestEmailFromIDs,
        ToEmail: SendTestEmailToIDs,
        RefresToken: sendTestRefersTokens,
        Html: SendTestEditBody,
        Subject: SendTestEditSubject,
        PageType: "MeetingReminderSendTest",
        Signature:SignatureSendTest,
        UnsubscribeText: UnsubscribeText,
        CC:MailCC2,
        BCC:MailBCC2
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/meetingremindersetting/MeetingReminderEmailSendTest",
        method: "POST",
        data: str_mail,
      })
        .then((res) => {
          SetIsLoading(false)
          if(res.data=="variable not found"){
            toast.error( <div className="toastsize">
            SendTest
            <br />
            Variable value not available 
          </div>
        );
            SetMeetingReminderSendTest(false)
          }else{
            SetMeetingReminderSendTest(false)
            toast.success("Email Send Successfully!");
          }
        })
        .catch((err) => {
          SetIsLoading(false)
        });
    }
  };


  const HandleAddSendTestEmailToID = async () => {
    let errors = {};
    var Domain = document.getElementById("AddSendTestEmailToID").value.trim();

    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
     // formIsValid = false;
      errors["AddSendTestEmailToID"] = "Please enter receiver email";
    }

    if (FLAGISVALID == true && Domain.length > 0) {
    //  formIsValid = false;
      errors["AddSendTestEmailToID"] = "Please enter valid email.";
    }
    SetErrors(errors);
  };


  const HandleAddSendTestEmailToIDOne = async () => {
    let errors = {};
    var Domain = document.getElementById("AddSendTestEmailToIDtwo").value.trim();

    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
     // formIsValid = false;
      errors["AddSendTestEmailToIDtwo"] = "Please enter receiver email";
    }

    if (FLAGISVALID == true && Domain.length > 0) {
    //  formIsValid = false;
      errors["AddSendTestEmailToIDtwo"] = "Please enter valid email.";
    }
    SetErrorsOne(errors);
  };

    //Domain Check for domain
    const DomainCheck = async (Domain) => {
   //   var Domain = document.getElementById("AddSendTestEmailToID").value.trim();
    //  let regexp = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      let regexp = /^([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*)@([a-zA-Z0-9\-]+\.[a-zA-Z]{2,})$/;
      if (!regexp.test(Domain)) {
        //SetIsValid(true);
        return true;
      } else {
        //SetIsValid(false);
        return false;
      }
    };

  return (
    <div className="tabdes">
       { IsLoading ?  <div id="hideloding" className="loding-display"><img src={loadingicon} /></div> : <></> }
      <div>
        <AppBar position="static">
          <Tabs
            value={TabValue}
            onChange={MeetingReminderSchedulerTab} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Meeting Reminder Scheduler - 1" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Meeting Reminder Scheduler - 2" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={TabValue} className="tagpaddnone bg-white" index={0}>
          <div className="row mx-0 align-items-center border-bottom">
            <div className="col ">
              <h4 className="headertitle pl-0 xs-headertitle float-left">
                Meeting Reminder Scheduler - 1
              </h4>
            </div>
            <div className="col">
              <div className="listing-li float-right">
              <button
                class="btn btn-primary btn-lightgreen mr-1"
                disabled={IsButtonDisabled}
                onClick={() => {
                  SaveMeetingReminderSc1();
                }}
              >
                <i class="la la-save"></i> Save
              </button>
              <a
                       onClick={() => {
                        let errors = {};
                        errors["AddSendTestEmailToID"] = null;
                        SetErrors(errors);
                        SetMeetingReminderSendTest(true);
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-send la-icon-font-size-13"></i>
                      <span>Send Test</span>
                    </a>
              </div>
            </div>
          </div>
          {/* Send Add test start */}
          <Popup open={MeetingReminderSendTest}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup1000">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4 px-3">
                    <div className="w-100 d-flex px-3">
                      <h5 className="mb-0">Send Test</h5>
                      <button
                        className="close"
                        onClick={() => {
                          SetMeetingReminderSendTest(false);
                        }}
                      >
                        <span aria-hidden="true" class="la la-remove"></span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="px-4">
                      <div class="row mb-4">
                        <div class="col-lg-6 d-flex justify-content-between align-items-center">
                          <label class=" col-form-label textright-vmb">
                            Email Accounts
                          </label>
                          <div class="">
                            <select
                              id="AddSendTestEmailFromID"
                              class="form-control m-input valid"
                            >
                              {SendTestAccount?.map((value) => (
                                <option
                                  key={value?._id}
                                  value={JSON.stringify(value)}
                                >
                                  {value?.SMTPFromEmail}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-between align-items-center">
                          <label class=" col-form-label textright-vmb">
                            To
                          </label>
                          <div class="">
                            <input
                              id="AddSendTestEmailToID"
                              class="form-control m-input valid"
                              onBlur={HandleAddSendTestEmailToID}
                              />
                            <span style={{ color: "red" }} id="validation_error">
                              {Errors["AddSendTestEmailToID"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        SetMeetingReminderSendTest(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btnColor"
                      onClick={SendReminderHandleTest}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
    

          <div class="row mx-0 border-bottom pb-4 pr-4">
            <div class="col-lg-12 boxsinput_group">
              <div class="row my-4">
                <div class="col-lg-8">
                  <div class="row">
                    <label class="col-lg-3 align-right">
                      Hours Before Meeting
                    </label>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-4">
                          <input
                            class="form-control m-input arrow-none"
                            type="number"
                            min="0"
                            max="24"
                            id="HoursBeforeMeetingSC1"
                            name="HoursBeforeMeeting"
                            onBlur={HandleHourBeforeOne}
                          />
                          <span style={{ color: "red" }}>{HourOne}</span>
                        </div>
                        <div class="col-lg-4">
                          <select
                            class="form-control m-input"
                            name="MinutesBeforeMeeting"
                            id="MinutesBeforeMeetingSC1"
                          >
                            {minutesBeforeMeeting?.map((value, index) => (
                              <option key={value.FieldID} value={value.FieldID}>
                                {value.FieldName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <label class="check_bx">
                    <input
                      type="checkbox"
                      id="IssendMeetingReminderCheckedSC1"
                    />
                    <span className="checkmark"></span> Send Meeting Reminder
                  </label>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">Email Type</label>
                <div class="col-lg-5">
                  <select
                    class="form-control m-input"
                    name="emailType"
                    id="EmailTypeSC1"
                  >
                    {meetingRemainderEmailType?.map((value) => (
                      <option value={value.FieldID} key={value.FieldID}>{value.FieldName}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">Subject</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    id="SubjectSC1"
                    type="text"
                    name="Subject"
                    onBlur={HandleHourBeforeOneSubject}
                  />
                  {SubOne == true ? (
                    <span style={{ color: "red" }}>Please enter subject</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">CC</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    id="MailCCSC1"
                    type="text"
                    name="MailCC"
                    onBlur={HandleHourBeforeOneMailCC}
                  />
                  {MailCCSubOne == true ? (
                    <span style={{ color: "red" }}>
                      Please enter valid email.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">BCC</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    id="MailBCCSC1"
                    type="text"
                    name="MailBCC"
                    onBlur={HandleHourBeforeOneMailBCC}
                  />
                  {MailBCCSubOne == true ? (
                    <span style={{ color: "red" }}>
                      Please enter valid email.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div class="col-lg-12 boxsinput_group">
              <div class="row">
                <label class="col-lg-2 align-right">Reminder Text</label>
                <div class="col-lg-10">
                  <FroalaEditor
                    id="signature"
                    config={config}
                    onModelChange={MeetingReminderSc1ModelChange}
                    model={SignatureSC1.data}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="row border-bottom mx-0">
            <div className="col">
              <h4 className="headertitle xs-headertitle float-left pl-0">Preview</h4>
            </div>
          </div>

          <div className="row mt-3 mx-0 font-small">
            <div className="col">
              <p>
                <b>Subject : </b>
                {SubjectPerview}
              </p>
              <p>{parse(BodyPerview)}</p>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={TabValue} className="tagpaddnone bg-white" index={1}>
          <div className="row mx-0 border-bottom align-items-center">
            <div className="col">
              <h4 className="headertitle xs-headertitle pl-0 float-left">
                Meeting Reminder Scheduler - 2
              </h4>
            </div>
            <div className="col">
              <div className="listing-li float-right ">

              <button
                class="btn btn-primary btn-lightgreen mr-1"
                disabled={IsButtonDisabledSecond}
                onClick={SaveMeetingReminderSc2}
              >
                <i class="la la-save"></i> Save
              </button>
              <a
                      onClick={() => {
                        SetMeetingReminderSendTest(true);
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-send la-icon-font-size-13"></i>
                      <span>Send Test</span>
                    </a>
              </div>
            </div>
          </div>

          {/* Send Add test start */}
          <Popup open={MeetingReminderSendTest}>
            <div>
              <div className="modal-black"></div>
              <div className="filterPopup largerPopup1000">
                <div className="paddingboxTerms">
                  <div className="modal-header py-4 px-3">
                    <div className="w-100 d-flex px-3">
                      <h5 className="mb-0">Send Test</h5>
                      <button
                        className="close"
                        onClick={() => {
                          let errors = {};
                          errors["AddSendTestEmailToIDtwo"] = null;
                          SetErrorsOne(errors);
                          SetMeetingReminderSendTest(false);
                        }}
                      >
                        <span aria-hidden="true" class="la la-remove"></span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="px-4">
                      <div class="row mb-4">
                        <div class="col-lg-6 d-flex justify-content-between align-items-center">
                          <label class=" col-form-label textright-vmb">
                            Email Accounts
                          </label>
                          <div class="">
                            <select
                              id="AddSendTestEmailFromID"
                              class="form-control m-input valid"
                            >
                              {SendTestAccount?.map((value) => (
                                <option
                                  key={value?._id}
                                  value={JSON.stringify(value)}
                                >
                                  {value?.SMTPFromEmail}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-between align-items-center">
                          <label class=" col-form-label textright-vmb">
                            To
                          </label>
                          <div class="">
                            <input
                              id="AddSendTestEmailToIDtwo"
                              class="form-control m-input valid"
                              onBlur={HandleAddSendTestEmailToIDOne}
                            />
                            <span style={{ color: "red" }} id="validation_error1">
                              {ErrorsOne["AddSendTestEmailToIDtwo"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        SetMeetingReminderSendTest(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btnColor"
                      onClick={SendReminderHandleTest2Tab}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          {/* Send Add test end */}


          <div class="row mx-0 border-bottom pb-4">
            <div class="col-lg-12 boxsinput_group">
              <div class="row my-4">
                <div class="col-lg-8">
                  <div class="row">
                    <label class="col-lg-3 align-right">
                      Hours Before Meeting
                    </label>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-4">
                          <input
                            class="form-control m-input arrow-none"
                            type="number"
                            min="0"
                            max="24"
                            id="HoursBeforeMeetingSC2"
                            name="HoursBeforeMeeting"
                            onBlur={HandleHourBeforeSecond}
                          />
                          <span style={{ color: "red" }}>{HourSecond}</span>
                        </div>
                        <div class="col-lg-4">
                          <select
                            class="form-control m-input"
                            name="minutesBeforeMeeting"
                            id="MinutesBeforeMeetingSC2"
                          >
                            {minutesBeforeMeeting?.map((value) => (
                              <option value={value.FieldID}>
                                {value.FieldName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <label class="m-checkbox ">
                    <input
                      type="checkbox"
                      id="IssendMeetingReminderCheckedSC2"
                    />{" "}
                    Send Meeting Reminder
                  </label>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">Email Type</label>
                <div class="col-lg-5">
                  <select
                    class="form-control m-input"
                    name="emailType"
                    id="EmailTypeSC2"
                  >
                    {meetingRemainderEmailType?.map((value) => (
                      <option value={value.FieldID} key={value.FieldID}>{value.FieldName}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">Subject</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    id="SubjectSC2"
                    type="text"
                    name="SubjectSC2"
                    onBlur={HandleHourBeforeSecondSubject}
                  />
                  {SubSecond == true ? (
                    <span style={{ color: "red" }}>Please enter subject</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">CC</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    type="text"
                    id="MailCCSC2"
                    name="MailCC"
                    onBlur={HandleHourBeforetwoMailCC}
                  />
                  {MailCCSubtwo == true ? (
                    <span style={{ color: "red" }}>
                      Please enter valid email.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-2 align-right">BCC</label>
                <div class="col-lg-10">
                  <input
                    class="form-control m-input"
                    type="text"
                    id="MailBCCSC2"
                    name="MailBCC"
                    onBlur={HandleHourBeforetwoMailBCC}
                  />
                  {MailBCCSubtwo == true ? (
                    <span style={{ color: "red" }}>
                      Please enter valid email.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div class="col-lg-12 boxsinput_group">
              <div class="row">
                <label class="col-lg-2 align-right">Reminder Text</label>
                <div class="col-lg-10">
                  <FroalaEditor
                    id="signature"
                    config={config}
                    onModelChange={MeetingReminderSc2ModelChange}
                    model={SignatureSC2.data}
                  />
                </div>
              </div>
            </div>
          </div>

   

          <div className="row mx-0 border-bottom">
            <div className="col">
              <h4 className="headertitle xs-headertitle float-left pl-0">Preview</h4>
            </div>
          </div>
          <div className="row mt-3 mx-0">
            <div className="col px-4 font-small">
              <p>
                <b>Subject : </b>
                {SubjectPerview}
              </p>
              <p>{parse(BodyPerview)}</p>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}


