import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";
import{GetUserDetails} from '../../../_helpers/Utility';
import SearchIcon from '@material-ui/icons/Search';

import Pagenations from "../../Pagenations";

const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function SeoTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reload, SetReload] = React.useState(false)
  const [IsArchive,SetIsArchive] = React.useState(false)
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetfAnchorEl] = React.useState(null);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [AID, SetAID] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect( () => {
  
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ResponderGetMethod(GetUserData.ParentUserID,GetUserData.Role);
  }, [Search, Page, RowsPerPage,Reload,IsArchive]);

  //get responder  list
  const ResponderGetMethod = (UID,URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
      IsArchive:IsArchive
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/seo/GetSeoList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value?.trim();
        if(SearchedVal==Search){
          SetSearch(SearchedVal);
          SetPage(1);
        }else{
          SetSearch(SearchedVal);
          SetPage(1);
    
        }
      }
  };
  
  //change Page
  const HandleChangePage = (Event, NewPage) => {
      if(NewPage==Page){
        SetPage(NewPage);
      }else{
          SetPage(NewPage);
         
      }
  };
  
  //change display rows
  const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
     
  };
  
  //get sort field data
  const SortData = (Field) => {
    if(Field == '2FA' || Field == 'Action'){
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);

    }else{
      SetIsLoading(true)
      var SearchedVal = document.getElementById("search").value?.trim();
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          UserID: UserID,
          Role: Role,
          IsArchive:IsArchive,
          Search:SearchedVal

        };
         Axios({
          url:CommonConstants.MOL_APIURL +"/seo/GetSeoList",
          method: "POST",
          data: InputParameter,
        }).then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Search: SearchedVal,
          SerchBox: SerchBox,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          UserID: UserID,
          Role: Role,
          IsArchive:IsArchive

        };
         Axios({
          url:CommonConstants.MOL_APIURL +"/seo/GetSeoList",
          method: "POST",
          data: InputParameter,
        }).then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        });
      }
    }
    
  };

  //Search archive
  const ClickOnSearch = () => {
    var check = document.getElementById("include_Archive").checked;
    SetIsArchive(check)
  };


   //edit page
   const EditPage = (id) => {
    console.log(id)
    history.push("/editseo", id);
  };


  //Handle click
  const HandleClick = (event, id) => { 
    SetAID(id);
    SetAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  //Handle close
  const HandleClose = () => {
    SetAID(null)
    SetAnchorEl(null);
    setSelectedRowId(null);
  };

  //Handle close one
  const HandleClickOne = (event, id) => { 
    SetAID(id);
    SetfAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  //Handle close Second
  const HandleCloseSecond = () => {
    SetfAnchorEl(null);
    SetAID(null)
    setSelectedRowId(null);
  };
//handle change anchrEl
const HandleCloseOne = () => {
  SetfAnchorEl(null);
  setSelectedRowId(null);
};


    //Update Status
    const UpdateStatus=(ID,Flag)=>{
      const InputParameters={
        ID:ID,
        Flag:Flag,
        LastUpdatedBy:CUserID,
        LastUpdatedDate:new Date()
      }
      Axios({
        url: CommonConstants.MOL_APIURL + "/seo/UpdateIsTwoStepVerification",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        if (res) {
          if ((res.statusText = "Ok")) {
            if(res.data.Data.IsTwoStepVerification == true){
              toast.success(<div className="toastsize">SEO <br/> Two Step Verification Activated successfully. </div>);
            }else{
              toast.success(<div className="toastsize">SEO <br/> Two Step Verification Deactivated  successfully. </div>);
            }
            ResponderGetMethod(UserID,Role)
          } else {
          }
        }
      });
    }

      //get responder list 
  const ResponderGetMethodReRender = (UID,URole,IsArchive) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
      IsArchive:IsArchive
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/seo/GetSeoList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

      //Archive User
  const ArchivedUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Archive an SEO.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "Archive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/seo/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Archived!",
                "SEO Archived successfully.",
                "success"
              );
              SetIsArchive(false)
              HandleClose();
              HandleCloseSecond();
              ResponderGetMethodReRender(UserID,Role,false)
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  //Unarchive User
  const UnArchiveUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to unarchive an SEO.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unarchive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "UnArchive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/seo/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Unarchived!",
                "SEO unarchived successfully.",
                "success"
              );
              SetIsArchive(false)
              HandleClose();
              HandleCloseSecond();
              ResponderGetMethodReRender(UserID,Role,false)
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  //Delete user
  const DeleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an SEO.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "delete",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:CommonConstants.MOL_APIURL + "/seo/DeleteSeo",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "SEO deleted successfully.",
                "success"
              );
              HandleClose();
              HandleCloseSecond();
              ResponderGetMethod(UserID,Role)
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseSecond();
      }
    });
  };

  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      <div className="row">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3">
          <div className="fulleriger d-flex align-items-center"> 
            <label class="check_bx mr-2">
              <input id="include_Archive" type="checkbox" />
              <span className="checkmark"></span>
              Include Archived SEO
            </label>
            <button
              onClick={ClickOnSearch}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span> <SearchIcon />
                <span>Search</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search {" "} 
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">            <TableHead>
              <TableRow>
              <TableCell onClick={() => { SortData("2FA"); setSortedColumn("2FA");}}>

                  Is Two Step Verification
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "2FA" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "2FA" ? "active" : null} />
                  </span>
              </TableCell>
                <TableCell onClick={() => { SortData("ResponderName"); setSortedColumn("ResponderName");}}>
                    Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "ResponderName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "ResponderName" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                </TableCell>
                {/* <TableCell onClick={() => { SortData("SlackAccount"); setSortedColumn("SlackAccount");}}>
                    Slack Account
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "SlackAccount" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "SlackAccount" ? "active" : null} />
                  </span>
                </TableCell> */}
                <TableCell onClick={() => { SortData("Action"); setSortedColumn("Action");}}>Action <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Action" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Action" ? "active" : null} />
                  </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { Rows.length == 0 ? <p className="text-center">No data in Available</p> :
            Rows?.map((row, index) => (
                <TableRow key={row._id}>
                <TableCell>
                <div class="toggleswich">
          <input type="checkbox" onChange={()=>{UpdateStatus(row._id,!row.IsTwoStepVerification)}} class="checktogle" checked={row.IsTwoStepVerification}  />
          <b class="switch"> 
            <span class="checkion"></span>
            <span class="uncheckion"></span>
          </b>
          <b class="track"></b>
        </div>
                </TableCell>
                  <TableCell><div className="flexcontentpro">
                      <span class="carduser_pic mr-2">
                    <img
                      style={imgstyle}
                      src={
                        row?.ProfileImage == "NULL"
                          ? CommonConstants.Image_url + "default.png"
                          : CommonConstants.Image_url + row?.ProfileImage
                      }
                    ></img></span>
                    <span>{row?.FullName}</span>
                  </div>
                  </TableCell>
                  <TableCell>{row?.Email}</TableCell>
                  {/* <TableCell>{row?.SlackAccount}</TableCell> */}

                  <TableCell>
                    {row?.IsArchive ? (
                      <a className="btn-eyesicon dopdownwe">
                        <Button
                          // aria-controls="simple-menu"
                          // aria-haspopup="true"
                          // onClick={(e) => {
                          //   HandleClick(e, row?._id);
                          // }}
                          aria-controls={`simple-menu-${index}`}
                          aria-haspopup="true"
                          onClick={(e) => HandleClick(e, row._id)}
                        >
                          <i className="la la-ellipsis-h add-icon"></i>
                        </Button>
                        <Menu
                        className="droptable dopdownwe"
                          // id="simple-menu"
                          // anchorEl={AnchorEl}
                          // keepMounted
                          // open={Boolean(AnchorEl)}
                          // onClose={HandleClose}

                          anchorEl={AnchorEl}
                          open={Boolean(AnchorEl && row._id === selectedRowId)}
                          onClose={HandleClose}
                          id={`simple-menu-${index}`}
                        >
                          <MenuItem
                            onClick={DeleteUser}
                          >
                            <i className="la flaticon-delete-1 mr-3"></i>{" "} Delete
                          </MenuItem>
                          <MenuItem
                            onClick={UnArchiveUser}
                          >
                           <i className="la flaticon-download mr-3"></i>{" "}
                            Unarchive
                          </MenuItem>
                        </Menu>
                      </a>
                    ) : (
                      <div>
                        <a title="Edit"
                          onClick={() => {
                            EditPage(row?._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a className="btn-eyesicon dopdownwe">
                          <Button 
                            // aria-controls="simple-menu"
                            // aria-haspopup="true"
                            // onClick={(e) => {
                            //   HandleClickOne(e, row?._id);
                            // }}
                            aria-controls={`simple-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) => HandleClickOne(e, row._id)}
                          >
                            <i className="la la-ellipsis-h add-icon"></i>
                          </Button>
                          <Menu className="droptable"
                            // id="simple-menu1"
                            // anchorEl={AnchorElf}
                            // keepMounted
                            // open={Boolean(AnchorElf)}
                            // onClose={HandleCloseOne}
                            anchorEl={AnchorElf}
                            open={Boolean(AnchorElf && row._id === selectedRowId)}
                            onClose={HandleCloseOne}
                            id={`simple-menu-${index}`}
                          >
                            <MenuItem
                              onClick={ArchivedUser}
                            >
                              <i className="la flaticon-download mr-3"></i> Archive
                            </MenuItem>
                          </Menu>
                        </a>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <div class='pb-3'>
      <Pagenations
      className=""
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
      </div>
     
    </div>
    
  );
}
