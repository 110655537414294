import React, { useState, useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { CommonConstants, skipScriptVariable, scriptVariable } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../../_components/user/StickyHeader/StickyHeader.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchIcon from '@material-ui/icons/Search';
import { Subject } from "@material-ui/icons";

// A function to reorder the rows array when dragged
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function DispositionsEmailTable(props) {
    const [Data, SetData] = React.useState([]);
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [Rows, SetRows] = React.useState([]);
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(100);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("OrderBy");
    const [SortedBy, SetSortedBy] = React.useState(1);
    const [ClientID, SetClientID] = React.useState(0);
    const [UserID, SetUserID] = React.useState(0);
    const [Role, SetRole] = React.useState("");
    const [CUserID, SetCUserID] = React.useState(0);
    const [IsLoading, SetIsLoading] = React.useState(true);
    const [IsDataLoaded, setIsDataLoaded] = React.useState(false);
    const [IsScript, setIsScript] = useState(false);
    const [SignatureSC, SetSignatureSC] = useState({ data: "" });
    const [ButtonLabel, SetButtonLabel] = useState("Add");
    const [DataID, SetDataID] = useState("");
    const [OpenScript, SetOpenScript] = React.useState(false);
    const [Name, SetName] = React.useState("");
    const [NoteDetail, SetNoteDetail] = React.useState("");
    const [SubjectData, SetSubjectData] = React.useState("");
    const [AnchorEl, SetAnchorEl] = React.useState(null);
    const [AnchorElf, SetfAnchorEl] = React.useState(null);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [AID, SetAID] = React.useState("");
    const [IsArchive, SetIsArchive] = React.useState(false)
    let config = {
        placeholderText: "Type something!",
        charCounterCount: false,
        toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "insertLink",
            "html",
            "Variable",
        ],

        imageUploadRemoteUrls: false,
        enter: Froala.ENTER_BR,
        key: CommonConstants.EditorValidationKey,
        heightMin: '230px',
        heightMax: '100%',
        resize: true,
        pastePlain: true,
        attribution: false,
        pasteDeniedAttrs: ['style']
    };

    // const onDragEnd = async (result) => {
    //     SetIsLoading(true)
    //     if (!result.destination) return;
    //     const newRows = reorder(Rows, result.source.index, result.destination.index);
    //     //SetRows(newRows);
    //     await ReOrderData(newRows);
    //     SetIsLoading(false);
    //     await DispositionEmailGet(ClientID, UserID, Role);
    // };

    const onDragEnd = async (result) => {
        SetIsLoading(true); // Set the loader to true at the start
        if (!result.destination) {
            SetIsLoading(false); // If there's no destination, stop the loader
            return;
        }

        const newRows = reorder(Rows, result.source.index, result.destination.index);
        try {
            // Attempt to reorder data and call the script API
            await ReOrderData(newRows);
            await DispositionEmailGet(ClientID, UserID, Role);
        } catch (error) {
            console.error("Error during API calls:", error); // Log any errors encountered
        } finally {
            // Always set the loader to false, regardless of success or failure
            SetIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Dispositions Status | SalesHive';
        let Details = GetUserDetails();
        if (Details != null) {
            SetClientID(Details.ClientID);
            SetUserID(Details.ParentUserID);
            SetCUserID(Details.ChildUserID);
            SetRole(Details.Role);
        }
        GetVariableDropDownDetails();
        DispositionEmailGet(Details.ClientID, Details.ParentUserID, Details.Role);
    }, [Search, Page, RowsPerPage, IsArchive]);

    // get value for text editor add
    const VariableModelChange = (model) => {
        model = model.replace(/<[\/]{0,1}(p)[^><]*>/ig, '')
        SetSignatureSC({
            data: model,
        });
    };

    //Get Campaign step details
    const GetVariableDropDownDetails = async () => {
        var VariableDropDown = await scriptVariable;
        var TempVariableDropDown = {};
        for (var i = 0; i < VariableDropDown.length; i++) {
            TempVariableDropDown["{" + VariableDropDown[i].split(" ").join("") + "}"] =
                VariableDropDown[i];
        }
        Froala.RegisterCommand("Variable", {
            title: "Advanced options",
            type: "dropdown",
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: TempVariableDropDown,
            callback: function (cmd, val) {
                var editorInstance = this;
                editorInstance.html.insert(val);
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            },
        });
    }

    const ReOrderData = async (Arr) => {
        var data = {
            DispositionEmailArray: Arr,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role
        }
        const response = await Axios({
            url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailReOrder",
            method: "POST",
            data: data,
        });
        if (response.data.StatusMessage === "SUCCESS") {
            toast.success("Reordered Successfully");
        } else {
            toast.error("Failed to reorder");
        }
    }

    //dispositions status get list
    const DispositionEmailGet = (CID, UID, URole) => {
        SetIsLoading(true)
        let ScriptData = {
            Page: Page,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            SortBy: SortedBy,
            Search: Search,
            Type: "User",
            ClientID: CID,
            UserID: UID,
            Role: URole,
            IsArchive: IsArchive
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailGet",
            method: "POST",
            data: ScriptData,
        });
        ApiResponse.then((result) => {
            SetData(result.data.PageData);
            SetRows(result.data.PageData);
            SetRlen(result.data.TotalCount);
            SetFlen(result.data.TotalCount);
            SetCountPage(result.data.PageCount);
            SetIsLoading(false);
            setIsDataLoaded(true);

        });
    };
    // Search details
    const RequestSearch = (event) => {
        if (event.key === "Enter") {
            let SearchedVal = document.getElementById("search").value;
            if (SearchedVal == Search) {
                SetSearch(SearchedVal);
                SetPage(1);
            } else {
                SetSearch(SearchedVal);
                SetPage(1);
                SetIsLoading(true);
            }
        }
    };
    // Delete function
    const DeleteBtn = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to delete a disposition email.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let ContactTagData = {
                    _id: AID,
                    LastUpdatedBy: CUserID,
                    LastUpdatedDate: new Date(),
                    Role: Role,
                    ClientID: ClientID,
                    UserID: UserID
                };
                Axios({
                    url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailDelete",
                    method: "POST",
                    data: ContactTagData,
                }).then((res) => {

                    if (res) {
                        if ((res.StatusMessage = "SUCCESS")) {
                            Swal.fire(
                                "Deleted!",
                                "Disposition Email deleted successfully.",
                                "success"
                            );
                            SetIsLoading(true);
                            DispositionEmailGet(ClientID, UserID, Role);
                            SetPage(1)
                            SetSearch("")
                            HandleClose()
                            HandleCloseOne()
                            HandleCloseSecond();
                            document.getElementById("search").value = ""
                        } else {
                        }
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
        });
    };

    //Close Notes 
    const CloseNotes = () => {
        SetOpenScript(false);
        SetNoteDetail("");
        SetSubjectData("");
        SetName("");
    }

    // copy script
    const CopyBtn = async (id) => {
        let DispositionEmailData = {
            ID: id,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role,
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailCopy",
            method: "POST",
            data: DispositionEmailData,
        });
        ApiResponse.then((result) => {
            if (result.data.StatusMessage === "SUCCESS") {
                toast.success(
                    <div className="toastsize">
                        Copy Disposition Email
                        <br />
                        Disposition email copied successfully.
                    </div>
                );
                SetIsLoading(true);
                DispositionEmailGet(ClientID, UserID, Role);
            } else {
                toast.error(
                    <div className="toastsize">
                        Copy Disposition Email
                        <br />
                        Failed to copy disposition email.
                    </div>
                );
            }
        });
    }

    // change page
    const HandleChangePage = (event, NewPage) => {
        if (NewPage == Page) {
            SetPage(NewPage);
        } else {
            SetPage(NewPage);
            SetIsLoading(true);
        }
    };

    // change display rows
    const ChangeRowSelected = (Event) => {
        SetRowsPerPage(Number(Event.target.value));
        SetPage(1);
        SetIsLoading(true);
    };

    // edit button
    const EditBtn = async (id) => {
        SetDataID(id);
        var Data = {
            ID: id,
            Role: Role
        };
        var requestAddScript = await Axios({
            url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailGetByID",
            method: "POST",
            data: Data,
        });
        if (requestAddScript.data?.StatusMessage === "SUCCESS") {
            setIsScript(true)
            SetButtonLabel("Edit");
            SetSignatureSC({ data: requestAddScript.data?.DispositionEmailData.Body });
            document.getElementById('AddDispositionEmailName').value = requestAddScript.data?.DispositionEmailData?.Name;
            document.getElementById('AddDispositionEmailSubject').value = requestAddScript.data?.DispositionEmailData?.Subject;
        }
    };
    // add button
    const AddBtn = (id) => {
        setIsScript(true)
        SetButtonLabel("Add");
    };

    const ViewBtn = async (id) => {
        SetIsLoading(true)
        var Data = {
            ID: id,
            Role: Role
        };
        var requestDataScript = await Axios({
            url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailView",
            method: "POST",
            data: Data,
        });
        if (requestDataScript.data?.StatusMessage === "SUCCESS") {
            SetOpenScript(true);
            SetName(requestDataScript.data?.DispositionData?.Name);
            SetSubjectData(requestDataScript.data?.DispositionData?.Subject);
            var BodyData = await ReplaceBody(requestDataScript.data?.DispositionData?.Body, requestDataScript.data?.ContactData);
            SetNoteDetail(BodyData);
        } else {
            toast.error("Failed to view script")
        }
        SetIsLoading(false)
    };

    const ReplaceBody = async (Body, Arr) => {
        try {
            let randomIndex = Math.floor(Math.random() * Arr.length);
            let randomObject = Arr[randomIndex];
            console.log(randomObject);
            var reg = Body.match(/{(.*?)}/g);
            if (reg !== null) {
                for (let i = 0; i < reg.length; i++) {
                    var variable = reg[i].replace(/[{}]/g, '');
                    if (randomObject[variable]) {
                        Body = Body.replace(reg[i], randomObject[variable]);
                    }
                }
            }
            return Body;

        } catch (e) {
            toast.error("Failed to replace variables");
        }
    }

    const AddScriptClose = async () => {
        setIsScript(false)
        SetSignatureSC({ data: "" });
    }
    const DispositionEmailPopupSubmit = async () => {
        
        var BodyMain = SignatureSC.data.replace(/<[\/]{0,1}(p)[^><]*>/ig, '').trim()
        var DispositionEmailName = document.getElementById('AddDispositionEmailName').value.trim();
        var DispositionEmailSubject = document.getElementById('AddDispositionEmailSubject').value.trim();
        function hasVariables(str) {
            // Convert skipVariables to lowercase for case-insensitive comparison
            let skipVariablesOne = skipScriptVariable.map(variable => variable.toLowerCase());
            var regsub = str.match(/{(.*?)}/g);
            if (regsub === null) {
                return false;
            }
            // Filter out variables that should be skipped and convert to lowercase in the filter function
            regsub = regsub.filter(variable => !skipVariablesOne.includes(variable.toLowerCase()));
            return regsub.length > 0;
        }

        // Check if body or subject contain variables
        var bodyHasVariables = hasVariables(BodyMain);
        if (bodyHasVariables === false) {
            if (BodyMain == "" && DispositionEmailName == "" && DispositionEmailSubject == "") {
                toast.error(
                    <div className="toastsize">
                        Add Disposition Email
                        <br />
                        Name, Body and Subject is empty, fill it!
                    </div>
                );
            } else if (DispositionEmailName == "") {
                toast.error(
                    <div className="toastsize">
                        Add Disposition Email
                        <br />
                        Email Disposition Name could not be Empty!
                    </div>
                );
            }else if (DispositionEmailSubject == "") {
                toast.error(
                    <div className="toastsize">
                        Add Disposition Email
                        <br />
                        Email Disposition Subject could not be Empty!
                    </div>
                );
            } else if (BodyMain == "") {
                toast.error(
                    <div className="toastsize">
                        Add Disposition Email
                        <br />
                        Body is empty, fill it!
                    </div>
                );
            }else {
                if (ButtonLabel === "Add") {
                    var Data = {
                        Name: DispositionEmailName,
                        Body: BodyMain,
                        Subject: DispositionEmailSubject,
                        Role: Role,
                        CreatedBy: CUserID,
                        CreatedDt: new Date(),
                        ClientID: ClientID,
                        UserID: UserID
                    };
                    var requestAddScript = await Axios({
                        url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailAdd",
                        method: "POST",
                        data: Data,
                    });
                    if (requestAddScript.data?.StatusMessage === "EXISTS") {
                        toast.error(
                            <div className="toastsize">
                                Add Disposition Email
                                <br />
                                Email Template Name already exists.!
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "ERROR") {
                        toast.error(
                            <div className="toastsize">
                                Add Disposition Email
                                <br />
                                {requestAddScript.data?.Message}
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "SUCCESS") {
                        setIsScript(false)
                        SetSignatureSC({ data: "" });
                        toast.success(<p>Add Disposition Email<br />You have inserted new disposition email.</p>);
                        DispositionEmailGet(ClientID, UserID, Role)
                    }
                } else if (ButtonLabel === "Edit") {
                    var Data = {
                        ID: DataID,
                        Name: DispositionEmailName,
                        Subject:DispositionEmailSubject,
                        Body: BodyMain,
                        Role: Role,
                        LastUpdatedBy: CUserID,
                        LastUpdatedDate: new Date(),
                        ClientID: ClientID,
                        UserID: UserID
                    };
                    var requestAddScript = await Axios({
                        url: CommonConstants.MOL_APIURL + "/disposition_email/DispositionEmailUpdate",
                        method: "POST",
                        data: Data,
                    });
                    if (requestAddScript.data?.StatusMessage === "EXISTS") {
                        toast.error(
                            <div className="toastsize">
                                Edit Disposition Email
                                <br />
                                Email Template Name already exists.!
                            </div>
                        );
                    } else if (requestAddScript.data?.StatusMessage === "ERROR") {
                        toast.error(
                            <div className="toastsize">
                                Edit Disposition Email
                                <br />
                                {requestAddScript.data?.Message}
                            </div>);
                    } else if (requestAddScript.data?.StatusMessage === "SUCCESS") {
                        setIsScript(false)
                        SetSignatureSC({ data: "" });
                        SetDataID("");
                        toast.success(<p>Edit Disposition Email<br />You have Updated disposition email successfully.</p>);
                        DispositionEmailGet(ClientID, UserID, Role)
                    }
                }

            }
        } else {
            toast.error(<p>Add Disposition Email<br />Wrong Variable inserted in body.</p>);
        }
    }

    const ClickOnSearch = () => {
        var check = document.getElementById("include_Archive").checked;
        SetIsArchive(check)
    };

    //Handle click
    const HandleClick = (event, id) => {
        SetAID(id);
        SetAnchorEl(event.currentTarget);
        setSelectedRowId(id);
    };

    const HandleClose = () => {
        SetAID(null)
        SetAnchorEl(null);
        setSelectedRowId(null);
    };

    //Handle close one
    const HandleClickOne = (event, id) => {
        SetAID(id);
        SetfAnchorEl(event.currentTarget);
        setSelectedRowId(id);
    };

    //Handle close Second
    const HandleCloseSecond = () => {
        SetfAnchorEl(null);
        SetAID(null)
        setSelectedRowId(null);
    };
    //handle change anchrEl
    const HandleCloseOne = () => {
        SetfAnchorEl(null);
        setSelectedRowId(null);
    };
    //Archive User
    const ArchivedUser = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to Archive a Disposition Email.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, archive it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((Result) => {
            if (Result.isConfirmed) {
                var InputParameters = {
                    uID: AID,
                    Type: "Archive",
                    LastUpdatedDate: new Date(),
                    LastUpdatedBy: UserID,
                    Role: Role,
                };
                Axios({
                    url:
                        CommonConstants.MOL_APIURL +
                        "/disposition_email/ArchiveProfile",
                    method: "POST",
                    data: InputParameters,
                }).then((Res) => {
                    if (Res) {
                        if ((Res.statusText = "Ok")) {
                            Swal.fire(
                                "Archived!",
                                "Disposition Email Archived successfully.",
                                "success"
                            );
                            SetIsArchive(false)
                            HandleCloseOne()
                            HandleCloseSecond();
                            SetPage(1);
                            SetSearch("");
                            const checkbox = document.getElementById("include_Archive");
                            if (checkbox !== null) {
                                checkbox.checked = false;
                            } else {
                                console.error('Element with ID "include_Archiv" not found.');
                            }
                            document.getElementById("search").value = "";
                            DispositionEmailGet(ClientID, UserID, Role);
                        } else {
                        }
                    }
                });
            } else if (Result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your data is safe :)", "error");
                HandleCloseOne()
                HandleCloseSecond();
            }
        });
    };

    //Unarchive User
    const UnArchiveUser = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to unarchive a Disposition Email.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, unarchive it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((Result) => {
            if (Result.isConfirmed) {
                var InputParameters = {
                    uID: AID,
                    Type: "UnArchive",
                    LastUpdatedDate: new Date(),
                    LastUpdatedBy: UserID,
                    Role: Role,
                };
                Axios({
                    url:
                        CommonConstants.MOL_APIURL +
                        "/disposition_email/ArchiveProfile",
                    method: "POST",
                    data: InputParameters,
                }).then((Res) => {
                    if (Res) {
                        if ((Res.statusText = "Ok")) {
                            Swal.fire(
                                "Unarchived!",
                                "Disposition Email unarchived successfully.",
                                "success"
                            );
                            SetIsArchive(false)
                            HandleCloseOne()
                            HandleCloseSecond();
                            SetPage(1);
                            SetSearch("");
                            DispositionEmailGet(ClientID, UserID, Role);
                            document.getElementById("search").value = "";
                            const checkbox = document.getElementById("include_Archive");
                            if (checkbox !== null) {
                                checkbox.checked = false;
                            } else {
                                console.error('Element with ID "include_Archive" not found.');
                            }
                        } else {
                        }
                    }
                });
            } else if (Result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your data is safe :)", "error");
                HandleCloseOne()
                HandleCloseSecond();
            }
        });
    };

    const ActiveUpdate = async (ID, Flag) => {

        var Str_in = {
            IsActive: !Flag,
            ID: ID,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role,
        };
        const rows1 = await Axios({
            url:
                CommonConstants.MOL_APIURL + "/disposition_email/ActiveUpdate",
            method: "POST",
            data: Str_in,
        });
        if (rows1.data.StatusMessage == "SUCCESS") {
            if (!Flag === true) {
                toast.success(<div>Disposition Email<br />Email Template has been activated.</div>);
            } else {
                toast.success(<div>Disposition Email<br />Email Template has been deactivated.</div>);
            }
            DispositionEmailGet(ClientID, UserID, Role)
        }
        else {
            toast.error(<div>Campaign<br />Please Add Account, Prospect and Step at least one.</div>);
        }
    }

    return (
        <div>
            {IsLoading ? (
                <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div>
            ) : (
                <></>
            )}
            <div className="row align-items-center border-bottom mx-0">
                <div className="col">
                    <h4 className="headertitlebd xs-headertitle float-left ">Disposition Email</h4>
                </div>
                <div className="col">
                    <div className="listing-li float-right">
                        <ul class='my-0'>
                            <li>
                                <a
                                    onClick={() => {
                                        AddBtn();
                                    }}
                                    // onClick={this.AddBtn}
                                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                >
                                    <i className="la la-icon-font-size-13 la-plus"></i>
                                    <span>Add</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class=''>
                <div className="row">
                    <div className="col padd-0"></div>
                    <div className="col padright flexdisplayer pr-3">
                        <div className="fulleriger d-flex align-items-center">
                            <label class="check_bx mr-2">
                                <input id="include_Archive" type="checkbox" />
                                <span className="checkmark"></span>
                                Include Archived disposition email
                            </label>
                            <button
                                onClick={ClickOnSearch}
                                type="submit"
                                className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
                            >
                                <span> <SearchIcon />
                                    <span>Search</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col padd-0"></div>
                </div>
                <div className="row pt-4 mx-0">
                    <div className="col-sm-12 col-md-6">
                        <label className="textlabelte">
                            Show
                            <select
                                name="tbl_meeting_length"
                                onChange={ChangeRowSelected}
                                aria-controls="tbl_meeting"
                                className="form-control form-control-sm"
                                value={RowsPerPage}
                            >
                                {CommonConstants.show_rows.map((value) => (
                                    <option value={value} key={value}>{value}</option>
                                ))}
                            </select>
                            entries
                        </label>
                    </div>
                    <div className="col-sm-12 col-md-6 full-right">
                        <label className="textlabelte">
                            Search:{" "}
                            <input
                                type="search"
                                id="search"
                                onKeyPress={RequestSearch}
                                className="form-control form-control-sm ml-2"
                                placeholder=""
                                aria-controls="tbl_meeting"
                            />
                        </label>
                    </div>
                </div>
                <div class='px-3'>
                    <div className="table-bordered">
                        <TableContainer component={Paper}>
                            <StyleHeader isDataLoaded={IsDataLoaded} />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="Rows" direction="vertical">
                                    {(provided) => (
                                        <Table className="table-ref" {...provided.droppableProps} ref={provided.innerRef} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {Rows?.length === 0 ? (
                                                    <p className="text-center">No data available in table</p>
                                                ) : (
                                                    Rows?.map((row, index) => (
                                                        <Draggable key={row._id} draggableId={row._id} index={index}>
                                                            {(provided) => (
                                                                <>
                                                                    <TableRow key={row?._id}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <TableCell align="center" {...provided.dragHandleProps}>
                                                                            <i class="fa fa-bars cursor-pointer"></i>

                                                                        </TableCell>
                                                                        <TableCell className='leftsticky'><div class="toggleswich">
                                                                            <input type="checkbox" class="checktogle" onChange={() => {
                                                                                if (!row.IsArchive) {  // Disable onChange if IsArchive is true
                                                                                    ActiveUpdate(row._id, row.IsActive);
                                                                                }
                                                                            }} checked={row?.IsActive} disabled={row.IsArchive ? true : false} />
                                                                            <b class="switch">
                                                                                <span class="checkion"></span>
                                                                                <span class="uncheckion"></span>
                                                                            </b>
                                                                            <b class="track"></b>
                                                                        </div>
                                                                        </TableCell>
                                                                        <TableCell>{row.Name}</TableCell>
                                                                        <TableCell>
                                                                            <a
                                                                                onClick={() => {
                                                                                    EditBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="Edit"
                                                                            >
                                                                                <i className="la flaticon-edit-1 edit-icon"></i>
                                                                            </a>
                                                                            {/* <a
                                                                                onClick={() => {
                                                                                    DeleteBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="Delete"
                                                                            >
                                                                                <i className="la flaticon-delete-1 delete-icon"></i>
                                                                            </a> */}
                                                                            <a
                                                                                onClick={() => {
                                                                                    CopyBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="Duplicate"
                                                                            >
                                                                                <i className="la flaticon-add add-icon edit-icon"></i>
                                                                            </a>
                                                                            <a
                                                                                onClick={() => {
                                                                                    ViewBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="View"
                                                                            >
                                                                                <i className="la flaticon-eye edit-icon"></i>
                                                                            </a>
                                                                            {row.IsArchive ? (<a className="btn-eyesicon dopdownwe">
                                                                                <Button
                                                                                    // aria-controls="simple-menu"
                                                                                    // aria-haspopup="true"
                                                                                    // onClick={(e) => {
                                                                                    //   HandleClick(e, row?._id);
                                                                                    // }}
                                                                                    aria-controls={`simple-menu-${index}`}
                                                                                    aria-haspopup="true"
                                                                                    onClick={(e) => HandleClick(e, row._id)}
                                                                                >
                                                                                    <i className="la la-ellipsis-h add-icon"></i>
                                                                                </Button>
                                                                                <Menu
                                                                                    className="droptable dopdownwe"
                                                                                    // id="simple-menu"
                                                                                    // anchorEl={AnchorEl}
                                                                                    // keepMounted
                                                                                    // open={Boolean(AnchorEl)}
                                                                                    // onClose={HandleClose}

                                                                                    anchorEl={AnchorEl}
                                                                                    open={Boolean(AnchorEl && row._id === selectedRowId)}
                                                                                    onClose={HandleClose}
                                                                                    id={`simple-menu-${index}`}
                                                                                >
                                                                                    <MenuItem
                                                                                        onClick={DeleteBtn}
                                                                                    >
                                                                                        <i className="la flaticon-delete-1 mr-3"></i>{" "} Delete
                                                                                    </MenuItem>
                                                                                    <MenuItem
                                                                                        onClick={UnArchiveUser}
                                                                                    >
                                                                                        <i className="la flaticon-download mr-3"></i>{" "}
                                                                                        Unarchive
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                            </a>) : (<a className="btn-eyesicon dopdownwe">
                                                                                <Button
                                                                                    // aria-controls="simple-menu"
                                                                                    // aria-haspopup="true"
                                                                                    // onClick={(e) => {
                                                                                    //   HandleClickOne(e, row?._id);
                                                                                    // }}
                                                                                    aria-controls={`simple-menu-${index}`}
                                                                                    aria-haspopup="true"
                                                                                    onClick={(e) => HandleClickOne(e, row._id)}
                                                                                >
                                                                                    <i className="la la-ellipsis-h add-icon"></i>
                                                                                </Button>
                                                                                <Menu className="droptable"
                                                                                    // id="simple-menu1"
                                                                                    // anchorEl={AnchorElf}
                                                                                    // keepMounted
                                                                                    // open={Boolean(AnchorElf)}
                                                                                    // onClose={HandleCloseOne}
                                                                                    anchorEl={AnchorElf}
                                                                                    open={Boolean(AnchorElf && row._id === selectedRowId)}
                                                                                    onClose={HandleCloseOne}
                                                                                    id={`simple-menu-${index}`}
                                                                                >
                                                                                    <MenuItem
                                                                                        onClick={ArchivedUser}
                                                                                    >
                                                                                        <i className="la flaticon-download mr-3"></i> Archive
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                            </a>)}

                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )}
                                                        </Draggable>
                                                    )))}
                                            </TableBody>

                                            <div className="row"></div>
                                        </Table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </TableContainer>
                    </div>
                </div>

                <div className="row mx-0 pb-2">
                    <div className="col dataTables_info">
                        <p>
                            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                            entries
                        </p>
                    </div>
                    <div className="col pageright">
                        <Pagination
                            component="div"
                            count={CountPage}
                            page={Page}
                            onChange={HandleChangePage}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </div>
            </div>
            <Popup open={IsScript}>
                <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                            <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">{ButtonLabel} Disposition Email</h5>
                                    <button
                                        className="close"
                                        onClick={AddScriptClose}
                                    >
                                        <span
                                            aria-hidden="true"
                                            class="la la-remove"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="px-4">
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Name
                                        </label>
                                        <div class="col-lg-9 d-flex">
                                            <input
                                                class="form-control m-input"
                                                type="text"
                                                id="AddDispositionEmailName"
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Subject
                                        </label>
                                        <div class="col-lg-9 d-flex">
                                            <input
                                                class="form-control m-input"
                                                type="text"
                                                id="AddDispositionEmailSubject"
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Body
                                        </label>
                                        <div class="col-lg-9">
                                            <div id="parent-wrapper-id" className="resizer-block">
                                                <FroalaEditor
                                                    id="signature2"
                                                    config={{
                                                        scrollableContainer: '#parent-wrapper-id',
                                                        ...config
                                                    }}
                                                    onModelChange={VariableModelChange}
                                                    model={SignatureSC.data}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={AddScriptClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    id="SubmitStepTemplate"
                                    onClick={DispositionEmailPopupSubmit}
                                    class="btn btn-primary btnColor"
                                >
                                    <i class="la la-save mr-1"></i>Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
            <Popup open={OpenScript} modal>
                <div className="modal-black"></div>
                <div className="filterPopup bigpopupcontent">
                    <div className="paddingboxTerms">
                        <div className="modal-header">
                            <h5>{Name}</h5>
                            <a className="close-ion" onClick={() => { CloseNotes(); }}>
                                <span aria-hidden="true" class="la la-remove"></span>
                            </a>
                        </div>
                        <div className="modal-content bordernone">
                        <div class="row max-cols d-flex flex-column">
                                <h6>Subject</h6>
                                <p> {SubjectData}</p>
                            </div>
                            <div class="row max-cols d-flex flex-column">
                                <h6>Body</h6>
                                <p><div dangerouslySetInnerHTML={{ __html: NoteDetail }} /></p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                onClick={() => { CloseNotes(); }}
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}
