import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddTwilioPhoneNumber extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          ClientID: null,
          UserID: null,
          CUserID: null,
          Role: null,
          PhoneNumber: "",
          RedirectPhoneNumber: "",
          errors: {},
          isPhoneNumberExists: false,
          IsButtonDisabled: false,
          IsTwilioPhoneValidation:false
        };
      }

    componentDidMount() {
        document.title = "Add Twilio Phone Number | SalesHive";
        let Details = GetUserDetails();
        if (Details != null) {
        this.setState({ ClientID: Details.ClientID });
        this.setState({ UserID: Details.ParentUserID });
        this.setState({ CUserID: Details.ChildUserID });
        this.setState({ Role: Details.Role });
        }
    }

     // Validate the phone number (digits only, length check)
    validate = () => {
        let tempErrors = {...this.state.errors};
        let isValid = true;

        // Validate phone number
        if (!this.state.PhoneNumber) {
            tempErrors["PhoneNumber"] = "Please enter a phone number.";
            isValid = false;
        }  else if (!/^\+?[0-9]+$/.test(this.state.PhoneNumber)) {
            tempErrors["PhoneNumber"] = "Please enter only numeric values.";
            isValid = false;
        }

        // Validate redirect phone number
        if(this.state.RedirectPhoneNumber?.trim() != ""){
          if (!/^\+?[0-9]+$/.test(this.state.RedirectPhoneNumber)) {
            tempErrors["RedirectPhoneNumber"] = "Please enter only numeric values.";
            isValid = false;
        }
        }
        

        this.setState({ errors: tempErrors });
        return isValid;
    };

    // Function to check if the phone number exists via API
    checkPhoneNumberExists = async (e) => {
        try {
          // Clear validation error for PhoneNumber or RedirectPhoneNumber
          if (this.state.PhoneNumber?.trim() === "") {
            this.setState((prevState) => ({
              errors: { ...prevState.errors,PhoneNumber : "Please enter phone number." },
            }));
          } else if (!/^\+?[0-9]+$/.test(this.state.PhoneNumber)) {
            this.setState((prevState) => ({
              errors: { ...prevState.errors,PhoneNumber : "Please enter a valid phone number." },
            }));
          } else {
            const response = await Axios.post(CommonConstants.MOL_APIURL +  "/sdrassignment/CheckTwilioPhoneExists", {
              PhoneNumber: this.state.PhoneNumber,
              UserID: this.state.UserID,
              Role: this.state.Role,
          });
  
          if (response.data?.Data?.IsExists) {
              this.setState((prevState) => ({
                  isPhoneNumberExists: true,
                  errors: { ...prevState.errors, PhoneNumber: "Phone number already exists." },
                }));
          } else {
              this.setState({ isPhoneNumberExists: false });
              await this.twilioPhoneNumberValidation(this.state.PhoneNumber,this.state.Role)
          }
          
        }
        } catch (error) {
        console.error("Error checking phone number:", error);
        toast.error("Failed to check phone number.");
        }
    };

    //Checking Twilio phone number validation
    twilioPhoneNumberValidation = async (PhoneNumber,Role) => {
      try{
        this.setState({IsLoading:true})
        const response = await Axios.post(CommonConstants.MOL_APIURL +  "/sdrassignment/VerifyTwilioPhoneNumberValid", {
          PhoneNumber: PhoneNumber,
          Role:Role
      });
      if (response.data?.Data?.IsValid == false) {
          this.setState((prevState) => ({
              IsTwilioPhoneValidation: false,
              IsLoading:false,
              errors: { ...prevState.errors, PhoneNumber: "Phone number is not valid." },
            }));

      } else {
          this.setState((prevState) => ({
            IsTwilioPhoneValidation: true,
            IsLoading:false,
            errors: { ...prevState.errors, PhoneNumber: "" },
          }));

      }
      }catch(err){
        console.log(err)
        this.setState({IsLoading:false})

      }
    }

    // Handle input change and clear validation error for the changed field
    handleInputChange = (e) => {
        const { name, value } = e.target;
        let errors = this.state.errors;
    
        // Clear validation error for PhoneNumber or RedirectPhoneNumber
        if (name === "PhoneNumber") {
          if (value?.trim() === "") {
            errors.PhoneNumber = "Please enter phone number.";
          } else if (!/^\+?[0-9]+$/.test(value)) {
            errors.PhoneNumber = "Please enter a valid phone number.";
          } else {
            delete errors.PhoneNumber;
          }
          }
          if (name === "RedirectPhoneNumber") {
            if (value?.trim() != "") {
              if (!/^\+?[0-9]+$/.test(value)) {
                errors.RedirectPhoneNumber = "Please enter only numeric values.";
              } else {
                delete errors.RedirectPhoneNumber;
              }
            }
          }
    
        this.setState({[name]: value,errors});
    };

    // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (this.validate()) {
      // Check if the phone number exists via API
      await this.checkPhoneNumberExists();

      // Proceed with form submission if phone number doesn't exist
      if (!this.state.isPhoneNumberExists && this.state.IsTwilioPhoneValidation) {
        let RequestBody = {
            UserID:this.state.UserID,
            CreatedBy:this.state.CUserID,
            PhoneNumber:this.state.PhoneNumber,
            RedirectNumber:this.state.RedirectPhoneNumber,
            CreatedDate:new Date(),
        }
        try {
            const response = await Axios.post(CommonConstants.MOL_APIURL +  "/sdrassignment/AddTwilioPhoneNumber", RequestBody);
    
            if (response.data?.Data?.IsSaved) {
                history.push("/sdrassignments",{tab:1});
                toast.success(<div className="toastsize">Twilio phone number <br/>Twilio phone number added !</div>);
            } else {
                toast.error(<div className="toastsize">Twilio phone number <br/>Twilio phone number cannot added !</div>);
            }
            } catch (error) {
                console.error("Error checking phone number:", error);
                toast.error("Failed to check phone number.");
            }

      }
    }
  };

  //Back Button
  BackBtn = () => {
    history.push("/sdrassignments",{tab:1})
  }

    render() {

        return (
            <>
             {this.state.IsLoading === true ? (
                <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
                </div>
                ) : (
                    <></>
                )}
                <div className="adminmain mheight">
                    <Sidebar className="" />
                    <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Add Twilio Phone Number
                  </h4>
                </div>
              </div>

              <form onSubmit={this.handleSubmit}>
        <div className="bg-white p-3 mb-3">
          <div className="row py-2">
            {/* Phone Number Input */}
            <div className="col-lg-6 boxsinput_group">
              <div className="row max-cols">
                <label className="col-lg-4 view-lable-meet">Phone Number</label>
                <div className="col-lg-8">
                  <input
                    className="form-control m-input"
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                    value={this.state.PhoneNumber}
                    onChange={this.handleInputChange}
                    onBlur={this.checkPhoneNumberExists} // Check if phone number exists on blur
                  />
                  {this.state.errors.PhoneNumber && (
                    <span style={{ color: "red" }}>
                      {this.state.errors.PhoneNumber}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Redirect Phone Number Input */}
            <div className="col-lg-6 boxsinput_group">
              <div className="row max-cols">
                <label className="col-lg-4 view-lable-meet">
                  Redirect Phone Number
                </label>
                <div className="col-lg-8">
                  <input
                    className="form-control m-input"
                    name="RedirectPhoneNumber"
                    placeholder="Enter redirect phone number"
                    type="text"
                    value={this.state.RedirectPhoneNumber}
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors.RedirectPhoneNumber && (
                    <span style={{ color: "red" }}>
                      {this.state.errors.RedirectPhoneNumber}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="row mb-3">
          <div className="col-lg-12 pull-left">
            <button
              type="submit"
              className="btn btn-primary btn-lightgreen mr-1"
            >
              <i className="la la-save"></i> Save
            </button>
            <button
              type="button"
              onClick={this.BackBtn}
              className="btn btn-secondary"
            >
              <i className="la la-arrow-circle-left"></i> Back
            </button>
          </div>
        </div>
      </form>
          </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
};

const connectedAddTwilioPhoneNumber = connect(mapState, actionCreators)(AddTwilioPhoneNumber);
export { connectedAddTwilioPhoneNumber as AddTwilioPhoneNumber };