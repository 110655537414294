import React, { useEffect, useState } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
let CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import $ from "jquery";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
import Pagenations from "../../Pagenations";
import { history } from "../../../_helpers";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import StyleHeader from "../StickyHeader/StickyHeader";

const SortingIcons = ({ SortedBy, sortedColumn, ColumnName }) => {
  return (
    <span className="shorting">
      <ArrowUpward
        className={
          SortedBy === 1 && sortedColumn === ColumnName ? "active" : null
        }
      />
      <ArrowDownward
        className={
          SortedBy === -1 && sortedColumn === ColumnName ? "active" : null
        }
      />
    </span>
  );
};

export default function PhoneNumberTable() {
  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(100);
  const [Email, SetEmail] = useState("");
  const [InvitePopup, SetInvitePopup] = useState(false);
  const [RID, SetRID] = useState("");
  const [UserID, SetUserID] = useState(null);
  const [SortField, SetSortField] = React.useState("PhoneNumber");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [Alertbox, SetAlertbox] = useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("PhoneNumber");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  //import csv
  const [files, setFiles] = React.useState([]);
  const [reset, setReset] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [csvdata, setCsvdata] = React.useState([]);
  const [DropBoxData, SetDropBoxData] = React.useState([]);
  const [MapAttrBox, SetMapAttrBox] = React.useState(false);
  const [opencsv, setOpencsv] = React.useState(false);
  const [MapCsvs, SetMapcsvs] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [PhoneNumber, SetPhoneNumber] = React.useState(null);
  const [RedirectPhoneNumber, SetRedirectPhoneNumber] = React.useState(null);
  const [SampleExportData, SetSampleExportData] = React.useState([]);

  useEffect(() => {
    let GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetTwilioPhoneNumberList(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
    SampleFileExportdetails();
  }, [Search, Page, RowsPerPage]);

  //Getting Twilio Phone number list
  const GetTwilioPhoneNumberList = (CID, UID, URole) => {
    try {
      SetIsLoading(true);
      let RequestBody = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: URole,
      };
      const ApiRespnose = Axios({
        url: CommonConstants.MOL_APIURL + "/sdrassignment/GetTwilioPhoneNumber",
        method: "POST",
        data: RequestBody,
      });
      ApiRespnose.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
        setIsDataLoaded(true);
      });
    } catch (err) {}
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("Search").value?.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //get sort field data

  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("Search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AdminListAPI = Axios({
      url: CommonConstants.MOL_APIURL + "/sdrassignment/GetTwilioPhoneNumber",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    }).catch((error) => {
      SetIsLoading(false);
    });
  };

  const DeleteBtn = (ID, StatusCall) => {
    if (StatusCall === "Available") {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a twilio phone number.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((Result) => {
        if (Result.isConfirmed) {
          let data = {
            ID: ID,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/sdrassignment/DeleteTwilioPhoneNumber",
            method: "POST",
            data: data,
          }).then((Res) => {
            if (Res) {
              if ((Res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Twilio phone number deleted successfully.",
                  "success"
                );
                GetTwilioPhoneNumberList(ClientID, UserID, Role);
                SetPage(1);
                SetSearch("");
                document.getElementById("Search").value = "";
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Please remove this phone number from your current SDR Assignment.
        </div>
      );
    }
  };

  // Add Button
  const AddBtn = () => {
    history.push("/addtwiliophonenumber");
  };

  // Edit Button
  const EditBtn = (ID) => {
    history.push({
      pathname: "/edittwiliophonenumber",
      state: { data: ID, Pagename: "" },
    });
  };

  //import csv numbers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnError = (err) => {};

  const handleOnRemoveFile = (data) => {};

  const resetcsv = () => {
    setReset(true);
  };

  const handleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setFiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length - 1] !== "csv") {
      setReset(true);
      resetcsv();
    } else {
      var csvfile =
        sep.slice(0, -1).join(".") +
        new Date().getTime() +
        "." +
        sep[sep.length - 1];
      setFilename(csvfile);

      // Filter out rows with all empty strings
      const filteredData = data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );

      // Set the filtered data to csvdata
      setCsvdata(filteredData);
      SetDropBoxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  //import
  const importcsv = (e) => {
    e.preventDefault();
    setOpencsv(true);
  };

  // map csv
  const NumberMapCsv = () => {
    if (csvdata.length != 0) {
      DropBoxData.map((dddd, index) => {});
      SetMapAttrBox(false);
      document.getElementById("map_attribute").style.display = "block";
      setReset(true);
    } else {
      toast.error(
        <div className="toastsize">
          Phone Numbers
          <br />
          Please select file
        </div>
      );
      setReset(true);
      SetMapAttrBox(true);
    }
    hidesCSVHeaderOptions();
  };

  const hidesCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "PhoneNumber") {
      SetPhoneNumber(value);
    } else if (Name == "RedirectPhoneNumber") {
      SetRedirectPhoneNumber(value);
    }
    hidesCSVHeaderOptions();
  });

  //mapcsv
  const Savecsv = async () => {
    try {
      
      let OriginalFileName = files.name;
      let onlyname = OriginalFileName.substring(
        0,
        OriginalFileName.lastIndexOf(".csv")
      );

      let saveAllContacts = [];
      let PhoneNumber = parseInt(document.getElementById("PhoneNumber")?.value);
      if (PhoneNumber >= 0) {
        let ObjPhoneNumber = {
          FieldAlias: "Phone Number",
          Fieldname: "PhoneNumber",
          Customfield: null,
          index: PhoneNumber,
        };
        saveAllContacts.push(ObjPhoneNumber);
      }
      let RedirectPhoneNumber = parseInt(
        document.getElementById("RedirectPhoneNumber")?.value
      );
      if (RedirectPhoneNumber >= 0) {
        let ObjRedirectPhoneNumber = {
          FieldAlias: "Redirect Phone Number",
          Fieldname: "RedirectPhoneNumber",
          Customfield: null,
          index: RedirectPhoneNumber,
        };
        saveAllContacts.push(ObjRedirectPhoneNumber);
      }
      
      let PhoneNumbers = document.getElementById("PhoneNumber").value;

      if (PhoneNumbers == "") {
        toast.error(
          <div className="toastsize">
            Phone Numbers is required!
          </div>
        );
      } else {
        SetIsLoading(true);
        Fileupload.getBase64(files, (result) => {
          let rs = result;
          let splirs = rs.split("base64,");
          let _base64 = splirs[1];
          let myresult = {
            fields: "Coverimg",
            path: "./Content/UploadedFiles/UserPhoneNumbers",
            file: _base64,
            imageName: filename,
            FileData: csvdata,
            ClientID: ClientID,
            UserID: UserID,
            Role:Role,
            CreatedBy: CUserID,
            CreatedDate: new Date(),
            saveAllContacts: saveAllContacts,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/sdrassignment/ImportCSVPhoneNumbersData",
            method: "POST",
            data: myresult,
          }).then((Res) => {
            if (Res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">Phone Numbers added successfully.</div>
              );

              setOpencsv(true);
              SetMapAttrBox(true);
              document.getElementById("map_attribute").style.display = "block";
              SetTotalResult(Res.data.Total);
              SetTotalDuplicate(Res.data.TotalDuplication);
              SetTotalError(Res.data.TotalError);
              SetTotalSuccess(Res.data.TotalSuccess);
              SetTotalDisplayCount(true);
              setCsvdata([]);
              SetIsLoading(false);
            } else {
              toast.error(Res.data.Message);
              setOpencsv(true);
              SetMapAttrBox(true);
              document.getElementById("map_attribute").style.display = "block";
              SetTotalResult(Res.data.Total);
              SetTotalDuplicate(Res.data.TotalDuplication);
              SetTotalError(Res.data.TotalError);
              SetTotalSuccess(Res.data.TotalSuccess);

              setCsvdata([]);
              SetIsLoading(false);
            }
          });
        });
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const canclecsv = () => {
    resetcsv();
    setCsvdata([]);
    SetMapAttrBox(false);
    document.getElementById("map_attribute").style.display = "none";
    SetTotalDisplayCount(false);
    setOpencsv(false);
  };

  //sample
  const SampleFileExportdetails = () => {
    const ParamsObject ={
      Name: "PhoneNumberSdr",
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/sdrassignment/GetSamplePhoneNumberExport",
      method: "POST",
      data: ParamsObject,
    }).then((res) => {
      SetSampleExportData(res?.data);
    });
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3">
          <div className="fulleriger d-flex align-items-center">
            <button
              onClick={AddBtn}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span>
                <i class="la la-icon-font-size-13 la-plus"></i>
                <span>Add</span>
              </span>
            </button>
          </div>
          {/* <div className="fulleriger d-flex align-items-center mx-1">
            <button
              onClick={importcsv}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span>
              <i className="la la-icon-font-size-13 la-upload"></i>
                <span>Import</span>
              </span>
            </button>
          </div> */}
        </div>
      </div>
      {/* import csv */}
      {opencsv ? (
        <div
          className="border-bottom portletbody px-0 border border-bottom-0 mt-0"
          id="import_csv"
        >
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <h3 className="uploadheadingtitle">
                Import Phone Numbers Details
              </h3>
              <span class="m-alert alert-paragraph">
                <i class="la la-info-circle"></i> A sample file is available for
                download,
                <CSVDownloader
                 data={SampleExportData}
                  filename={"Sample"}
                  bom={true}
                >
                  <a> please click here</a>
                </CSVDownloader>
              </span>
              <div className="uplodfilesbox">
                <CSVReader
                  accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  removeButtonColor="#659cef"
                  onRemoveFile={handleOnRemoveFile}
                  isReset={reset}
                >
                  <span>Select .CSV file</span>
                  <p>Add phone numbers data from csv file to bulk upload.</p>
                </CSVReader>
              </div>
            </div>
            <div className="col-xl-8 offset-xl-2 pt-3">
              <div className="alignright">
                <a onClick={NumberMapCsv} className="btn btn-primary btnColor">
                  <i className="la la-upload"></i> Upload
                </a>
                <a onClick={canclecsv} className="btn btn-secondary">
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className="portletbody p-0 my-0 bg-white"
        style={{ display: "none" }}
        id="map_attribute"
      >
        <div className="row col-xs-12 border-bottom mx-0 mb-3 py-2">
          <h4 className="headertitle xs-headertitle float-left pl-0 mb-0">
            Map attributes
          </h4>
        </div>
        <div className="row mx-0" id="divContactSelection">
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="PhoneNumber">
                    Phone Number
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="PhoneNumber"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    PhoneNumber == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "phonenumber" ||
                            item.toLowerCase() === "phone number"
                        )
                      : PhoneNumber
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 form-group m-form__group row mx-0">
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="RedirectPhoneNumber">
                    Redirect Phone Number
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-5 nopadding">
              <div>
                <select
                  disabled={MapAttrBox}
                  id="RedirectPhoneNumber"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    RedirectPhoneNumber == null
                      ? DropBoxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "redirectphonenumber" ||
                            item.toLowerCase() === "redirect phone number"
                        )
                      : RedirectPhoneNumber
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropBoxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-12 py-3 px-3 border-top">
            <button
              onClick={Savecsv}
              disabled={MapAttrBox}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>

      {TotalDisplayCount ? (
        <div
          className="portletbody my-0 bg-white border-bottom-0 px-2 pt-4 pb-3"
          id=" "
        >
          <div className="row col-xs-12 mx-0">
            <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">
              Results
            </h4>
          </div>

          <div className="col-xs-12 mx-0">
            <div className="row colfive mx-0">
              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-primary">
                          {TotalResult}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-success">
                          {TotalSuccess}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total success</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-danger">
                          {TotalError}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total error</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="boxcardcounter">
                  <div className="d-flex align-center">
                    <div className="contectboxes w-100">
                      <div className="line-title mb-2 text-center d-block">
                        <span className="box-main-title font-warning">
                          {TotalDuplicate}
                        </span>
                      </div>
                      <div className="xs-headertitle box-inner-title">
                        <string className="clr-sky">Total Duplicate</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* import csv */}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => {
                    SortData("PhoneNumber");
                    setSortedColumn("PhoneNumber");
                  }}
                >
                  Phone Numbers
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"PhoneNumber"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Status");
                    setSortedColumn("Status");
                  }}
                >
                  Status
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"Status"}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("RedirectNumber");
                    setSortedColumn("RedirectNumber");
                  }}
                >
                  Redirect Mobile
                  <SortingIcons
                    SortedBy={SortedBy}
                    sortedColumn={sortedColumn}
                    ColumnName={"RedirectNumber"}
                  />
                </TableCell>
                <TableCell>Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.PhoneNumber}</TableCell>
                    <TableCell>{row?.Status}</TableCell>
                    <TableCell>{row?.RedirectNumber}</TableCell>
                    <TableCell>
                      <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row?._id, row?.Status);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
        Sflag={Sflag}
        CountPage={CountPage}
        HandleChangePage={HandleChangePage}
        Rlen={Rlen}
        Page={Page}
        RowsPerPage={RowsPerPage}
        Flen={Flen}
      />
    </div>
  );
}
