import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditTwilioPhoneNumber extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        ClientID: null,
        UserID: null,
        CUserID: null,
        Role: null,
        PhoneNumber: "",
        OriginalPhoneNumber: "", // Store original phone number
        RedirectPhoneNumber: "",
        errors: {},
        isPhoneNumberExists: false,
        IsButtonDisabled: false,
        IsLoading: true,
        ID: null,
        IsTwilioPhoneValidation:false

      };
    }
  
    componentDidMount() {
      document.title = "Edit Twilio Phone Number | SalesHive";
      const Details = GetUserDetails();
      if (Details) {
        this.setState({
          ClientID: Details.ClientID,
          UserID: Details.ParentUserID,
          CUserID: Details.ChildUserID,
          Role: Details.Role,
        });
      }
      this.GettingTwilioPhoneNumber();
    }
  
    // Get Twilio phone number details
    GettingTwilioPhoneNumber = async () => {
      try {
        const id = this.props.location.state?.data;
        this.setState({ ID: id });
        const response = await Axios.post(
          `${CommonConstants.MOL_APIURL}/sdrassignment/GetTwilioPhoneNumberById`,
          { ID: id, Role: this.state.Role }
        );
        if (response.data?.StatusMessage === "SUCCESS") {
          const { PhoneNumber, RedirectNumber } = response.data.Data;
          this.setState({
            PhoneNumber,
            OriginalPhoneNumber: PhoneNumber, // Store original phone number
            RedirectPhoneNumber: RedirectNumber,
            IsLoading:false
          });
        } else {
          this.setState({ PhoneNumber: null, RedirectPhoneNumber: null,IsLoading:false });
        }
      } catch (err) {
        console.error(err);
        this.setState({ PhoneNumber: null, RedirectPhoneNumber: null,IsLoading:false });
      }
    };
  
    validate = () => {
      const { PhoneNumber, RedirectPhoneNumber } = this.state;
      let tempErrors = {...this.state.errors};
      let isValid = true;
  
      // Validate phone number
      if (!PhoneNumber) {
        tempErrors["PhoneNumber"] = "Please enter a phone number.";
        isValid = false;
      } else if (!/^\+?[0-9]+$/.test(PhoneNumber)) {
        tempErrors["PhoneNumber"] = "Please enter only numeric values.";
        isValid = false;
      }
  
      // Validate redirect phone number
      if(this.state.RedirectPhoneNumber?.trim() != ""){
        if (!/^\+?[0-9]+$/.test(RedirectPhoneNumber)) {
          tempErrors["RedirectPhoneNumber"] = "Please enter only numeric values.";
          isValid = false;
        }
      }
     
  
      this.setState({ errors: tempErrors });
      return isValid;
    };
  
    // Check if phone number exists
    checkPhoneNumberExists = async () => {
      const {PhoneNumber,OriginalPhoneNumber, UserID, Role } = this.state;
      if (PhoneNumber === OriginalPhoneNumber) {
        // Skip check if the phone number hasn't changed
        this.setState({ isPhoneNumberExists: false ,IsTwilioPhoneValidation: true});
        return;
      }
  
      try {
           // Clear validation error for PhoneNumber or RedirectPhoneNumber
            if (PhoneNumber?.trim() === "") {
              this.setState((prevState) => ({
                errors: { ...prevState.errors,PhoneNumber : "Please enter phone number." },
              }));
            } else if (!/^\+?[0-9]+$/.test(PhoneNumber)) {
              this.setState((prevState) => ({
                errors: { ...prevState.errors,PhoneNumber : "Please enter a valid phone number." },
              }));
            } else {
              const response = await Axios.post(
                `${CommonConstants.MOL_APIURL}/sdrassignment/CheckTwilioPhoneExists`,
                { PhoneNumber:PhoneNumber, UserID, Role }
              );
        
              if (response.data?.Data?.IsExists) {
                this.setState((prevState) => ({
                  isPhoneNumberExists: true,
                  errors: { ...prevState.errors, PhoneNumber: "Phone number already exists." },
                }));
              } else {
                this.setState({ isPhoneNumberExists: false });
              await this.twilioPhoneNumberValidation(this.state.PhoneNumber,this.state.Role)

              }
            }
          
      } catch (error) {
        console.error("Error checking phone number:", error);
        toast.error("Failed to check phone number.");
      }
    };
  
        //Checking Twilio phone number validation
        twilioPhoneNumberValidation = async (PhoneNumber,Role) => {
          try{
            this.setState({IsLoading:true})
            const response = await Axios.post(CommonConstants.MOL_APIURL +  "/sdrassignment/VerifyTwilioPhoneNumberValid", {
              PhoneNumber: PhoneNumber,
              Role:Role
          });
          if (response.data?.Data?.IsValid == false) {
              this.setState((prevState) => ({
                  IsTwilioPhoneValidation: false,
                  IsLoading:false,
                  errors: { ...prevState.errors, PhoneNumber: "Phone number is not valid." },
                }));
    
          } else {
              this.setState((prevState) => ({
                IsTwilioPhoneValidation: true,
                IsLoading:false,
                errors: { ...prevState.errors, PhoneNumber: "" },
              }));
    
          }
          }catch(err){
            console.log(err)
            this.setState({IsLoading:false})
    
          }
        }
    // Handle input change
    handleInputChange = (e) => {
      const { name, value } = e.target;
      let errors = { ...this.state.errors };
  
      // Clear validation error if valid input is provided
      if (name === "PhoneNumber") {
        if(value?.trim() == ""){
          errors.PhoneNumber = "Please enter phone number.";
        }else if (!/^\+?[0-9]+$/.test(value)) {
            errors.PhoneNumber = "Please enter only numeric values.";
        } else {
            delete errors.PhoneNumber;
        }
      }
      if (name === "RedirectPhoneNumber") {
        if (value?.trim() != "") {
          if (!/^\+?[0-9]+$/.test(value)) {
            errors.RedirectPhoneNumber = "Please enter only numeric values.";
          } else {
            delete errors.RedirectPhoneNumber;
          }
        }
      }
  
      this.setState({ [name]: value, errors });
    };
  
    // Handle form submission
    handleSubmit = async (e) => {
      e.preventDefault();
      if (this.validate()) {
        await this.checkPhoneNumberExists();
  
        if (!this.state.isPhoneNumberExists && this.state.IsTwilioPhoneValidation) {
          const { UserID, CUserID, PhoneNumber, Role, RedirectPhoneNumber, ID } = this.state;
          const RequestBody = {
            UserID,
            LastUpdatedBy: CUserID,
            PhoneNumber,
            Role,
            RedirectNumber: RedirectPhoneNumber,
            LastUpdatedDate: new Date(),
            ID,
          };
  
          try {
            const response = await Axios.post(
              `${CommonConstants.MOL_APIURL}/sdrassignment/UpdateTwilioPhoneNumber`,
              RequestBody
            );
  
            if (response.data?.Data?.IsUpdated) {
              history.push("/sdrassignments",{tab:1});
              toast.success("Twilio phone number updated successfully!");
            } else {
              toast.error("Failed to update Twilio phone number.");
            }
          } catch (error) {
            console.error("Error updating phone number:", error);
            toast.error("Failed to update phone number.");
          }
        }
      }
    };
  
    // Back button functionality
    BackBtn = () => {
      history.push("/sdrassignments", { tab: 1 });
    };
  
    render() {
      const { PhoneNumber, RedirectPhoneNumber, errors } = this.state;
  
      return (
        <>
        {this.state.IsLoading === true ? (
                <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
                </div>
                ) : (
                    <></>
                )}
          <div className="adminmain mheight">
            <Sidebar />
            <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Edit Twilio Phone Number
                  </h4>
                </div>
              </div>
  
            
                <div className="bg-white p-3 mb-3">
                  <div className="row py-2">
                    {/* Phone Number Input */}
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">Phone Number</label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            name="PhoneNumber"
                            placeholder="Enter phone number"
                            type="text"
                            value={PhoneNumber}
                            onChange={this.handleInputChange}
                            onBlur={this.checkPhoneNumberExists}
                          />
                          {errors.PhoneNumber && (
                            <span style={{ color: "red" }}>{errors.PhoneNumber}</span>
                          )}
                        </div>
                      </div>
                    </div>
  
                    {/* Redirect Phone Number Input */}
                    <div className="col-lg-6 boxsinput_group">
                      <div className="row max-cols">
                        <label className="col-lg-4 view-lable-meet">Redirect Phone Number</label>
                        <div className="col-lg-8">
                          <input
                            className="form-control m-input"
                            name="RedirectPhoneNumber"
                            placeholder="Enter redirect phone number"
                            type="text"
                            value={RedirectPhoneNumber}
                            onChange={this.handleInputChange}
                          />
                          {errors.RedirectPhoneNumber && (
                            <span style={{ color: "red" }}>{errors.RedirectPhoneNumber}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* Submit Button */}
                <div className="row mb-3">
                  <div className="col-lg-12 pull-left">
                    <button type="submit" onClick={this.handleSubmit} className="btn btn-primary btn-lightgreen mr-1">
                      <i className="la la-save"></i> Save
                    </button>
                    <button type="button" onClick={this.BackBtn} className="btn btn-secondary">
                      <i className="la la-arrow-circle-left"></i> Back
                    </button>
                  </div>
                </div>
             
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }
  

  

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
};

const connectedEditTwilioPhoneNumber = connect(mapState, actionCreators)(EditTwilioPhoneNumber);
export { connectedEditTwilioPhoneNumber as EditTwilioPhoneNumber };