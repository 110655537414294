import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Axios from "axios";
const moment = require("moment");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";

import Contacts from "./Contacts";
import Notes from "./Notes";
import PotentialMeeting from "./PotentialMeeting";
import Meeting from "./Meeting";
import Campaigns from "./Campaigns";
import List from "./List";
import AccountStatusHistory from "./AccountStatusHistory";
import CallHistory from "./CallHistory";
import ViewAccountStats from "./ViewAccountStats";

class ViewAccountPage extends React.Component {
  constructor(props) {
    let Details = GetUserDetails();
    super(props);

    this.state = {

      // New State 
      RenderAccountStats:0,
      GetAccountStats:{},
      EmailDomain:"",
      CommonID: this.props.location?.state?.data || new URLSearchParams(location.search).get('id') || null,
      AccountPageName: this.props.location?.state?.Pagename || this.props.location?.state?.PageName || new URLSearchParams(location.search).get('pagename') || null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      ChildUserID: Details.ChildUserID,
      Role: Details.Role,
      allres: [],
      GetResponse:[],
      AccountIDs: "",
      ContactDomain: "",
      AccountData: [],
      ID: "",
      AddBlackListID: "",
      SalesAccountID: "",
      AccountCustomField: [],
      AccountCustomfieldbyid: [],
      parentValueLastStatusChageValue:"",
    };


    this.GettingBasicAccountDetails(this.props.location?.state?.data || new URLSearchParams(location.search).get('id'),Details.Role);
    this.GettingAccountCustomFieldsDetails(Details.ParentUserID,Details.ClientID,Details.Role);
    this.GetAccountCustomfieldbyid(this.props.location?.state?.data || new URLSearchParams(location.search).get('id'),Details.Role);
  }

  componentDidMount() {
    document.title = `Edit Account | SalesHive`;
    var Details = GetUserDetails();
    const id = this.props.location?.state?.data || new URLSearchParams(location.search).get('id');
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.ChildUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
  }


  // Getting Account detailes
  GettingBasicAccountDetails(id,Role) {
    try{
      this.setState({ id: id });
      let InputParameters = {
        Role: Role,
        _id: id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/SalesReplyByID",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        if(res.data.StatusMessage === 'SUCCESS'){
          this.setState({ allres: [res.data.Data], GetResponse: res.data.Data});
          this.setState({ AccountData: [res.data.Data] });
          this.setState({ ContactDomain: res.data.Data[0]?.Salesreply_Accountdetails?.Domain});
          this.setState({ AddBlackListID: res.data.Data[0]?.Salesreply_Accountdetails?.Domain});
          this.setState({ SalesAccountID: res.data.Data[0]?.Salesreply_Accountdetails?.AccountID});
    
          //notes
          this.setState({ AccountIDs: res.data.Data[0]?._id });
        }else{
          toast.error(res.data.Message)
        }
        
  
        
        document.getElementById("hideloding").style.display = "none";
      });
    }catch(err){
      toast.error("Something went worng !")
      document.getElementById("hideloding").style.display = "none";
    }
  }

   // Getting Account custom fields details (For Column Name)
   GettingAccountCustomFieldsDetails(UserID,ClientID,Role) {
    let InputParameter = {
      ClientID: ClientID,
      Role: Role,
      UserID: UserID,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetCustomFieldsDetailsForView",
      method: "POST",
      data: InputParameter,
    });
    rows.then((result) => {
      if(result.data.StatusMessage === "SUCCESS"){
        this.setState({AccountCustomField: result.data.AccountCustomField});
      }else{
        toast.error(result.data.Message)
      }
     
    });
  }

  // Getting Account custom fields details (For Column value)
  GetAccountCustomfieldbyid(id,Role) {
    let InputParameters = {
      Role: Role,
      SalesReplyAccountID: id
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountCustomFieldId",
      method: "POST",
      data: InputParameters,
    }).then((resmeeting) => {
      if(resmeeting.data.StatusMessage === "SUCCESS"){
        this.setState({
          AccountCustomfieldbyid: resmeeting.data?.Data,
        });
      }else{
        toast.error(resmeeting.data.Message)
      }
      
    });
  }

  //edit page
  editbtn = () => {
    
    if (this.state.AccountPageName == "ClientAccount") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "ClientAccount" },
      });
    } else if (this.state.AccountPageName == "Potential") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "Potential" },
      });
    } else if (this.state.AccountPageName == "PotentialLinkedin") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "PotentialLinkedin" },
      });
    }else if (this.state.AccountPageName == "BookedMeeting") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "BookedMeeting" },
      });
    }else if (this.state.AccountPageName === "kpiMeeting") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "kpiMeeting" },
      });
    }else if (this.state.AccountPageName == "RescheduleMeeting") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "RescheduleMeeting" },
      });
    }else if (this.state.AccountPageName == "OpportunitiesMeeting") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "OpportunitiesMeeting" },
      });
    }else if (this.state.AccountPageName == "ClientRescheduleMeeting") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "ClientRescheduleMeeting" },
      });
    }else if (this.state.AccountPageName == "accounts") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "accounts" },
      })}else if (this.state.AccountPageName == "ViewContact") {
        history.push({
          pathname: "/editaccount",
          state: {
            data: this.state.AccountIDs,
            dataprospect: this.props.location.state?.dataprospect,
            EmailData: this.props.location.state?.EmailData,
            PageName: "ViewContact"
          },
        })}else if (this.state.AccountPageName == "MainContactPage") {
          history.push({
            pathname: "/editaccount",
            state: { data: this.state.AccountIDs, PageName: "MainContactPage" },
          })}
     else {
      history.push({
        pathname: "/editaccount",
        state: { data: this.state.AccountIDs, Pagename: "" },
      });
    }
  };

  //back to page
  backbtn = () => {
    if (this.state.AccountPageName == "ClientAccount") {
      history.push("/clientaccount");
    } else if (this.state.AccountPageName == "Potential") {
      history.push("/potentialmeeting");
    } else if (this.state.AccountPageName == "PotentialLinkedin") {
      history.push("/linkedinfollowup");
    }  else if (this.state.AccountPageName == "ClientRescheduleMeeting") {
      history.push("/clientrescheduling");
    } else if (this.state.AccountPageName == "BookedMeeting") {
      history.push("/bookedmeetings");
    }
    else if (this.state.AccountPageName == "RescheduleMeeting") {
      history.push("/reschedules");
    }else if (this.state.AccountPageName=== "kpiMeeting") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 2,
        },
      });
    }
    else if (this.state.AccountPageName == "OpportunitiesMeeting") {
      history.push("/opportunities");
    }else if (this.state.AccountPageName == "ViewContact") {
      history.push({
        pathname: "/viewcontacts",
        state: {
          data: this.props.location.state?.dataprospect,
          EmailData: this.props.location.state?.EmailData,
        },
      })}else if (this.state.AccountPageName == "MainContactPage") {
        history.push({
          pathname: "/contacts",
          
        })}
    else {
      history.push("/accounts");
    }
  }

  handleValueChangeStatusChangesDate = (value) => {
    this.setState({parentValueLastStatusChageValue:value})
  }

  //delete account
  DeleteClientAccount = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          _id: this.state.AccountIDs,
          LastUpdatedDate: new Date(),
          Role: this.state.Role,
          ClientID:this.state.ClientID,
          LastUpdatedBy: this.state.ChildUserID,
          UserID:this.state.UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteAccountList",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage === "SUCCESS") {
           
              Swal.fire("Deleted!", "Delete Account successfully.", "success");
              history.push("/accounts");
          }else{
            toast.error(res.data.Message)
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //black list
  AddToBlankAdd = () => {
    document.getElementById("hideloding").style.display = "block";
    let InputParameters = {
      DomainName: this.state.ContactDomain,
      Role: this.state.Role,
      ClientID: this.state.ClientID,
      AccountID: this.state.CommonID,
      UserID: this.state.UserID,
      CreatedBy: this.state.ChildUserID
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountToBlackList",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        if (res.data.BlackListlength === null) {
            // Handle the case where BlackListlength is null
            toast.error("Domain Not Exists");
            document.getElementById("hideloding").style.display = "none";
        } else if (res.data.BlackListlength.length === 0) {
          this.setState({RenderAccountStats:this.state.RenderAccountStats+1})
          toast.success("Add BlackList successfully blackListed!");
          document.getElementById("hideloding").style.display = "none";
        } else {
            toast.error("Accounts already blacklisted!");
            document.getElementById("hideloding").style.display = "none";
        }
    } else {
        toast.error("Error while blacklisting accounts.");
        document.getElementById("hideloding").style.display = "none";
    }
    });
  };

  // custom field data list
  CustomFieldValueGet() {
    const id = this.props.location?.state?.data || new URLSearchParams(location.search).get('id')
    this.setState({ id: id });
    // prospect custom field id
    let CustomFiedIdData = {
      Role: this.state.Role,
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/SalesreplyStatusNotesByID",
      method: "POST",
      data: CustomFiedIdData,
    }).then((res) => {
      this.setState({
        ProspectCustomFieldById: res.data.ProspectCustomFieldById,
      });
    });
  }

//Date formatter 
  renderDate=(date) =>{
    return date ? moment(date).format("MM/DD/YYYY") : "";
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain"> 
            <Sidebar className="" />
          <div className="bodyhome">
            <div className="mb-3"> 
              <div className="row">
                <div className="col-md-4 col-sm-4">
                  <h3 className="lg-headertitle py-4 mb-0">
                    Edit Account
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 text-right pt-2">
                  <div className="listing-li float-right padb-15 iconbox-pad edited">
                    <ul>
                      <li>
                        <a className="btn btngroup m-btn" onClick={this.editbtn}>
                          <i className="la flaticon-edit-1 la-icon-font-size-13"></i><span>Edit</span></a>
                      </li>
                      <li>
                        <a onClick={this.DeleteClientAccount} className="btn btngroup m-btn">
                          <i className="flaticon-delete-1 la-icon-font-size-13"></i><span>Delete</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={this.AddToBlankAdd} className="btn btngroup m-btn">
                          <i className="fa la-icon-font-size-13 fa-ban la-icon-font-size-13"></i><span>Add to Blacklist</span></a>
                      </li>
                      <li>
                        <a onClick={this.backbtn} className="btn btngroup m-btn borderbtn">
                          <i className="la la-arrow-circle-left la-icon-font-size-13"></i> <span>Back</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> 
              <div className="bg-white px-3"> 
                {this.state?.allres[0]?.map((value) => (
                  <div className="row px-2">
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Name
                        </label>
                        <label className="labeltext">{value?.AccountName}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2"></div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols  d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Name Long
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountNameLong}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Email Domains
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Salesreply_Accountdetails?.Domain}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Website
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Website}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Owner
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.MeetingOwnerData[0]?.Name}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Category
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountCategoryData[0]?.Name}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountStatusData[0]?.Status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Industry :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Industry}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Address 1 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Address1}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Address 2 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Address2}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          City :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.City}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          State :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.State}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Country :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Country}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Zip :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Zip}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Reason Unqualified :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountReasonUnqualifiedData[0]?.Reason}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Type :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CommonFieldData[0]?.FieldName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Client Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountClientStatusData[0]?.Name}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CompanyPhone}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Revenue :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CompanyRevenue == null || "" || undefined ? 0 : value?.CompanyRevenue}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Employee Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CompanyEmployeeCount == null || "" || undefined ? 0 : value?.CompanyEmployeeCount}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
     
                  {
                    this.state.AccountCustomField?.length != 0 ? <><div className="row ">
                    <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                      <h3 className=" float-left addpotential_header">
                        Custom  Fields
                      </h3>
                    </div>
                    </div>

                  <div className="row ">
                      {this.state.AccountCustomField.map((value, index) => {
                        return (
                          <div className="col-lg-6 boxsinput_group">
                            <div
                              className="row max-cols d-flex align-items-center"
                              key={index}
                            >
                              <label className="col-lg-4">{value.FieldName}:</label>
                              {this.state.AccountCustomfieldbyid?.map((val) => {
                                if (val.CustomFieldID === value._id) {
                                  return (
                                    <div className="col-lg-7" key={value?._id}>
                                      <label>{val.FieldValue}</label>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          );
                        })}
                    </div> </>:<></>
                  }
            

            </div>
            <div className=" mt-3">
                {/* Account Stats */}
                {
                  (this.state.Role && this.state.AccountIDs && this.state.UserID && this.state.ClientID && this.state.ContactDomain && this.state.GetResponse[0]?.CreatedDate) && <ViewAccountStats
                  Role={this.state.Role}
                  SalesReplyAccountID={this.state.AccountIDs}
                  UserID={this.state.UserID}
                  ClientID={this.state.ClientID}
                  EmailDomain={this.state.ContactDomain}
                  CreatedDate={this.state.GetResponse[0]?.CreatedDate}
                  key={this.state.RenderAccountStats}
                />
                }
              </div>
                
          {/* <div className="bg-white px-3 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Account Stats
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Created Date :
                  </label>
                  <div className="col-lg-7">
                    
                    <label className="col-lg-4 view-lable-meet potential_text">
                     <b>{this.renderDate(this.state.GetResponse[0]?.CreatedDate)}</b> 
                   
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Validation Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.renderDate(this.state.GetAccountStats?.LastValidationDate)}</b> </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Email Send Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">
                    <b>{this.renderDate(this.state.GetAccountStats?.LastEmailSendDate)}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Open Date :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">
                    <b>{this.renderDate(this.state.GetAccountStats?.LastOpenDate)}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Response Date
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">
                    <b>{this.renderDate(this.state.GetAccountStats?.LastResponsesDate)}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Direct Mail Date
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">
                      <b>{this.renderDate(this.state.GetAccountStats?.LastResponsesDate)}</b>
                      </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Letter Date
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Last Status Change
                  </label>
                  <label className="col-lg-4 view-lable-meet potential_text">
                      <b>{this.renderDate(this.state.parentValueLastStatusChageValue)}</b>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Is Blacklisted :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.IsBlackListed}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Total Email Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalEmailSent == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalEmailSent}</b> </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Total Touches :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalTouches == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalTouches}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Tracked Email Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalTrackedEmail == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalTrackedEmail}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Open Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.OpenedCount == null || undefined || "" ? 0 : this.state.GetAccountStats?.OpenedCount}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Reply Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalReply == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalReply}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Touches Before First Reply :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TouchesBeforeFirstReply == null || undefined || "" ? 0 : this.state.GetAccountStats?.TouchesBeforeFirstReply}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Removed Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Soft Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalSoftBounce == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalSoftBounce}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Hard Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{this.state.GetAccountStats?.TotalHardBounce == null || undefined || "" ? 0 : this.state.GetAccountStats?.TotalHardBounce}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Letter Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Direct Mail Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    View Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
            </div>
          </div>  */}

        {/* Contact */} 
          <div className="bg-white px-3 pb-3 mt-3"> 
                  <Contacts
                    ClientID={this.state.ClientID}
                    UserID={this.state.UserID}
                    Role={this.state.Role}
                    AccountID={this.state.AccountIDs}
                    ContactDomain={this.state.ContactDomain}
                  />
          </div> 

        {/* notes */} 
          <div className="bg-white px-3 pb-3 mt-3">

            <Notes
                UserID={this.state.UserID}
                ClientID={this.state.ClientID}
                Role={this.state.Role}
                AccountID={this.state.AccountIDs}
                Domain={this.state.ContactDomain}
                ChildUserID={this.state.ChildUserID}
            />
          </div> 

        {/*Potential meeting*/} 
          <div className="bg-white px-3 pb-3 mt-3">
            <PotentialMeeting
                UserID={this.state.UserID}
                ClientID={this.state.ClientID}
                Role={this.state.Role}
                AccountID={this.state.AccountIDs}
                Domain={this.state.ContactDomain}
            />
          </div> 

        {/* meeting*/} 
          <div className="bg-white px-3 pb-3 mt-3">
            <Meeting 
               UserID={this.state.UserID}
               ClientID={this.state.ClientID}
               Role={this.state.Role}
               AccountID={this.state.AccountIDs}
               Domain={this.state.ContactDomain}
            />
          </div> 

        {/* Campaigns */}  
          <div className="bg-white px-3 pb-3 mt-3">
            <Campaigns 
             UserID={this.state.UserID}
             ClientID={this.state.ClientID}
             Role={this.state.Role}
             AccountID={this.state.AccountIDs}
             Domain={this.state.ContactDomain}
            />
          </div> 

        {/* Lists */} 
          <div className="bg-white px-3 pb-3 mt-3">
            <List
               UserID={this.state.UserID}
               ClientID={this.state.ClientID}
               Role={this.state.Role}
               AccountID={this.state.AccountIDs}
               Domain={this.state.ContactDomain}
            />
          </div> 

        {/* Account status history */} 
          <div className="bg-white px-3 pb-3 mt-3">
            <AccountStatusHistory 
               UserID={this.state.UserID}
               ClientID={this.state.ClientID}
               Role={this.state.Role}
               AccountID={this.state.AccountIDs}
               Domain={this.state.ContactDomain}
               onValueChange={this.handleValueChangeStatusChangesDate}
            />
            
          </div> 

        {/* call Histroy */} 
          <div className="bg-white px-3 pb-3 mt-3">
            <CallHistory
               UserID={this.state.UserID}
               ClientID={this.state.ClientID}
               Role={this.state.Role}
               AccountID={this.state.AccountIDs}
               Domain={this.state.ContactDomain}
            />
          </div>  
        </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedViewAccountPage = connect(
  mapState,
  actionCreators
)(ViewAccountPage);
export { connectedViewAccountPage as ViewAccountPage };
