import React, { useEffect } from "react";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { history } from "../../../_helpers";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";
toast.configure();

export default function PotentialMeetingstable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [MeetingExportData, SetMeetingExportData] = React.useState([]);
  const [MeetingStatus, setMeetingStatus] = React.useState([]);
  const [CName, SetCName] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    MeetingGet(Details.ClientID, Details.Role);
    ExportMeeting(Details.ClientID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const MeetingGet = (CID, Role) => {
    SetIsLoading(true)
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: true,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        SetRows(Res.data.PageData);
        SetRlen(Res.data.TotalCount);
        SetFlen(Res.data.TotalCount);
        SetCountPage(Res.data.PageCount);
        setMeetingStatus(Res.data.MeetingStatus);
        SetIsLoading(false)
      }else{
        SetIsLoading(false)
      }
    });
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs,EmailID) => {
    SetIsLoading(true);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      Role: Role,
      MeetingStatusID: event.target.value,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      var data = {
        // _id: id,
        EventID: EventID,
        ClientID: ClientID,
        UserID: UserID,
        MeetingStatusID: res.data.BookedStatus[0]?._id,
        Role: Role,
        OldMeetingStatusID: StatusIDs,
        Status: res.data.BookedStatus[0]?.Status,
        StatusName: document.getElementById(`MeetingStatusID${EventID}`)
        .options[
        document.getElementById(`MeetingStatusID${EventID}`).selectedIndex
      ].textContent,
      Email: EmailID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/UpdateBookedMeetingStatusHistory",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          MeetingGet(ClientID, Role);
          ExportMeeting(ClientID, Role);
          toast.success(
            "Booked meeting select option Status update successfully.",
            "Update Booked select option  meeting Status"
          );
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false);
        }
      });
    });
  };

  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Search: Search,
      Type: "User",
      ClientID: ClientID,
      Role: Role,
      IsPagingRequired: true,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeeting",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Res) => {
      SetRows(Res.data.PageData);
      SetRlen(Res.data.TotalCount);
      SetFlen(Res.data.TotalCount);
      SetCountPage(Res.data.PageCount);
      setMeetingStatus(Res.data.MeetingStatus);
      SetIsLoading(false)
    }).catch((error) => {
      SetIsLoading(false);
    });
  };

  const ExportMeeting = (CID, Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: '',
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: false,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        var ExportData = [];
        if (Res.data.PageData.length > 0) {
          for (var i = 0; i < Res.data.PageData.length; i++) {
            var objCampaign = Res.data.PageData[i];
            var data = {};
            data["Meeting Status"] = objCampaign?.Status || "";
            data["Company"] = objCampaign.Company || "";
            data["Title"] = objCampaign.JobTitle || "";
            data["Contact Name"] =
              objCampaign?.Firstname + " " + objCampaign?.Lastname || "";
            data["Email"] = objCampaign.Email || "";
            data["Domain"] = objCampaign.Domain || "";
            data["Meeting Source"] = objCampaign?.MSR?.Name || "";
            data["Owner"] =
              objCampaign.MO != null && objCampaign.MO != ""
                ? objCampaign?.MO?.Name
                : "";
            data["Meeting Date"] = objCampaign.EventStartDt || "";
            data["Meeting Set Date"] = objCampaign.EventCreatedDt || "";
            data["Send Reminder"] =
              objCampaign?.IsSendMeetingReminder == true ? "Yes" : "No";
            data["LinkedIn Url"] = objCampaign.LinkedInUrl || "";
            data["Contract Value"] = objCampaign?.MS?.ContractValue || "";
            data["Industry"] = objCampaign.Industry || "";
            data["Contact Source"] = objCampaign.ContactSource || "";
            ExportData.push(data);
          }
          SetMeetingExportData(ExportData);
        } else {
          var data = {};
          data["Meeting Status"] = "";
          data["Company"] = "";
          data["Title"] = "";
          data["Contact Name"] = "";
          data["Email"] = "";
          data["Domain"] = "";
          data["Meeting Source"] = "";
          data["Owner"] = "";
          data["Meeting Set Date"] = "";
          data["Meeting Date"] = "";
          data["Send Reminder"] = "";
          data["LinkedIn Url"] = "";
          data["Contract Value"] = "";
          data["Industry"] = "";
          data["Contact Source"] = "";
          ExportData.push(data);
          SetMeetingExportData(ExportData);
        }
      }
    });
  };
  const ExportCSV = () => {
    toast.success(
      <div className="toastsize">
        Kpi <br />
        Data exported successfully.
      </div>
    );
  };
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };


  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
    history.push({
      pathname: "/viewcontacts",
      state: { data: id, EmailData: Email, Pagename: "kpiMeeting7days" },
    });
  }
  };
  return (
    <>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row mxl-30">
        <div className="col">
          <h4 className="headertitle xs-headertitle float-left padx-0">Meetings</h4>

          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <CSVDownloader
                  data={MeetingExportData}
                  filename={CName + "-Meeting"}
                  bom={true}
                >
                  <a
                    onClick={() => {
                      ExportCSV();
                    }}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-15 mxl-30 px-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
          <TableCell className="Meeting-Status" onClick={() => { SortData("Status"); setSortedColumn("MeetingStatus"); }}>
            Meeting Status
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingStatus" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingStatus" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Company"); setSortedColumn("Company"); }}>
            Company
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Company" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Company" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("JobTitle"); setSortedColumn("JobTitle"); }}>
            Title
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "JobTitle" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "JobTitle" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("FirstName"); setSortedColumn("FirstName"); }}>
            Contact Name
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "FirstName" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "FirstName" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email"); }}>
            Email
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Domain"); setSortedColumn("Domain"); }}>
            Domain
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Domain" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Domain" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("MeetingSource"); setSortedColumn("MeetingSourceID"); }}>
            Meeting Source
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingSourceID" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingSourceID" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("MeetingOwner"); setSortedColumn("MeetingOwnerID"); }}>
            Owner
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingOwnerID" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingOwnerID" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("EventCreatedDt"); setSortedColumn("EventCreatedDt"); }}>
            Meeting Date
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "EventCreatedDt" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "EventCreatedDt" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("EventStartDt"); setSortedColumn("EventStartDt"); }}>
            Meeting Set Date
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "EventStartDt" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "EventStartDt" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("IsReminderMailSent"); setSortedColumn("IsReminderMailSent"); }}>
            Send Reminder
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsReminderMailSent" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsReminderMailSent" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("LinkedInUrl"); setSortedColumn("LinkedInUrl"); }}>
            LinkedIn Url
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("ContractValue"); setSortedColumn("ContractValue"); }}>
            Contract Value
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContractValue" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContractValue" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Industry"); setSortedColumn("Industry"); }}>
            Industry
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Industry" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Industry" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("ContactSource"); setSortedColumn("ContactSource"); }}>
            Contact Source
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContactSource" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContactSource" ? "active" : null} />
            </span>
          </TableCell>
        </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell className="labeltds" scope="row">
                        <select
                          onChange={(e) => {
                            Bookedstatusselect(
                              e,
                              row?._id,
                              row?.UserID,
                              row?.StatusID,
                              row?.Email
                            );
                          }}
                          class="form-control  m-input"
                          id={`MeetingStatusID${row?._id}`}
                          name="Meetingstatus"
                          autoComplete="off"
                          value={row.StatusID}
                        >
                          {MeetingStatus.map((value) => (
                            <option value={value._id}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell>{row?.JobTitle}</TableCell> 
                      <TableCell > <a
                                  href={`/viewcontacts?id=${row?.Prospectid}&email=${encodeURIComponent(row?.Email)}&pagename=kpiMeeting7days`}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      EditContactHandle(row?.Prospectid, row?.Email);
                                  }}
                        >
                          <u> {row?.Firstname +" "+  row?.Lastname }</u>
                        </a></TableCell> 
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell>  
                        <a
                                href={`http://${row?.Domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {row?.Domain}
                                </u>
                              </a></TableCell>
                      <TableCell>{row?.MSR?.Name}</TableCell>
                      <TableCell>
                        {row?.MO != null
                          ? row?.MO?.Name
                          : ""}
                      </TableCell>
                      <TableCell>{row?.EventStartDt}</TableCell>   
                      <TableCell>{row?.EventCreatedDt}</TableCell>
                      <TableCell>
                        {row?.IsSendMeetingReminder == true ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.ContractValue}</TableCell>
                      <TableCell>{row?.Industry}</TableCell>
                      <TableCell>{row?.ContactSource}</TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </>
  );
}
