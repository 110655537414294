import React from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
const moment = require("moment");
import Swal from "sweetalert2";
var CryptoJS = require("crypto-js");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { history } from "../../../_helpers";

import "react-toastify/dist/ReactToastify.css";
import { Email } from "@material-ui/icons";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

class ViewContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Prospectids: this.props.location.state?.data || new URLSearchParams(location.search).get('id') || null,
      Prospectemail: this.props.location.state?.EmailData || decodeURIComponent(new URLSearchParams(location.search).get('email')) || null,
      ProspectPageName: this.props.location.state?.pagename || new URLSearchParams(location.search).get('pagename') || null,
      Fields: {},
      Errors: {},
      TitleAvailable: null,
      ClientID: null,
      UserID: null,
      ContactData: [],
      ContactSource: "",
      ClientPoc: "",
      ReasonUnqualified: "",
      ContactTag: "",
      ContactStatus: "",
      ContactOwner: "",
      ContactCustomFieldArray: [],
      ContactCustomFieldById: [],
      ContactAllData: [],
      IsRepliedDataData: [],
      IsSentMailCountData: [],
      IsSoftBounceCount: [],
      IsOpenedCount: [],
      IsHardBounceCount: [],
      LastEmailCampaignData: [],
      ProspectIdForNote: "",
      ProspectNoteId: "",

      // Note
      Page: 1,
      RowsPerPage: 100,
      Search: "",
      SortField: "Title",
      SortedBy: 1,
      Sflag: false,
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      SearchData: [],
      TitleAvailableNotes: null,
      Fields1: {},
      Errors1: {},
      PageType: "",

      // contact status history
      HistoryData: [],
      HRows: [],
      HRlen: 0,
      HFlen: 0,
      HCountPage: 0,
      HPage: 1,
      HRowsPerPage: 100,
      HSearch: "",
      HSortField: "Status",
      HSortedBy: 1,
      HSflag: false,
      HSortedColumn:"p1Status",
      // lists prosepct
      ListData: [],
      ListRows: [],
      LRlen: 0,
      LFlen: 0,
      LCountPage: 0,
      PageL: 1,
      RowsPerPageL: 100,
      SearchL: "",
      SortFieldL: "Name",
      SortedByL: 1,
      SflagL: false,
      ListsSortedColumn:"Name",
      // store for delete
      Email: "",
      SEmail: "",
      ProspectCustomFieldById: [],
      LastCallDate:"",
      // potentmeeting data
      PotentialMeetingData: [],
      PMRows: [],
      PMRlen: 0,
      PMFlen: 0,
      PMCountPage: 0,
      PMPage: 1,
      PMRowsPerPage: 100,
      PMSearch: "",
      PMSortField: "FirstName",
      PMSortedBy: 1,
      PMSflag: false,
      PMNotes: [],
      PMSortedColumn:"FirstName",
      // meeting data
      MeetingData: [],
      MRows: [],
      MRlen: 0,
      MFlen: 0,
      MCountPage: 0,
      MPage: 1,
      MRowsPerPage: 100,
      MSearch: "",
      MSortField: "FirstName",
      MSortedBy: 1,
      MSflag: false,
      MNotes: [],
      MeetingSortedColumn:"FirstName",

      // call history data
      CallHistoryData: [],
      CRows: [],
      CRlen: 0,
      CFlen: 0,
      CCountPage: 0,
      CPage: 1,
      CRowsPerPage: 100,
      CSearch: "",
      CSortField: "Status",
      CSortedBy: 1,
      CSflag: false,
      CallHistorySortedColumn:"Status",
      // campaign history data
      CampaignHistoryData: [],
      CAMRows: [],
      CAMRlen: 0,
      CAMFlen: 0,
      CAMCountPage: 0,
      CAMPage: 1,
      CAMRowsPerPage: 100,
      CAMSearch: "",
      CAMSortField: "CampaignName",
      CAMSortedBy: 1,
      CAMSflag: false,
      CAMSortedColumn:"CampaignName",
      // contact status
      ContactStatusDData: [],
      ShowNotePopup: false,
      SendEmailPopup: false,

      // send email
      EmailDataSend: [],
      SelectedValueEmail: "",
      fields: {},
      errors: {},
      AccountData: [],
      AccountRefreshToken: "",
      AccountEmail: "",

      // for note
      NotePage: 1,
      NoteRowsPerPage: 100,
      NoteSearch: "",
      NoteSortField: "Title",
      NoteSortedBy: 1,
      NoteSflag: false,
      NoteData: [],
      NoteRows: [],
      NoteRlen: 0,
      NoteFlen: 0,
      NoteCountPage: 0,
      OldTitleUpdate: null,
      NoteSortedColumn:"Title",

      // note for 
      Errors: {},
      id: null,
      Open: false,
      NotesData: [],
      Role: "",
      CUserID: null,
      ProspectNoteBtnDisabled: false,
      IsLoading: true,
      BtnDisabledUpdate: true,
      CollCalllerName:[],
      LastStatusChangeDate:null,
      BlacklistedDate:null,

      // Call Note for
      CallNoteData: [],
      CallNoteRows: [],
      CallNoteRlen: 0,
      CallNoteFlen: 0,
      CallNoteCountPage: 0,
      CallNotePage: 1,
      CallNoteRowsPerPage: 100,
      CallNoteSearch: "",
      CallNoteSortField: "FirstName",
      CallNoteSortedBy: 1,
      CallNoteSflag: false,

      // Step histroy
          STHData: [],
          STHRows: [],
          STHRlen: 0,
          STHFlen: 0,
          STHCountPage: 0,
          STHPage: 1,
          STHRowsPerPage: 100,
          STHSearch: "",
          STHSortField: "CampaignName",
          STHSortedBy: 1,
          STHSflag: false,
          StepHistorySortedColumn:"CampaignName",


          // Contact Activity Data:
          ContactActivityData:[],
          ListData:[],
          IsListFalg:false,
          ProspectStatusHData:[],
          MeetingStatusHData:[],
          PotentialMeetingCSAData:[],
          MeetingCSAData:[],
          CampaignStatushistory:[],
          Sentmailcampaigndata:[],
          Externaldomaintreackdata:[],
          Callprospecthistorydata:[],
          BlacklistEmailHistoryData:[],
          ContactDomainBlacklistedData:[],
          CreatedDate:null,
          TotalEmailCount:null,
          TotalReplyCount:null,
          TotalSendEmailCount:null,
          TotalTrackedEmailCount:null,
          TotalOpenCount:null,
          TotalHardBounceCount:null,
          TotalSoftBounceCount:null,
          TotalTouchesCount:null,
          LastEmailStep:null,
          LastEmaiCampaign:"",
          LastEmailSendDate:null,
          LastOpenDate:null,
          LastResponseDate:null,
          CollCalllerNameArray:[],
          ContactStatusAllActivity:[],
          SendEmailRefreshToken:"",
          SendEmailAccount:null,

    };
    console.log(this.state.Prospectids,this.state.Prospectemail,this.state.ProspectPageName,"3455ee")
    this.ChangeRowSelectedCallNote = this.ChangeRowSelectedCallNote.bind(this);
    this.NoteGet();
    // this.CustomFieldValueGet();
    this.ContactEdit();

    this.ContactStatusHistoryGet();
    this.ListsGet();
    this.PotentialMeetingGet();
    this.MeetingGet();
    this.CallHistoryGet();
    this.CampaignHistoryGet();
    this.ContactStatusGet();
    this.CallNoteGet();
    this.StepHistoryGet();
    this.ContactActivityGet()


    this.ProspectNoteEditBtn = this.ProspectNoteEditBtn.bind(this);
    this.ProspectNoteSave = this.ProspectNoteSave.bind(this);
    this.ProspectNoteUpdate = this.ProspectNoteUpdate.bind(this);
    this.ShowPopupNoteHandler = this.ShowPopupNoteHandler(this);

    this.HandleChangePageList = this.HandleChangePageList.bind(this);
    this.ChangeRowSelectedList = this.ChangeRowSelectedList.bind(this);

    this.ChangeRowSelected = this.ChangeRowSelected.bind(this);
    this.HandleChangePage = this.HandleChangePage.bind(this);

    this.ChangeRowSelectedCallHistory = this.ChangeRowSelectedCallHistory.bind(this);
    this.HandleChangePageCallHistory = this.HandleChangePageCallHistory.bind(this);

    this.ChangeRowSelectedCampaignHistory = this.ChangeRowSelectedCampaignHistory.bind(this);
    this.HandleChangePageCampaignHistory = this.HandleChangePageCampaignHistory.bind(this);


    this.ChangeRowSelectedPotentialMeeting = this.ChangeRowSelectedPotentialMeeting.bind(this);
    this.HandleChangePagePotentialMeeting = this.HandleChangePagePotentialMeeting.bind(this);

    this.ChangeRowSelectedContactStatusHistory = this.ChangeRowSelectedContactStatusHistory.bind(this);
    this.HandleChangePageContactStatusHistory = this.HandleChangePageContactStatusHistory.bind(this);

    this.ChangeRowSelectedMeeting = this.ChangeRowSelectedMeeting.bind(this);
    this.HandleChangePageMeeting = this.HandleChangePageMeeting.bind(this);

    this.Handlevalidationfornotesedit = this.Handlevalidationfornotesedit.bind(this);


    // this.CallNoteHandleChangePage = this.CallNoteHandleChangePage(this);
  }

  componentDidMount() {
    document.title = "Edit Contact | SalesHive";
    const details = GetUserDetails();

    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        UserID: details.ParentUserID,
        CUserID: details.ChildUserID,
        Role: details.Role,
      });
      this.ContactInfoGetData(details.ClientID, details.ParentUserID, details.Role);
      this.CustomFieldValueGet();
    }
  }



  handleemailchange(e) {
    this.setState({
      SelectedValueEmail: e.target.value
    })
  }

  handleClick = () => {
    this.setState((prevState) => ({
      ShowNotePopup: !prevState.ShowNotePopup,
      Errors: {},
      TitleAvailableNotes: null
    }));
  };

  Handlevalidationfornotesedit() {
    let Errors1 = {};
    Errors1["TitlePEditNote"] = "";
    Errors1["TitlePEditNote"] = "";
    this.state.TitleAvailableNotes = null;
    this.setState({ Errors1: Errors1 });
  }
  // contact update
  ContactEdit() {
    this.setState({ IsLoading: true });
   // const id = this.props.location.state;
    this.setState({ id: this.state.Prospectids });
  
    var str_in = {
      ProspectID: {data:this.state.Prospectids},
    };
  
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      const contactData = res.data?.Data;
      this.setState({
        CreatedDate:contactData[0]?.CreatedDate
      })
  
      this.setState({
        ContactData: contactData,
        ProspectIdForNote: contactData[0]?._id,
        Email: contactData[0]?.Email

      });
  
      // All contact info
      var allstr_in = {
        ContactSourceID: contactData[0]?.ContactSourceID,
        ClientPOCID: contactData[0]?.ClientPOCID,
        ReasonUnqualifiedID: contactData[0]?.ReasonUnqualifiedID,
        ContactOwnerID: contactData[0]?.ContactOwnerID,
        ContactTagID: contactData[0]?.ContactTagID,
        ContactStatusID: contactData[0]?.ContactStatusID,
        CustomFieldID: contactData[0]?.ProspectID,
      };
  
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/FindContactInfo",
        method: "POST",
        data: allstr_in,
      }).then((resall) => {
        const contactSource = resall.data.ContactSourceData[0]?.Name;
        const clientPoc = resall.data.ClientPOCData[0]?.Name;
        const reasonUnqualified = resall.data.ReasonUnqualifiedData[0]?.Reason;
        const contactStatus = resall.data.ContactStatusData[0]?.Status;
        const contactOwner = resall.data.ContactOwnerData[0]?.Name;
        const contactCustomFieldById = resall.data?.ContactCustomFieldByIDData;
  
        this.setState({
          ContactSource: contactSource,
          ClientPoc: clientPoc,
          ReasonUnqualified: reasonUnqualified,
          ContactStatus: contactStatus,
          ContactOwner: contactOwner,
          ContactCustomFieldById: contactCustomFieldById,
          // IsLoading: false,
        });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
  
      // Prospect note
      this.setState({ ProspectNoteId: contactData[0]?._id });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    })
  }
  

  // POtential meeting get
  async PotentialMeetingGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
     // const { EmailData } = this.props.location.state;
  
      const PotentialMeetingDataStr = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.PMSortedBy,
        Search: this.state.PMSearch,
        ClientID: ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: ParentUserID,
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/PotentialMeetingGet`,
        PotentialMeetingDataStr
      );
  
      this.setState({
        PotentialMeetingData: result.data?.PageData,
        PMRows: result.data?.PageData,
        PMRlen: result.data?.TotalCount,
        PMFlen: result.data?.TotalCount,
        PMCountPage: result.data?.PageCount,
        PMNotes: result.data?.PotentialMeetingNoteData,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  

  // Meeting get
  async MeetingGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      //const { EmailData } = this.props.location.state;
  
      const MeetingDataStr = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: this.state.MSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        Email: this.state.Prospectemail,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/MeetingGet`,
        MeetingDataStr
      );
  
      this.setState({
        MeetingData: result.data?.PageData,
        MRows: result.data?.PageData,
        MRlen: result.data?.TotalCount,
        MFlen: result.data?.TotalCount,
        MCountPage: result.data?.PageCount,
        MNotes: result.data?.MeetingNoteData,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
 
    // Contact Activity Get
    async ContactActivityGet() {
      try {
        this.setState({ IsLoading: true });
        const { ClientID, ParentUserID } = GetUserDetails();
        // const { data } = this.props.location.state;
        // const { EmailData } = this.props.location.state;
        const ContactActivityDataStr = {
          ClientID: ClientID,
          UserID: ParentUserID,
          ProspectId: this.state.Prospectids,
          Type: "User",
          Role: this.state.Role,
          Email:this.state.Prospectemail
        };
    
        const result = await Axios.post(
          `${CommonConstants.MOL_APIURL}/contacts/ContactActivityGet`,
          ContactActivityDataStr
        );
    
        this.setState({
          ContactActivityData: result.data?.Data,
          ListData:result.data?.ListData,
          IsListFalg:result.data?.ListUploadFlag,
          LastCallDate:result.data?.Callprospecthistorydata?.map(item => item?.CreatedDate).reverse()?.[0],
          // IsLoading: false,
        });

        
        // Push ProspectStatusHData with IsProspectStatusFlag if it has data
        if (result.data?.ProspectStatusData.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.ProspectStatusData.map(item => ({ ...item, IsProspectStatusFlag: true })));
        }
        
        // Push CampaignStatushistory with IsProspectCampaignStatusFlag if it has data
        if (result.data?.CampaignStatushistory.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.CampaignStatushistory.map(item => ({ ...item, IsProspectCampaignStatusFlag: true })));
        }
        
        // Push Sentmailcampaigndata with IsSentEmailFlag if it has data
        if (result.data?.Sentmailcampaigndata.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.Sentmailcampaigndata.map(item => ({ ...item, IsSentEmailFlag: true })));
        }
        
         // Push Meeting status history data with IsMeetingStatusFlag if it has data
         if (result.data?.MeetingStatusHistoryData.length > 0) {
                this.state.ContactStatusAllActivity.push(...result.data?.MeetingStatusHistoryData.map(item => ({ ...item, IsMeetingStatusFlag: true })));
              }
        //    // Push potential meeting history data with IsPotentialMeetingFlag if it has data
           if (result.data?.PotentialMeetingData.length > 0) {
            this.state.ContactStatusAllActivity.push(...result.data?.PotentialMeetingData.map(item => ({ ...item, IsPotentialMeetingFlag: true })));
          }

        //   // Push  meeting  data with IsMeetingFlag if it has data
          if (result.data?.MeetingData.length > 0) {
                    this.state.ContactStatusAllActivity.push(...result.data?.MeetingData.map(item => ({ ...item, IsMeetingFlag: true })));
          }
        //    // Push contact domain blacklisr data with IsContactDomainBlacklistedFlag if it has data
           if (result.data?.ContactDomainBlacklistedData.length > 0) {
            this.state.ContactStatusAllActivity.push(...result.data?.ContactDomainBlacklistedData.map(item => ({ ...item, IsContactDomainBlacklistedFlag: true })));
          }

        // // // Push external domain track domain data with IsExternalDomainTrackFlag if it has data
          if (result.data?.Externaldomaintreackdata.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.Externaldomaintreackdata.map(item => ({ ...item, IsExternalDomainTrackFlag: true })));
          }

        // //           // Push call history contact data with IsContatCallHistoryFlag if it has data
                  if (result.data?.Callprospecthistorydata.length > 0) {
                    this.state.ContactStatusAllActivity.push(...result.data?.Callprospecthistorydata.map(item => ({ ...item, IsContatCallHistoryFlag: true })));
                    }

                    if (result.data?.EmailContactBlacklist.length > 0) {
                      this.state.ContactStatusAllActivity.push(...result.data?.EmailContactBlacklist.map(item => ({ ...item, IsContatEmailBlacklIstFlag: true })));
                      }

                    // Parse CreatedDate strings into Date objects before sorting
                    this.state.ContactStatusAllActivity.forEach(item => {
                      if (item.CreatedDate) {
                        item.CreatedDate = new Date(item.CreatedDate);
                      }
                    });

                      // Sort ContactStatusAllActivity by CreatedDate
                      this.state.ContactStatusAllActivity.sort((a, b) => a.CreatedDate - b.CreatedDate).reverse();
                      this.setState({ IsLoading: false });
                            } catch (error) {
                              // Handle errors
                              console.error("Error fetching data:", error);
                              this.setState({ IsLoading: false });
                            }
                          }

    // Call Note get
    async CallNoteGet() {
      try {
        this.setState({ IsLoading: true });
       // const { data: id } = this.props.location.state;
        const CallNoteDataStr = {
          Page: this.state.CallNotePage,
          RowsPerPage: this.state.CallNoteRowsPerPage,
          Sort: true,
          Field: this.state.CallNoteSortField,
          SortBy: this.state.CallNoteSortedBy,
          Search: this.state.CallNoteSearch,
          ProspectID: this.state.Prospectids,
          Type: "User",
          Role: this.state.Role,
        };
        const result = await Axios.post(
          `${CommonConstants.MOL_APIURL}/contacts/CallNoteGet`,
          CallNoteDataStr
        );    
    
    
        this.setState({
          CallNoteData: result.data?.PageData || [], // Set CallNoteData to an empty array if no data
          CallNoteRows: result.data?.PageData || [],
          CallNoteRlen: result.data?.TotalCount || 0, // Set to 0 if undefined
          CallNoteFlen: result.data?.TotalCount || 0, // Set to 0 if undefined
          CallNoteCountPage: result.data?.PageCount || 0, // Set to 0 if undefined
          // IsLoading: false,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        this.setState({ IsLoading: false });
      }
    }

  // Call history get
  async ContactStatusGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID } = GetUserDetails();
     // const { data: id } = this.props.location.state;
  
      const DataStr = {
        ClientID: ClientID,
        ProspectID: this.state.Prospectids,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ContactLastEmail`,
        DataStr
      );
  
      const newArray = [result.data?.ContactStatusAllData];
  
      this.setState({
        ContactStatusDData: newArray,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  
  
  // contact note get
  async NoteGet() {

    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
     // const { EmailData, data: id } = this.props.location.state;
  
      // this.setState({ id: id });
  
      const ProspectNoteData = {
        Page: this.state.NotePage,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: this.state.Prospectids,
        Email: this.state.Prospectemail,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: this.state.NoteSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const responsenote = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/NoteGet`,
        ProspectNoteData
      );
  
      this.setState({
        NoteData: responsenote.data?.PageData,
        NoteRows: responsenote.data?.PageData,
        NoteRlen: responsenote.data?.TotalCount,
        NoteFlen: responsenote.data?.TotalCount,
        NoteCountPage: responsenote.data?.PageCount,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  
  // call history get
  async CallHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
     // const { data: id } = this.props.location.state;
  
      const CallHistoryDataStr = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: this.state.CSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: this.state.Prospectids,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/CallHistoryGet`,
        CallHistoryDataStr
      );
  
      this.setState({
        CallHistoryData: result.data?.PageData,
        CRows: result.data?.PageData,
        CRlen: result.data?.TotalCount,
        CFlen: result.data?.TotalCount,
        CCountPage: result.data?.PageCount,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  

  // call note contact change display rows
  ChangeRowSelected = (Event) => {
    var Details = GetUserDetails();
    this.setState({ NoteRowsPerPage: Number(Event.target.value), NotePage: 1 });
    this.setState({ IsLoading: true });
    let SearchedNote = document.getElementById("SearchNote").value;
    // const Email = this.props.location.state.EmailData;
    // const id = this.props.location.state.data;
    // this.setState({ id: id });
    var ProspectNoteData = {
      Page: 1,
      ClientID: Details.ClientID,
      ProspectID: this.state.Prospectids,
      Email: this.state.Prospectemail,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.NoteSortField,
      SortBy: this.state.NoteSortedBy,
      Search: SearchedNote,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
      method: "POST",
      data: ProspectNoteData,
    }).then((responsenote) => {
      this.setState({ NoteData: responsenote.data?.PageData });
      this.setState({ NoteRows: responsenote.data?.PageData });
      this.setState({ NoteRlen: responsenote.data?.TotalCount });
      this.setState({ NoteFlen: responsenote.data?.TotalCount });
      this.setState({ NoteCountPage: responsenote.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  // search for record contact nFVote
  RequestSearch(event) {
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      let SearchedNote = document.getElementById("SearchNote").value;
      var Details = GetUserDetails();
      // const Email = this.props.location.state.EmailData;
      // const id = this.props.location.state.data;
      this.setState({ id: this.state.Prospectids});
      var ProspectNoteData = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        Email: this.state.Prospectemail,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: SearchedNote,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: ProspectNoteData,
      }).then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });

    }
  }

  // change page
  HandleChangePage = (Event, NewPage) => {
    var Details = GetUserDetails();
    this.setState({ NotePage: NewPage });
    var SearchedVal = document.getElementById("SearchNote").value;

    if (NewPage == this.state.NotePage) {
      this.setState({ IsLoading: true });
      this.setState({ NotePage: NewPage });
      this.setState({ IsLoading: false });

    } else {
      this.setState({ IsLoading: true });
      // const Email = this.props.location.state.EmailData;
      // const id = this.props.location.state.data;
      this.setState({ id: id });
      var ProspectNoteData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        Email: this.state.Prospectemail,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: ProspectNoteData,
      }).then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  //  potential meeting change display rows
  ChangeRowSelectedPotentialMeeting = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ PMRowsPerPage: Number(Event.target.value), PMPage: 1 });
    //const Email = this.props.location.state.EmailData;
    var SearchedVal = document.getElementById("searchPotentialMeeting").value;
    var PotentialMeetingDataStr = {
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.PMSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      Email: this.state.Prospectemail,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
      method: "POST",
      data: PotentialMeetingDataStr,
    }).then((result) => {
      this.setState({ PotentialMeetingData: result.data?.PageData });
      this.setState({ PMRows: result.data?.PageData });
      this.setState({ PMRlen: result.data?.TotalCount });
      this.setState({ PMFle: result.data?.TotalCount });
      this.setState({ PMCountPage: result.data?.PageCount });
      this.setState({ PMNotes: result.data?.PotentialMeetingNoteData });
      this.setState({ IsLoading: false });

    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  //potential meeting search for record contact note
  RequestSearchPotentialMeeting(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchPotentialMeeting").value;
      this.setState({ PMSearch: SearchedVal });
     // const Email = this.props.location.state.EmailData;
      var PotentialMeetingDataStr = {
        Page: 1,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.PMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: PotentialMeetingDataStr,
      }).then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // meeting search for record contact note
  RequestSearchMeeting(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var Searchedval = document.getElementById("searchMeeting").value;
    //  const Email = this.props.location.state.EmailData;
      var MeetingDataStr = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: Searchedval,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: MeetingDataStr,
      }).then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }
  // call history search for record
  RequestSearchCallHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var Searchedval = document.getElementById("searchcallhistory").value;
     // const id = this.props.location.state.data;
      this.setState({ id: id });
      var CallHistoryrDataStr = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: Searchedval,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: CallHistoryrDataStr,
      }).then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // meeting change page
  HandleChangePageMeeting = (Event, NewPage) => {
    this.setState({ MPage: NewPage });
    if (NewPage == this.state.MPage) {
      this.setState({ IsLoading: true });
      this.setState({ MPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ MPage: NewPage });
      var SearchedVal = document.getElementById("searchMeeting").value;
      var Details = GetUserDetails();
      //const Email = this.props.location.state.EmailData;
      var MeetingDataStr = {
        Page: NewPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Email: this.state.Prospectemail,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: MeetingDataStr,
      }).then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ MNotes: result.data?.MeetingNoteData });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Step history change page
  HandleChangePageStepHistory = (Event, NewPage) => {
    this.setState({ STHPage: NewPage });
    if (NewPage == this.state.STHPage) {
      this.setState({ IsLoading: true });
      this.setState({ STHPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ STHPage: NewPage });
      var SearchedVal = document.getElementById("searchMeeting").value;
      var Details = GetUserDetails();
      //const Email = this.props.location.state.EmailData;
      var StepHistoryDataStr = {
        Page: NewPage,
        RowsPerPage: this.state.STHRowsPerPage,
        Sort: true,
        Field: this.state.STHSortField,
        SortBy: this.state.STHSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Email: this.state.Prospectemail,
        ProspectID:this.state.Prospectids,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/StepHistoryGet",
        method: "POST",
        data: StepHistoryDataStr,
      }).then((result) => {
        this.setState({
          STHData: result.data?.PageData,
          STHRows: result.data?.PageData,
          STHRlen: result.data?.TotalCount,
          STHFlen: result.data?.TotalCount,
          STHCountPage: result.data?.PageCount,
          IsLoading: false,
        });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  //  call history  change page
  HandleChangePageCallHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.CPage) {
      this.setState({ IsLoading: true });
      this.setState({ CPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ CPage: NewPage });
      var SearchedVal = document.getElementById("searchcallhistory").value;
      //const id = this.props.location.state.data;
      this.setState({ id: this.state.Prospectids });
      var CallHistoryrDataStr = {
        Page: NewPage,
        RowsPerPage: Number(Event.target.value),
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: CallHistoryrDataStr,
      }).then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  //   meeting change display rows
  ChangeRowSelectedMeeting = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ MRowsPerPage: Number(Event.target.value), MPage: 1 });
    var SearchedVal = document.getElementById("searchMeeting").value;
    //const Email = this.props.location.state.EmailData;
    var MeetingDataStr = {
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.MSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Email: this.state.Prospectemail,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
      method: "POST",
      data: MeetingDataStr,
    }).then((result) => {
      this.setState({ MeetingData: result.data?.PageData });
      this.setState({ MRows: result.data?.PageData });
      this.setState({ MRlen: result.data?.TotalCount });
      this.setState({ MFlen: result.data?.TotalCount });
      this.setState({ MCountPage: result.data?.PageCount });
      this.setState({ MNotes: result.data?.MeetingNoteData });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  //   meeting change display rows
  ChangeRowSelectedCallHistory = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ CRowsPerPage: Number(Event.target.value), CPage: 1 });
    var SearchedVal = document.getElementById("searchcallhistory").value;
    //const id = this.props.location.state.data;
    this.setState({ id: id });
    var CallHistoryrDataStr = {
      Page: this.state.CPage,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.CSortField,
      SortBy: this.state.CSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      ProspectID: this.state.Prospectids,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
      method: "POST",
      data: CallHistoryrDataStr,
    }).then((result) => {
      this.setState({ CallHistoryData: result.data?.PageData });
      this.setState({ CRows: result.data?.PageData });
      this.setState({ CRlen: result.data?.TotalCount });
      this.setState({ CFlen: result.data?.TotalCount });
      this.setState({ CCountPage: result.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  // potential meetingchange page
  HandleChangePagePotentialMeeting = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.PMPage) {
      this.setState({ IsLoading: true });
      this.setState({ PMPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ PMPage: NewPage });
      var SearchedVal = document.getElementById("searchPotentialMeeting").value;
      //const Email = this.props.location.state.EmailData;
      var PotentialMeetingDataStr = {
        Page: NewPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.PMSortField,
        SortBy: this.state.PMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: PotentialMeetingDataStr,
      }).then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ PMNotes: result.data?.PotentialMeetingNoteData });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }


  };
  //delete contact note id
  ProspectNoteDelete(id, PageName) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a prospect note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          Type: PageName,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: this.state.UserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteDelete",
          method: "POST",
          data: PNoteDelete,
        }).then(async(res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "prospect note deleted successfully.",
                "success"
              );
              await this.NoteGet();
              this.setState({ IsLoading: false });
            } else {
              this.setState({ IsLoading: false });
            }
          }
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });

        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  // custom field data list
  async CustomFieldValueGet() {
    try {
      this.setState({ IsLoading: true });
      const CustomFiedIdData = { ProspectID: this.state.Prospectids };
      const res = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ProspectCustomFieldId`,
        CustomFiedIdData
      );

      this.setState({
        ProspectCustomFieldById: res.data?.ProspectCustomFieldById || [],
        // IsLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  
  
  
  

  // save contact note
  async ProspectNoteSave() {
    this.setState({ ProspectNoteBtnDisabled: true });
    var Final_flag = await this.fromValidationNotes();
    if (Final_flag == true) {
      this.setState({ IsLoading: true });
      var Title = document.getElementById("TitlePNote").value;
      var Notes = document.getElementById("NotesPNote").value;
      var ProspectData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ProspectID: this.state.ProspectIdForNote,
        Title: Title,
        Note: Notes,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      try {
        const res = await Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteAdd",
          method: "POST",
          data: ProspectData,
        });
  
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Prospect note added successfully.", "Add Note");
          await this.NoteGet();  // Await the NoteGet method
          this.setState({ ShowNotePopup: false });
        } else {
          toast.error(res.data.Message);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      } finally {
        this.setState({ IsLoading: false });
        this.setState({ ProspectNoteBtnDisabled: false });
      }
    } else {
      this.setState({ ProspectNoteBtnDisabled: false });
    }
  }
  //form validation for add prospect note
  async fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.Fields;
    let Errors = {};
    var Title = document.getElementById("TitlePNote").value.trim();
    var Notes = document.getElementById("NotesPNote").value.trim();
    var IsExist = await this.ProspectNoteTitleCheckExist(Title, "Prospect");
    if (Title == "") {
      formIsValid = false;
      Errors["TitlePNote"] = "Please Enter Title";
    }
    if (Notes == "") {
      formIsValid = false;
      Errors["NotesPNote"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ Errors: Errors });
    return formIsValid;
  }
  // find check exits contact prospect title
  async ProspectNoteTitleCheckExist(Title, PageType) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
      Type: PageType
    };
    var res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
      method: "POST",
      data: ProspectNote,
    })
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data.length > 0) {
        let Errors = {};
        this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
        Errors["NotesPNote"] = "";
        this.setState({ Errors: Errors });
        return true;
      } else {
        return false;
      }
    }

  }

  // handlechage add prospect note
  HandleChangePNotesAdd(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailableNotes = null;
      this.ProspectNoteTitleCheckExist(e.target.value, "Prospect");
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors.Title = null;
    }
  }

  // find id by record contact edit
  ProspectNoteEditBtn(id, PageName) {
    this.setState({ id: id });
    this.setState({ PageType: PageName })
    var ProspectNoteEdit = {
      id: id,
      Type: PageName
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteByID",
      method: "POST",
      data: ProspectNoteEdit,
    }).then((res) => {

      // if (res.data.StatusMessage == "SUCCESS") {
      this.setState({ OldTitleUpdate: res.data[0]?.Title });
      document.getElementById("TitlePEditNote").value = res.data[0].Title;
      document.getElementById("NotesEditPNotes").value = res.data[0].Note;
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  //  contact note update
  async ProspectNoteUpdate(e, closeCallback) {
    this.setState({ BtnDisabledUpdate: true });
    var FinalValidation = await this.fromValidationNote();
    if (FinalValidation == true) {
      var Title = document.getElementById("TitlePEditNote").value;
      var Notes = document.getElementById("NotesEditPNotes").value;

      var ProspectNoteUpdateData = {
        _id: this.state.id,
        Title: Title,
        Note: Notes,
        Type: this.state.PageType,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteUpdate",
        method: "POST",
        data: ProspectNoteUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Prospect note Status update successfully.",
            "Update Potential meeting Status"
          );
          closeCallback();
          this.NoteGet()
        } else {
          toast.error(res.data.Message);
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      document.getElementById("submitupdate").disabled = false;
    }

  }

  // formvalidation note update
  async fromValidationNote() {
    let formIsValid = true;

    let Fields = this.state.Fields1;
    let Errors1 = {};

    var Title1 = document.getElementById("TitlePEditNote").value;
    var Notes1 = document.getElementById("NotesEditPNotes").value;
    var IsExist = await this.ProspectNoteTitleCheckExist(Title1, this.state.PageType);
    if (Title1 == "") {
      formIsValid = false;
      Errors1["TitlePEditNote"] = "Please Enter Title";
    }
    if (Notes1 == "") {
      formIsValid = false;
      Errors1["NotesEditPNotes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ Errors1: Errors1 });

    return formIsValid;
  }

  // check exists update notes
  async ProspectNoteTitleCheckExist1(Title, PageT) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
      Type: PageT
    };
    var res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
      method: "POST",
      data: ProspectNote,
    })
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data.length > 0) {
        if (this.state.OldTitleUpdate == Title) {
          return false;
        } else {
          let Errors1 = {};
          this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
          Errors1["TitlePEditNote"] = "";
          this.setState({ Errors1: Errors1 });
          return true;
        }
      } else {
        return false;
      }
    }

  }

  // handlechage add prospect note
  HandleChangePNotesEdit(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors1.Title = null;
      this.state.TitleAvailableNotes = null;
      this.ProspectNoteTitleCheckExist1(e.target.value, this.state.PageType);
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors1.Title = null;
    }
  }
  // contact status history get
  async ContactStatusHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
    //  const { data: id } = this.props.location.state;
  
      this.setState({ id: this.state.Prospectids });
  
      const ContactStatusHistoryData = {
        Page: this.state.HPage,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: this.state.HSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const reshistory = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ProspectstatusHistoryGet`,
        ContactStatusHistoryData
      );
  
      this.setState({
        HistoryData: reshistory.data?.PageData,
        HRows: reshistory.data?.PageData,
        HRlen: reshistory.data?.TotalCount,
        HFlen: reshistory.data?.TotalCount,
        HCountPage: reshistory.data?.PageCount,
        // IsLoading: false,
        BlacklistStatusData:reshistory.data?.BlacklistStatusProsepctData[0],
        LastStatusChangeDate:reshistory.data?.PageData[0].ChangedDate ?  new Date(reshistory.data?.PageData[0].ChangedDate).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // hour12: true,
          timeZone:'utc'
        }) : '',
        BlacklistedDate:reshistory.data?.BlacklistStatusProsepctData[0]?.CreatedDate ?  new Date(reshistory.data?.BlacklistStatusProsepctData[0]?.CreatedDate).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // hour12: true
        }) : ''


      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  

  // contact info delete data
  ContactInfoGetData(CID, UID, Role) {
    this.setState({ IsLoading: true });
    const str_in = {
      ClientID: CID,
      UserID: UID,
      Role: Role
    };

    Axios.post(CommonConstants.MOL_APIURL + "/contacts/ViewContactInfoDetailsGet", str_in)
      .then((result) => {
        this.setState({
          ContactCustomFieldArray: result.data?.ContactCustomFieldData || [],
          EmailDataSend: result.data?.EmailData,
          AccountEmail: result.data?.EmailData[0]?.SMTPFromEmail || "",
          AccountData: result.data.EmailData[0]?.RefreshToken || "",
          AccountRefreshToken: result.data?.EmailData[0]?.RefreshToken || "",
          CollCalllerNameArray: result.data?.SalesReplyDevlopmentData || [],
          // IsLoading: false,
        });
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  }

  // form validation
  FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    if (!Fields["Title"]) {
      FormIsValid = false;
      Errors["Title"] = "Please enter title";
    }
    if (!Fields["Note"]) {
      FormIsValid = false;
      Errors["Note"] = "Please enter note";
    }

    if (this.state.TitleAvailable != null) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // Search for contact status history 
  RequestSearchContactHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      let SearchStatusHistory = document.getElementById("HSearch").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ContactStatusHistoryData = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: SearchStatusHistory,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: ContactStatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // change display rows contact status history
  HandleChangePageContactStatusHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.HPage) {
      this.setState({ IsLoading: true });
      this.setState({ HPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ HPage: NewPage });
      var SearchedVal = document.getElementById("HSearch").value;
     // const id = this.props.location.state.data;
      this.setState({ id: id });
      var ContactStatusHistoryData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: ContactStatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Change per row wise contact status history
  ChangeRowSelectedContactStatusHistory(Event) {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ HRowsPerPage: Number(Event.target.value), HPage: 1 });
    var SearchedVal = document.getElementById("HSearch").value;
   // const id = this.props.location.state.data;
    // this.setState({ id: id });
    var ContactStatusHistoryData = {
      Page: 1,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      ProspectID: this.state.Prospectids,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.HSortField,
      SortBy: this.state.HSortedBy,
      Search: SearchedVal,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
      method: "POST",
      data: ContactStatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }
  
  // Step History get
  async StepHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
    //  const { data: id } = this.props.location.state;
  
      const StepHistoryData = {
        Page: this.state.STHPage,
        ProspectID: this.state.Prospectids,
        ClientID: ClientID,
        UserID: ParentUserID,
        RowsPerPage: this.state.STHRowsPerPage,
        Sort: true,
        Field: this.state.STHSortField,
        SortBy: this.state.STHSortedBy,
        Search: this.state.STHSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result1 = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/StepHistoryGet`,
        StepHistoryData
      );
  
      this.setState({
        STHData: result1.data?.PageData,
        STHRows: result1.data?.PageData,
        STHRlen: result1.data?.TotalCount,
        STHFlen: result1.data?.TotalCount,
        STHCountPage: result1.data?.PageCount,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  // Change Row Step History
    ChangeRowSelectedStepHistory = (Event) => {
      var Details = GetUserDetails();
      this.setState({ IsLoading: true });
      this.setState({ STHRowsPerPage: Number(Event.target.value), MPage: 1 });
      var SearchedVal = document.getElementById("searchStepHistory").value;
      var StepHistoryDataStr = {
        Page: 1,
        RowsPerPage: Number(Event.target.value),
        ProspectID: this.state.Prospectids,
        Sort: true,
        Field: this.state.STHSortField,
        SortBy: this.state.STHSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Email: this.state.Prospectemail,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/StepHistoryGet",
        method: "POST",
        data: StepHistoryDataStr,
      }).then((result1) => {
        this.setState({
          STHData: result1.data?.PageData,
          STHRows: result1.data?.PageData,
          STHRlen: result1.data?.TotalCount,
          STHFlen: result1.data?.TotalCount,
          STHCountPage: result1.data?.PageCount,
          IsLoading: false,
        });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    };
    RequestSearchStepHistory(event) {
      var Details = GetUserDetails();
      if (event.key === "Enter") {
        this.setState({ IsLoading: true });
        var SearchedVal = document.getElementById("searchStepHistory").value;
        this.setState({ STHSearch: SearchedVal });
        var StepHistoryDataStr = {
          Page: 1,
          ProspectID: this.state.Prospectids,
          RowsPerPage: this.state.STHRowsPerPage,
          Sort: true,
          Field: this.state.STHSortField,
          SortBy: this.state.STHSortedBy,
          Search: SearchedVal,
          ClientID: Details.ClientID,
          UserID: Details.ParentUserID,
          Type: "User",
          Role: Details.Role
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/StepHistoryGet",
          method: "POST",
          data: StepHistoryDataStr,
        }).then((result1) => {
          this.setState({
            STHData: result1.data?.PageData,
            STHRows: result1.data?.PageData,
            STHRlen: result1.data?.TotalCount,
            STHFlen: result1.data?.TotalCount,
            STHCountPage: result1.data?.PageCount,
            IsLoading: false,
          });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
        });
      }
    }
  // Lists get all
  async ListsGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
     // const { data: id } = this.props.location.state;
  
      const ListData = {
        Page: this.state.PageL,
        ProspectID: this.state.Prospectids,
        ClientID: ClientID,
        UserID: ParentUserID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        SortBy: this.state.SortedByL,
        Search: this.state.SearchL,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result1 = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ListsGet`,
        ListData
      );
  
      this.setState({
        ListData: result1.data?.PageData,
        ListRows: result1.data?.PageData,
        LRlen: result1.data?.TotalCount,
        LFlen: result1.data?.TotalCount,
        LCountPage: result1.data?.PageCount,
        // IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  

  // search for record
  RequestSearchList(Event) {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchlist").value;
      this.setState({ SearchL: SearchedVal });
      var ListData = {
        Page: 1,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        SortBy: this.state.SortedByL,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: ListData,
      }).then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  
  // change display rows
  ChangeRowSelectedList(Event) {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ RowsPerPageL: Number(Event.target.value), PageL: 1 });
    var SearchedVal = document.getElementById("searchlist").value;
    var ListData = {
      Page: 1,
      ProspectID: this.state.Prospectids,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.SortFieldL,
      SortBy: this.state.SortedByL,
      Search: SearchedVal,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
      method: "POST",
      data: ListData,
    }).then((result1) => {
      this.setState({ ListData: result1.data?.PageData });
      this.setState({ ListRows: result1.data?.PageData });
      this.setState({ LRlen: result1.data?.TotalCount });
      this.setState({ LFlen: result1.data?.TotalCount });
      this.setState({ LCountPage: result1.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  // change page
  HandleChangePageList(Event, NewPage) {
    var Details = GetUserDetails();
    if (this && this.state && NewPage == this.state.PageL) {
      this.setState({ IsLoading: true });
      this.setState({ PageL: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ PageL: NewPage });
      var SearchedVal = document.getElementById("searchlist").value;
      var ListData = {
        Page: NewPage,
        ProspectID: this.state.Prospectids,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        RowsPerPage: this.state?.RowsPerPageL,
        Sort: true,
        Field: this.state?.SortFieldL,
        SortBy: this.state?.SortedByL,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: ListData,
      }).then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // edit page
  editspage = (id) => {
    if (this.state.ProspectPageName == "Potential") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "Potential",EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "PotentialLinkedin") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "PotentialLinkedin",EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "clientPotenial") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "clientPotenial",EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "ClientBookedMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "ClientBookedMeeting",EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "ClientLinkedinFollow") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "ClientLinkedinFollow",EmailData: this.state.Prospectemail } })
    }else if (this.state.ProspectPageName == "ClientRescheduleMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "ClientRescheduleMeeting",EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "ColdCallingContacts") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "ColdCallingContacts", EmailData: this.state.Prospectemail } })
    }else if (this.state.ProspectPageName == "BookedMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "BookedMeeting", EmailData: this.state.Prospectemail } })
    }else if (this.state.ProspectPageName === "kpiMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "kpiMeeting", EmailData: this.state.Prospectemail } })
    }else if (this.state.ProspectPageName === "Kpi7DaysPotential") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "Kpi7DaysPotential", EmailData: this.state.Prospectemail } })
    }
    else if (this.state.ProspectPageName === "kpiMeeting7days") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "kpiMeeting7days", EmailData: this.state.Prospectemail } })
    }else if (this.state.ProspectPageName === "kpiEmailResponder") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "kpiEmailResponder", EmailData: this.state.Prospectemail } })
    }
    else if (this.state.ProspectPageName == "OpportunitiesMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "OpportunitiesMeeting", EmailData: this.state.Prospectemail } })
    } else if (this.state.ProspectPageName == "RescheduleMeeting") {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "RescheduleMeeting", EmailData: this.state.Prospectemail } })
    }else if(this.state.ProspectPageName == "EditCampaign"){
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, Pagename: "EditCampaign", EmailData: this.state.Prospectemail, campignId: this.props.location.state.CampaigId } })

    }else {
      history.push({ pathname: "/editcontacts", state: { data: this.state.Prospectids, EmailData: this.state.Prospectemail } });
    }
  };

  // contact btn delete
  DeleteBtnContact = (id) => {
    if (this.props.location.state.CampaigId == undefined) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete a contact.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            IsDeleted: true,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: this.state.CUserID,
            CampaignID: null,
            ClientID:this.state.ClientID,
            UserID:this.state.UserID
          };

          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Contact  deleted successfully.",
                  "success"
                );
                history.push("/contacts");
              } else {
              }
            }
          }).catch((error) => {
            toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete a contact.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            IsDeleted: true,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: this.state.CUserID,
            CampaignID: this.props.location.state.CampaigId,
            ClientID:this.state.ClientID,
            UserID:this.state.UserID
          };


          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Contact  deleted successfully.",
                  "success"
                );
                if (this.props.location.state.PageName == "EditCampaign") {
                  history.push({ pathname: "/editcampaigns", state: { data: this.props.location.state.CampaigId } })
                } else {
                  history.push("/contacts");
                }

              } else {
              }
            }
          }).catch((error) => {
            toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }

  };

  // contact add to blacklist domain
  ContactAddToBlackList = (Email) => {
    this.setState({IsLoading:true})
    var ContactData = {
      ClientID: this.state.ClientID,
      LastUpdatedBy: this.state.CUserID,
      Email: Email,
      UserID: this.state.UserID,
      Role: this.state.Role,
      ProspectID:this.state.Prospectids
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactToBlackList",
      method: "POST",
      data: ContactData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(res.data.Message);
        this.ContactEdit()
        this.CustomFieldValueGet();
        this.NoteGet();
        this.ContactStatusHistoryGet();
        this.ListsGet();
        this.PotentialMeetingGet();
        this.MeetingGet();
        this.CallHistoryGet();
        this.CampaignHistoryGet();
        this.ContactStatusGet();
        this.CallNoteGet();
        this.StepHistoryGet();
        this.ContactActivityGet();
        this.ContactInfoGetData(this.state.ClientID,this.state.UserID,this.state.Role);

        this.setState({IsLoading:false})
      }else if(res.data.StatusMessage === "EXITS"){
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      }else if(res.data.StatusMessage === "NotExitsEmail"){
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };
  // meetings add
  MeetingsAdd() {
    var PushData = this.state.ContactData
    history.push({ pathname: "/addbookedmeeting", state: { Pagename: "Meeting", PushData, dataprospect: this.state.ProspectIdForNote, BackPage: "/viewcontacts", pid: this.state.Prospectids, EmailData: this.state.Prospectemail } });
  }
  // campaign add 
  CampaignAdd() {
    history.push({ pathname: "/addCampaigns", state: { Pagename: "ViewContact", dataprospect: this.state.ProspectIdForNote }, data: this.state.Prospectids, EmailData: this.state.Prospectemail });
  }

  // potential meetings add
  PotentialMeetingsAdd() {
    var PushData = this.state.ContactData
    PushData["Pagename"] = "Contact"
    history.push("/addpotentialmeeting", PushData);
  }

  // potential meetings edit
  PotentialMeetingsEdit(id) {
    const prid = this.state.Prospectids;
    history.push({ pathname: "/viewpotentialmeeting", state: { data: id, pid: prid, EmailData: this.state.Prospectemail, Pagename: "Contact" } });
  }

  // List edit
ListsAdd() {
    const cmid = this.state.Prospectids;
    // history.push({ pathname: "/addlist", state: { data: cmid, Pagename: "ViewContact" } });
    history.push({ pathname: "/addlist", state: {  pid: cmid, EmailData: this.state.Prospectemail, Pagename: "ViewContact" } });
  }

  // meetings edit
  MeetingsEdit(id) {
    history.push({ pathname: "/viewbookedmeeting", state: { data: id, Pagename: "Meeting", pid: this.state.Prospectids, EmailData: this.state.Prospectemail } });
  }

  // List edit
  ListsEdit(id) {
    history.push({ pathname: "/editlist", state: { data: id, Pagename: "ViewContact", pid: this.state.Prospectids, EmailData: this.state.Prospectemail } });
  }


  // Back button contacts
  ContactBackBtn() {
    if (this.state.ProspectPageName == "EditCampaign") {
      history.push({ pathname: "/editcampaigns", state: { data: this.props.location.state.CampaigId,PageName:"ViewContact" } })
    } else if (this.state.ProspectPageName == "Potential") {
      history.push("/potentialmeeting");
    } else if (this.state.ProspectPageName == "PotentialLinkedin") {
      history.push("/linkedinfollowup");
    } else if (this.state.ProspectPageName == "ColdCallingContacts") {
      history.push("/ColdCallingContact");
    }else if (this.state.ProspectPageName == "BookedMeeting") {
      history.push("/bookedmeetings");
    } else if (this.state.ProspectPageName== "OpportunitiesMeeting") {
      history.push("/opportunities");
    }else if (this.state.ProspectPageName == "RescheduleMeeting") {
      history.push("/reschedules");
    }else if (this.state.ProspectPageName == "clientPotenial") {
      history.push("/clientpotentialmeetings");
    }else if (this.state.ProspectPageName == "ClientRescheduleMeeting") {
      history.push("/clientrescheduling");
    }else if (this.state.ProspectPageName === "kpiMeeting") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 2,
        },
      });
    }else if (this.state.ProspectPageName === "kpiEmailResponder") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 5,
        },
      });
    } else if (this.state.ProspectPageName === "Kpi7DaysPotential") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 1,
        },
      });
    }  else if (this.state.ProspectPageName === "kpiMeeting7days") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 1,
        },
      });
    } else if (this.state.ProspectPageName == "ClientBookedMeeting") {
      history.push("/clientmeetings");
    } else if (this.state.ProspectPageName == "ClientLinkedinFollow") {
      history.push("/clientlinkedinfollowUp");
    }else if (this.state.ProspectPageName == "AccountContact"){
      history.push({ pathname: "/viewaccount", state: { data: this.props.location.state.AccountData } });
    }
    else {
      history.push("/contacts");
    }

  }

  //  campaign history change display rows
  ChangeRowSelectedCampaignHistory = (Event) => {
    this.setState({ IsLoading: true });
    var Details = GetUserDetails();
    this.setState({ CAMRowsPerPage: Number(Event.target.value), CAMPage: 1 });
    var SearchedVal = document.getElementById("searchcampaigns").value;
    const id = this.state.Prospectids;
    var CampaignHistoryDataStr = {
      Page: 1,
      ProspectID: this.state.Prospectids,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.CAMSortField,
      SortBy: this.state.CAMSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Type: "User",
      Role: Details.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
      method: "POST",
      data: CampaignHistoryDataStr,
    }).then((result) => {
      this.setState({ CampaignHistoryData: result.data?.PageData });
      this.setState({ CAMRows: result.data?.PageData });
      this.setState({ CAMRlen: result.data?.TotalCount });
      this.setState({ CAMFlen: result.data?.TotalCount });
      this.setState({ CAMCountPage: result.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  //campaign history search for record campaign history
  RequestSearchCampaignHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchcampaigns").value;
      this.setState({ CAMSearch: SearchedVal });
      const id = this.state.Prospectids;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  //  campaign hitory change page
  HandleChangePageCampaignHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    this.setState({ CAMPage: NewPage });
    if (NewPage == this.state.CAMPage) {
      this.setState({ IsLoading: true });
      this.setState({ CAMPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      const id = this.state.Prospectids;
      this.setState({ CAMPage: NewPage });
      var SearchedVal = document.getElementById("searchcampaigns").value;
      var CampaignHistoryDataStr = {
        Page: NewPage,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // campaign history sorting
  CampaignHistorySortData = (Field) => {
    var Details = GetUserDetails();
    //const id = this.props.location.state;
    this.setState({ id: this.state.Prospectids });
    var searchedVal = document.getElementById("searchcampaigns").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ CAMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.CAMSortedBy == 1) {
        SortBy = -1;
        this.setState({ CAMSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ CAMSortedBy: 1 });
      }
      this.setState({ CAMSortField: Field });
      const id = this.state.Prospectids;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: searchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ CAMSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.CAMSortedBy == 1) {
        SortBy = -1;
        this.setState({ CAMSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ CAMSortedBy: 1 });
      }
      this.setState({ CAMSortField: Field });
      const id = this.state.Prospectids;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: searchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Campaign history get
  async CampaignHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID, Role } = GetUserDetails();
      // const { data: id } = this.props.location.state;
  
      const CampaignHistoryDataStr = {
        Page: this.state.CAMPage,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: this.state.CAMSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        Type: "User",
        Role: Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/CampaignsHistoryGet`,
        CampaignHistoryDataStr
      );
  
      this.setState({
        CampaignHistoryData: result.data?.PageData,
        CAMRows: result.data?.PageData,
        CAMRlen: result.data?.TotalCount,
        CAMFlen: result.data?.TotalCount,
        CAMCountPage: result.data?.PageCount,
        // IsLoading: false,
        LastEmaiCampaign:result.data?.PageData[0]?.CampaignName
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  

  //get sort field data
  NoteSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("SearchNote").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ NoteSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.NoteSortedBy == 1) {
        SortBy = -1;
        this.setState({ NoteSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ NoteSortedBy: 1 });
      }
      this.setState({ NoteSortField: Field });
      //const Email = this.props.location.state.EmailData;
     // const id = this.props.location.state.data;
      var InputParameter = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        Email: this.state.Prospectemail,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.NoteSearch,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ NoteSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.NoteSortedBy == 1) {
        SortBy = -1;
        this.setState({ NoteSortedBy: -1 });

      } else {
        SortBy = 1;
        this.setState({ NoteSortedBy: 1 });
      }

      this.setState({ NoteSortField: Field });
      //const Email = this.props.location.state.EmailData;
     // const id = this.props.location.state.data;
      var InputParameter = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.NoteSearch,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // contact status history sort get data
  ContactStatusHistorySortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById(
      "HSearch"
    ).value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      // SerchBox = false;
      this.setState({ Sflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.HSortedBy == 1) {
        SortBy = -1;
        this.setState({ HSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ HSortedBy: 1 });
      }
      this.setState({ HSortField: Field });
    //  const id = this.props.location.state.data;
      this.setState({ id: this.state.Prospectids });

      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: this.state.UserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
        Role: this.state.Role
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      // SerchBox = true;
      this.setState({ Sflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.HSortedBy == 1) {
        SortBy = -1;
        this.setState({ HSortedBy: -1 });

      } else {
        SortBy = 1;

        this.setState({ HSortedBy: 1 });
      }
      this.setState({ HSortField: Field });
     // const id = this.props.location.state.data;
      this.setState({ id: this.state.Prospectids });
      var Details = GetUserDetails();
      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: this.state.UserID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // lists sort get data
  ListsSortData = (Field) => {
    var Details = GetUserDetails();
    //const id = this.props.location.state;
    this.setState({ id: this.state.Prospectids });
    var searchedVal = document.getElementById("searchlist").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ SflagL: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByL == 1) {
        SortBy = -1;
        this.setState({ SortedByL: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByL: 1 });
      }
      this.setState({ SortFieldL: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ SflagL: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedByL == 1) {
        SortBy = -1;
        this.setState({ SortedByL: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedByL: 1 });
      }
      this.setState({ SortFieldL: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Potential Meetings sort get data
  PotentialMeetingSortData = (Field) => {
    var Details = GetUserDetails();
//const Email = this.props.location.state.EmailData;
    var searchedVal = document.getElementById("searchPotentialMeeting").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      // SerchBox = false;
      this.setState({ PMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.PMSortedBy == 1) {
        SortBy = -1;
        this.setState({ PMSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ PMSortedBy: 1 });
      }
      this.setState({ PMSortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        ClientID: Details.ClientID,
        Type: "User",
        Email: this.state.Prospectemail,
        Search: this.state.PMSearch,
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
     // const Email = this.props.location.state.EmailData;
      // SerchBox = true;
      this.setState({ PMSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.PMSortedBy == 1) {
        SortBy = -1;
        this.setState({ PMSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ PMSortedBy: 1 });
      }
      this.setState({ PMSortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.PMSearch,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  //  Meetings sort get data
  MeetingSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchMeeting").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ MSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.MSortedBy == 1) {
        SortBy = -1;
        this.setState({ MSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ MSortedBy: 1 });
      }
      this.setState({ MSortField: Field });
      //const Email = this.props.location.state.EmailData;
      var InputParameter = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });

      this.setState({ MSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.MSortedBy == 1) {
        SortBy = -1;
        this.setState({ MSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ MSortedBy: 1 });
      }
      this.setState({ MSortField: Field });
      var InputParameter = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Call history sort get data
  CallHistorySortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.state.Prospectids;
    var searchedVal = document.getElementById("searchcallhistory").value;

    if (searchedVal == "") {
      this.setState({ IsLoading: true });

      this.setState({ CSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.CSortedBy == 1) {
        SortBy = -1;
        this.setState({ CSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ CSortedBy: 1 });
      }
      this.setState({ CSortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        Type: "User",
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      const id = this.state.Prospectids;
      this.setState({ CSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.CSortedBy == 1) {
        SortBy = -1;
        this.setState({ CSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ CSortedBy: 1 });
      }
      this.setState({ CSortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID: Details.ClientID,
        ProspectID: this.state.Prospectids,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Step History Sort Data
  StepHistorySortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchMeeting").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ STHSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.STHSortedBy == 1) {
        SortBy = -1;
        this.setState({ STHSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ STHSortedBy: 1 });
      }
      this.setState({ STHSortField: Field });
      //const Email = this.props.location.state.EmailData;
      var InputParameter = {
        Page: this.state.STHPage,
        RowsPerPage: this.state.STHRowsPerPage,
        ProspectID: this.state.Prospectids,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.STHSearch,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const StepHistoryData = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/StepHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      StepHistoryData.then((result) => {
        this.setState({
          STHData: result.data?.PageData,
          STHRows: result.data?.PageData,
          STHRlen: result.data?.TotalCount,
          STHFlen: result.data?.TotalCount,
          STHCountPage: result.data?.PageCount,
          IsLoading: false,
        });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });

      this.setState({ STHSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.STHSortedBy == 1) {
        SortBy = -1;
        this.setState({ STHSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ STHSortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var SortInputParameter = {
        Page: this.state.STHPage,
        RowsPerPage: this.state.STHRowsPerPage,
        ProspectID: this.state.Prospectids,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.STHSearch,
        ClientID: Details.ClientID,
        Email: this.state.Prospectemail,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const StepHistoryData = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/StepHistoryGet",
        method: "POST",
        data: SortInputParameter,
      });
      StepHistoryData.then((result) => {
        this.setState({
          STHData: result.data?.PageData,
          STHRows: result.data?.PageData,
          STHRlen: result.data?.TotalCount,
          STHFlen: result.data?.TotalCount,
          STHCountPage: result.data?.PageCount,
          IsLoading: false,
        });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }
  // show
  ShowPopupNoteHandler = () => {
    this.setState({ ShowNotePopup: true })
  }

  // hide
  HidePopupNoteHandler = () => {
    this.setState({
      ShowNotePopup: false
    })
  }
  // handle change for send email;
  handleChange(field, e) {
    const Errors = { ...this.state.Errors };
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    console.log("e.target.value",e.target.value)
    if(e.target.value == ""){
      Errors["email_account"] = `Please enter Account`;
      this.setState({SendEmailAccount:null,SendEmailRefreshToken:""})
    }else{
      Errors["email_account"] = null;
      var InputParameter = {
        AccountID: e.target.value
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/AccountDetailsGetById",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        this.setState({
          AccountData: res.data.AccountDataDetails[0]?.RefreshToken,
          SendEmailRefreshToken:res.data.AccountDataDetails[0]?.RefreshToken,
          SendEmailAccount:res.data.AccountDataDetails[0]?.SMTPFromEmail
  
        })
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      })
    }
    this.setState({ Errors });
  }
  // checking subject and body 
  handleInputChange = (fieldName, value) => {

    const Errors = { ...this.state.Errors };
    if (value.length < 1) {
      Errors[fieldName] = `Please enter ${fieldName}`;
    } else {
      Errors[fieldName] = null;
    }
    this.setState({ Errors });
  }


  SendEmailContact = async () => {
    this.setState({IsLoading:true})
    var FromEmail = this.state.SendEmailAccount;
    var RefresToken = this.state.SendEmailRefreshToken;
    var ContactEmail = this.state.Email;
    var Subject = document.getElementById("Subject").value.trim();
    var Body = document.getElementById("Body").value.trim();
    
    // Check individual fields and set errors if necessary
    var Errors = {};
    if (!FromEmail) {
      Errors["email_account"] = "Please select account";
      this.setState({IsLoading:false})
    }
    if (!Subject) {
      Errors["subject"] = "Please enter subject";
      this.setState({IsLoading:false})
    }
    if (!Body) {
      Errors["body"] = "Please enter body";
      this.setState({IsLoading:false})
    }
  
    // If there are any errors, update state and exit
    if (Object.keys(Errors).length > 0) {
      this.setState({ Errors: Errors });
      return;
    }

    this.setState({ Errors: { subject: null }, IsLoading: true });
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(ContactEmail);
  
    if (Emailflg && FromEmail && Subject && Body) {
      var str_mail = {
        FromEmail: FromEmail,
        ToEmail: ContactEmail,
        RefresToken: RefresToken,
        Html: Body,
        Subject: Subject,
        PageType: "ContactSend"
      };
      // Uncomment the following Axios request and handle the response accordingly
      try {
        const response = await Axios.post(CommonConstants.MOL_APIURL + "/contacts/ContactEmailSend", str_mail);
        this.setState({
          SendEmailPopup: false,
          SendEmailAccount:null,
          SendEmailRefreshToken:"",
          IsLoading: false,
          Errors: {
            body: null,
            subject: null,
            email_account: null
          }
        });
        toast.success("Email Sent Successfully!");
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
        // Handle error as needed
      }
    } else {
      this.setState({
        Errors: {
          email_account: 'Please enter your email',
        },
        IsLoading: false
      });
    }
  };
  
  
  

  // Edit campaign history 
  EditCampaignHistory(id) {
    history.push({ pathname: "/editcampaigns", state: { data: id, PageName: "ViewContact", dataprospect: this.state.ProspectIdForNote, EmailData: this.state.Prospectemail } });
  }

  toggleMeeting = (meetingID) => {
    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [meetingID]: !prevState.Open[meetingID],
      },
    }));
  };

  toggleMeeting1 = (eventID) => {
    
    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [eventID]: !prevState.Open[eventID],
      },
    }));
  };


  ViewPage = (ID) => {
    history.push({ pathname: "/viewaccount", state: { data: ID ,PageName: "ViewContact", dataprospect: this.state.ProspectIdForNote, EmailData: this.state.Prospectemail} });
  };
  ChangeRowSelectedCallNote = (event) => {
    var Details = GetUserDetails();
    this.setState({ CallNoteRowsPerPage: Number(event.target.value), CallNotePage: 1 });
    this.setState({ IsLoading: true });
    let SearchCallNote = document.getElementById("SearchCallNote").value;
    var ProspectNoteData = {
      Page: 1,
      ClientID: Details.ClientID,
      ProspectID: this.state.Prospectids,
      Email: this.state.Prospectemail,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: this.state.NoteSortField,
      SortBy: this.state.NoteSortedBy,
      Search: SearchCallNote,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CallNoteGet",
      method: "POST",
      data: ProspectNoteData,
    }).then((responsenote) => {
      this.setState({
        CallNoteData: responsenote.data?.PageData,
        CallNoteRows: responsenote.data?.PageData,
        CallNoteRlen: responsenote.data?.TotalCount,
        CallNoteFlen: responsenote.data?.TotalCount,
        CallNoteCountPage: responsenote.data?.PageCount,
        IsLoading: false,
      });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    });
  };
  RequestSeachCallNoteList(Event) {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("SearchCallNote").value;
      this.setState({ SearchL: SearchedVal });
      var CallNoteData = {
        Page: 1,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: this.state.Prospectids,
        Email: this.state.Prospectemail,
        RowsPerPage: this.state.CallNoteRowsPerPage,
        Sort: true,
        Field: this.state.CallNoteSortField,
        SortBy: this.state.CallNoteSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallNoteGet",
        method: "POST",
        data: CallNoteData,
      }).then((result1) => {

        this.setState({ CallNoteData: result1.data?.PageData });
        this.setState({ CallNoteRows: result1.data?.PageData });
        this.setState({ CallNoteRlen: result1.data?.TotalCount });
        this.setState({ CallNoteFlen: result1.data?.TotalCount });
        this.setState({ CallNoteCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }
 

  render() {
    return (
      <>
        {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        {/* EDIT AND NOTES PAGE  */}

        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="my-3">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header lg-headertitle py-3">
                    Edit Contact
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 pb-4 text-right"></div>
              </div>
              <div className="bg-white px-3">
                <div className="row borderbtnm ">
                  <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className="float-left addpotential_header xs-headertitle mb-0">Contact Info</h3>
                  </div>
                  <div className="col-md-8 col-sm-8 text-right">
                    <div className="listing-li float-right padb-15 iconbox-pad edited">
                      <ul>
                        <li>
                          <a className="btn btngroup m-btn" onClick={() => { this.setState({ SendEmailPopup: true }) }}>
                            <i class="flaticon-mail la-icon-font-size-13"></i>
                            <span>Send Email</span>
                          </a>
                        </li>
                        <Popup open={this.state.SendEmailPopup}>

                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup largerPopup900">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-3 px-3">
                                  <h6 className="mb-0">Send Email</h6>
                                </div>
                                <div className="modal-body p-5">

                                  <div class="row mb-3">
                                    <label class="col-lg-2 col-form-label text-left">
                                      Account
                                    </label>
                                    <div class="col-lg-4">
                                      <select
                                        className="form-control  m-input"
                                        name="email_account"
                                        id="email_account"
                                        onChange={this.handleChange.bind(this, "email_account")}
                                      >
                                        <option value="" >-Select-</option>
                                        {
                                          this.state.EmailDataSend?.map((emitem) => {
                                            return (<option key={emitem._id}  value={emitem._id}>
                                              {emitem.SMTPFromEmail
                                              }
                                            </option>)
                                          })
                                        }
                                      </select>
                                      <span className="email_account" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                    {this.state.Errors["email_account"]}
                                  </span>
                                    </div>
                                    <div class="col-lg-6 d-flex">
                                      <label>Contact</label>
                                      {this.state.ContactData?.map((item) => {
                                        return (<span class="ml-3" id="contactemail">{item.Email}</span>)
                                      })}
                                    </div>

                                  </div>
                                  <div>

                                    <div class="row mb-3">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Subject
                                      </label>
                                      <div class="col-lg-8">
                                        <input
                                          class="form-control m-input"
                                          data-val="true"
                                          data-val-remote="Title already exist."
                                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                          data-val-required="Please enter title"
                                          id="Subject"
                                          name="Subject"
                                        
                                          onChange={(e) => this.handleInputChange("subject", e.target.value)}

                                          type="text"
                                        />
                                        <span className="sendmail-errors" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                          {this.state.Errors["subject"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="row">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Body
                                      </label>
                                      <div class="col-lg-8">
                                        <textarea
                                          class="form-control m-input"
                                          data-val="true"
                                          id="Body"
                                          name="Body"
                                          
                                          type="textarea"
                                          onChange={(e) => this.handleInputChange("body", e.target.value)}
                                        >
                                        </textarea>
                                        <span className="sendmail-errors" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                          {this.state.Errors["body"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-content border-top py-4 px-3 text-right">
                                  <div class="row">
                                    <div class="col-lg-12 pull-right">
                                      <a
                                        id="backtolist"
                                        class="btn btn-secondary mr-2"
                                        onClick={() => {
                                        this.setState({
                                          SendEmailPopup: false,
                                          SendEmailAccount:null,
                                          SendEmailRefreshToken:"",
                                          Errors: {
                                            body: null,
                                            subject: null,
                                            email_account:null
                                          }
                                        });
                                      }}
                                      >
                                        Cancel
                                      </a>
                                      <button
                                        id="submit"
                                        class="btn btn-primary btn-lightgreen mr-1"
                                        href="javascript:void(0);"
                                        value="Save"
                                        onClick={() => this.SendEmailContact()}
                                      >
                                        <i class="la la-save"></i> Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Popup>
                        <li>
                          <a
                            onClick={() => {
                              this.editspage(this.state.ProspectIdForNote);
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="fa fa-edit la-icon-font-size-13"></i>
                            <span>Edit</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.DeleteBtnContact(this.state.ProspectIdForNote);
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="flaticon-delete-1 la-icon-font-size-13"></i>
                            <span>Delete</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.ContactAddToBlackList(this.state.Email);
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="fa la-icon-font-size-13 fa-ban la-icon-font-size-13"></i>
                            <span>Add to Blacklist</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.ContactBackBtn();
                            }}
                            className="btn btngroup m-btn borderbtn"
                          >
                            <i class="la la-arrow-circle-left la-icon-font-size-13"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              
                {this.state.ContactData?.map((value) => (
                  <div className="row py-3 px-2">
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols  d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Name
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext"><a
                      onClick={() => {
                        this.ViewPage(value?.Accountname?.SalesReplyAccountID);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          ><u>{value?.Accountname?.SalesReplyAccountName === null || "" || undefined ? "" : value?.Accountname?.SalesReplyAccountName}</u></a></label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2"></div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols  d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          First Name :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.FirstName}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Last Name :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.LastName}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Email :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Email}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Title :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Title}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Company}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Name Long :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CompanyNameLong}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Website :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext"><a   href={`http://${value?.Website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                          ><u>{value?.Website}</u></a></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          LinkedIn URL :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.LinkedInURL}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Industry :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Industry}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Address 1 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Address1}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Address 2 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Address2}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          City :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.City}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          State :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.State}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Zip :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Zip}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Country :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.Country}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Contact Category :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ContactCategory}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Category :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.AccountCategory}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Contact Source :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {this.state?.ContactSource}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Contact Owner :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {this.state?.ContactOwner}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Client POC :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {this.state?.ClientPoc}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Reason Unqualified :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {this.state?.ReasonUnqualified}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Mobile Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.MobilePhone}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Direct Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.DirectPhone}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Direct Phone Ext :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.DirectPhoneExt}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.CompanyPhone}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Contact Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ProspectStatusDetails[0]?.Status == "" || null || undefined ? "" : value?.ProspectStatusDetails[0]?.Status}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Revenue :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                          {
        value?.Accountname?.CompanyRevenue === null || value?.Accountname?.CompanyRevenue === undefined || value?.Accountname?.CompanyRevenue === "" 
        ? 0 
        : value?.Accountname?.CompanyRevenue
      }
                            {/* {value?.Accountname?.CompanyRevenue} */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Employee Count :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                          {
        value?.Accountname?.CompanyEmployeeCount === null || value?.Accountname?.CompanyEmployeeCount === undefined || value?.Accountname?.CompanyEmployeeCount === "" 
        ? 0 
        : value?.Accountname?.CompanyEmployeeCount
      }
                            {/* {value?.Accountname?.CompanyEmployeeCount} */}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Tag :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Tagname && value.Tagname.length > 0 ? value.Tagname
        .filter(tag => tag.TagName) // Ensure TagName exists
        .map(tag => tag.TagName)
        .join(", ") 
        : ""}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Follow Up Date :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {" "}
                            {value?.FollowUpDate ?
  moment(new Date(value?.FollowUpDate).toDateString()).format("MM/DD/YYYY") :
  ""
}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                    <div className="row max-cols d-flex align-items-center">
  <label className="col-lg-4 view-lable-meet potential_text">
    Sales Development Reps :
  </label>
{
  this.state.CollCalllerNameArray?.map((item) => {
    if(item?._id === value?.ColdCallersID){
      return   <div className="col-lg-7">
    {/* Display the filtered result */}
    <label className="labeltext">{item?.ColdCallersName === null || undefined ? "" : item?.ColdCallersName}{" "}{item?.LastName === null || undefined ? "" : item?.LastName}</label>
  </div>
}
  })
}

</div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Sales Rep :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">{value?.SalesRep}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Other Phone 1 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.OtherPhone1}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Other Phone 2 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.OtherPhone2}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Follow Up Notes:
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.FollowUpNotes}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2"></div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Account ID :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ExternalAccountID}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Contact ID :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ExternalContactID}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 1 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ExternalField1}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 2 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ExternalField2}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 3 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ExternalField3}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Zoom ID :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.ZoomID}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Best Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.BestPhone}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Best Phone Status:
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.BestPhoneStatus}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone1}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone1Status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone2 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone2}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone2 Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone2Status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone3 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone3}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone3 Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone3Status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone4 :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone4}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Phone4 Status :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.Phone4Status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        External ID :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.PRLLeadID}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 py-1 px-2"></div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Do Not Call Contact :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.IsDoNotCallContact == true ? "Yes" : "No"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 py-1 px-2">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Honey Score :
                        </label>
                        <div className="col-lg-7">
                          <label className="labeltext">
                            {value?.HoneyScore}
                          </label>
                        </div>
                      </div>
                    </div>

                      {this.state.ContactCustomFieldArray.length > 0 ? <div className="col-lg-12 pt-4 padd-0 d-flex align-items-center p-1">
                            <h3 className=" float-left addpotential_header xs-headertitle">
                              Custom Fields
                            </h3>
                          </div> : null

                      }
                    
                    {this.state.ContactCustomFieldArray?.map((value1, index) => {
          
                      return (
                        <>
                
                          <div className="col-lg-6 py-1 px-2">
                            <div
                              className="row max-cols d-flex align-items-center"
                              key={index}
                            >
                              <label className="col-lg-4">
                                {value1?.FieldName}:
                              </label>
                              {this.state.ProspectCustomFieldById?.map((val) => {
                                if (value1._id === val.CustomFieldID) {
                                  return (
                                    <div className="col-lg-7">
                                      <label>{val?.FieldValue}</label>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>



            <div className="mt-3 bg-white">
              <div className="row border-bottom p-3 py-4 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left addpotential_header xs-headertitle mb-0">
                    Contact Status{" "}
                  </h3>
                </div>
              </div>
              {this.state.ContactData?.map((value) => (
                <div className="row px-3 pt-2">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Created Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4 view-lable-meet potential_text">
              

                          {
                          value?.CreatedDate == null ? "" : moment.utc(
                              new Date(value?.CreatedDate)
                            ).format("MM/DD/YYYY")
                          }
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Validation Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">
                        {
                          value?.LastValidationDate == null ? "" : moment.utc(
                              new Date(value?.LastValidationDate)
                            ).format("MM/DD/YYYY")
                          }
           
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.ContactStatusDData?.map((valstats) => (
                    <>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Send Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats?.TotalSentDate?.MailSentDate != null
                                ? valstats?.TotalSentDate?.MailSentDate : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Open Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastEmailOpentDate?.OpenedDate != null
                                ? valstats?.LastEmailOpentDate?.OpenedDate: ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Response Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastResponseDate?.RepliedDate != null
                                ? valstats?.LastResponseDate?.RepliedDate : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Direct Mail Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats.TotalSentDate?.DateOfSendMail != null
                                ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.DateOfSendMail
                                  ).toDateString()
                                ).format("MM/DD/YYYY")
                                : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Letter Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Status Change :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{this.state.LastStatusChangeDate}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Is Opted Out :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {value?.IsOptedOut == true ? "Yes" : "No"}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Is Blacklisted :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {value?.IsContatcBlackListed == true || value?.IsDomainBlackListed == true || value?.IsGlobalCountryBlacklist == true ||  value?.IsGlobalDomainBlacklist == true ||  value?.IsDefaultCountryBlacklist == true  ||  value?.IsGlobalContactBlacklist == true ? "Yes" : "No"}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Blacklist Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{this.state.BlacklistedDate}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            On Global Bounce List :
                          </label>
                          <div className="col-lg-7">
                            {value?.IsGlobalBounce == true ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Total Email Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                 
                            {valstats?.LastEmailSentMailDate === null ? 0 : valstats?.LastEmailSentMailDate}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                          Total Touches :
                        
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastEmailSentMailDate === null ? 0 : valstats?.LastEmailSentMailDate}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Tracked Email Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats?.TrackedEmailCount === null ? 0 : valstats?.TrackedEmailCount}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Open Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.TotalIsOpenedCount === null ? 0 : valstats?.TotalIsOpenedCount}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Reply Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.ContactEmailSend?.
Replies === null && " " ? 0 : value?.ContactEmailSend?.
Replies}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Touches Before First Reply :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {this.state.TotalReplyCount === null ? 0 :this.state.TotalReplyCount}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Removed Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">0</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Soft Bounce Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.ContactEmailSend?.SoftBounce === null || " " ? 0 : value?.ContactEmailSend?.SoftBounce}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Hard Bounce Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.ContactEmailSend?.HardBounce === null || " " ? 0 : value?.ContactEmailSend?.HardBounce}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Letter Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Direct Mail Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Campaign :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {this.state.LastEmaiCampaign == "" || null || undefined ? "" : this.state.LastEmaiCampaign}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Step :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.CampaignDetails?.LastEmailStep === null ? 0 : value?.CampaignDetails?.LastEmailStep}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            View Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Call Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{value.CallCount  == null || undefined ? 0 : value.CallCount}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Disposition Status :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">{value.LastCallResult}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Call Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">
                          {this.state.LastCallDate == "" || this.state.LastCallDate == null || this.state.LastCallDate == undefined ? "" : moment.utc(
                            new Date(this.state.LastCallDate)
                          ).format("MM/DD/YYYY")}
                          {""}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>


            {/*Call notes table */}

            <div className="mt-3 bg-white">
              <div className="row p-3 py-4 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                    Call Notes
                  </h3>
                </div>
              </div>
              <div className="row px-3 pt-4">
              <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      onChange={this.ChangeRowSelectedCallNote}
                      className="form-control form-control-sm"
                      value={this.state.CallNoteRowsPerPage}
                    >
                    {CommonConstants.show_rows && CommonConstants.show_rows.map((value) => (
  <option key={value} value={value}>{value}</option>
))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="SearchCallNote"
                      onKeyPress={(event) => this.RequestSeachCallNoteList(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Call Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
              {this.state.CallNoteData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={1} align="center">No data available in table</TableCell>
                </TableRow>
              ) : (
                this.state.CallNoteData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.CallNotes}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.CallNoteSflag ? (
                <div class="row px-3  pb-2">
                  <Pagination
                    component="div"
                    count={this.state.CallNoteCountPage}
                    // onChange={this.CallNoteHandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CallNoteRlen == 0
                        ? 0
                        : (this.state.CallNotePage - 1) * this.state.CallNoteRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.CallNotePage * this.state.CallNoteRowsPerPage > this.state.CallNoteRlen
                        ? this.state.CallNoteRlen
                        : this.state.CallNotePage * this.state.CallNoteRowsPerPage}{" "}
                      of {this.state.CallNoteRlen} entries (filtered from{" "}
                      {this.state.CallNoteFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3  pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CallNoteRlen == 0
                        ? 0
                        : (this.state.CallNotePage - 1) * this.state.CallNoteRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.CallNotePage * this.state.CallNoteRowsPerPage > this.state.CallNoteRlen
                        ? this.state.CallNoteRlen
                        : this.state.CallNotePage * this.state.CallNoteRowsPerPage}{" "}
                      of {this.state.CallNoteRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CallNoteCountPage}
                      // onChange={this.CallNoteHandleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>


            {/* notes table */}

            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">Notes</h3>
                </div>
                <div className="col text-right">
                  <button onClick={this.handleClick} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                  <Popup open={this.state.ShowNotePopup}>
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10 text-left">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="TitlePNote"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.HandleChangePNotesAdd.bind(
                                    this,
                                    "TitlePNote"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["TitlePNote"]}
                                </span>
                                {this.state.TitleAvailableNotes && (
                                  <span style={{ color: "red" }}>
                                    Title already exist.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10 text-left">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="NotesPNote"
                                  name="NotesPNote"
                                  placeholder="Enter note"
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["NotesPNote"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right pt-0">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={this.handleClick}
                                >
                                  cancel
                                </a>
                                <button
                                  disabled={this.state.ProspectNoteBtnDisabled}
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.ProspectNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Popup>
                </div>
              </div>

              <div className="row px-3 pt-4">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      onChange={this.ChangeRowSelected}
                      className="form-control form-control-sm"
                      value={this.state.NoteRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="SearchNote"
                      onKeyPress={(event) => this.RequestSearch(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell onClick={() => {
                              this.NoteSortData("Title");
                            this.setState({  NoteSortedColumn : "Title"})
                            }}>
                      
                            Title
                            <span className="shorting">
                         <ArrowUpward className={this.state.NoteSortedBy === 1 && this.state.NoteSortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={this.state.NoteSortedBy === -1 && this.state.NoteSortedColumn === "Title"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.NoteSortData("Note");
                            this.setState({  NoteSortedColumn : "Note"})
                            }}>
                       
                            Notes
                            <span className="shorting">
                         <ArrowUpward className={this.state.NoteSortedBy === 1 && this.state.NoteSortedColumn === "Note" ? "active" : null} />
                          <ArrowDownward  className={this.state.NoteSortedBy === -1 && this.state.NoteSortedColumn === "Note"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.NoteSortData("Type");
                            this.setState({  NoteSortedColumn : "Type"})
                            }}>

                            Type
                            <span className="shorting">
                         <ArrowUpward className={this.state.NoteSortedBy === 1 && this.state.NoteSortedColumn === "Type" ? "active" : null} />
                          <ArrowDownward  className={this.state.NoteSortedBy === -1 && this.state.NoteSortedColumn === "Type"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.NoteSortData("CreatedDate");
                            this.setState({  NoteSortedColumn : "CreatedDate"})
                            }}>
                        
                            Created Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.NoteSortedBy === 1 && this.state.NoteSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.NoteSortedBy === -1 && this.state.NoteSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
               </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.NoteRows?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.NoteRows?.map((row) => (
                          <TableRow>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.Note}</TableCell>
                            <TableCell>{row?.PageName}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  this.ProspectNoteEditBtn(row._id, row.PageName);
                                }}
                              >
                                <Popup
                                  trigger={
                                    <i class="la flaticon-edit-1 edit-icon"></i>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-3 px-3">
                                            <h4 className="mb-0">Note</h4>
                                          </div>
                                          <div className="modal-body p-5">
                                            <div class="row mb-3">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Title
                                              </label>
                                              <div class="col-lg-10">
                                                <input
                                                  class="form-control m-input"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                  data-val-required="Please enter title"
                                                  id="TitlePEditNote"
                                                  name="TitlePEditNote"
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onBlur={this.HandleChangePNotesEdit.bind(
                                                    this,
                                                    "TitlePEditNote"
                                                  )}

                                                />
                                                <span style={{ color: "red" }}>
                                                  {
                                                    this.state.Errors1[
                                                    "TitlePEditNote"
                                                    ]
                                                  }
                                                </span>
                                                {this.state.TitleAvailableNotes && (
                                                  <span style={{ color: "red" }}>
                                                    Title already exist.
                                                  </span>
                                                )}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Note
                                              </label>
                                              <div class="col-lg-10">
                                                <textarea
                                                  class="form-control m-input minheight390"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-required="Please enter title"
                                                  id="NotesEditPNotes"
                                                  name="NotesEditPNotes"
                                                  placeholder="Enter note"
                                                  type="text"
                                                  value={
                                                    this.state.Fields[
                                                    "NotesEditPNotes"
                                                    ]
                                                  }
                                                >
                                                  {" "}
                                                </textarea>
                                                <span style={{ color: "red" }}>
                                                  {
                                                    this.state.Errors1[
                                                    "NotesEditPNotes"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-content bordernone text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    close(); this.Handlevalidationfornotesedit();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <button
                                                  id="submitupdate"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  href="javascript:void(0);"
                                                  value="Save"

                                                  onClick={(e) => {
                                                    this.ProspectNoteUpdate(e, () => {
                                                      close(); // Call the close function when the update is successful
                                                    });
                                                  }}
                                                >
                                                  <i class="la la-save"></i> Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </a>
                              <a
                                className="btn-eyesicon"
                                onClick={() => {
                                  this.ProspectNoteDelete(row._id, row.PageName);
                                }}
                              >
                                <i class="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.NoteSflag ? (
                <div class="row px-3  pb-2">
                  <Pagination
                    component="div"
                    count={this.state.NoteCountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.NoteRlen == 0
                        ? 0
                        : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                      {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                        ? this.state.NoteRlen
                        : this.state.NotePage * this.state.NoteRowsPerPage} of {this.state.NoteRlen}{" "} entries (filtered from {this.state.NoteFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3  pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.NoteRlen == 0
                        ? 0
                        : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                      {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                        ? this.state.NoteRlen
                        : this.state.NotePage * this.state.NoteRowsPerPage}
                      of {this.state.NoteRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.NoteCountPage}
                      onChange={this.HandleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>


          {/* Potential Meeting Table */}
          <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                  Potential Meetings
                  </h3>
                </div>

                <div className="col text-right">
                  <button onClick={() => { this.PotentialMeetingsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row px-3 pt-4">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedPotentialMeeting}
                      value={this.state.PMRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchPotentialMeeting"
                      onKeyPress={(event) => this.RequestSearchPotentialMeeting(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell   onClick={() => {
                              this.PotentialMeetingSortData("FirstName");
                              this.setState({ PMSortedColumn : "FirstName"})
                            }}>
                   
                            Contact Name
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "FirstName" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "FirstName"  ? "active" : null} />
                          </span>
                 
                        </TableCell>
                        <TableCell          onClick={() => {
                              this.PotentialMeetingSortData("Email");
                              this.setState({ PMSortedColumn : "Email"})
                            }}>
              
                            Email
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "Email" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "Email"  ? "active" : null} />
                          </span>
                     
                        </TableCell>
                        <TableCell               onClick={() => {
                              this.PotentialMeetingSortData("Company");
                              this.setState({ PMSortedColumn : "Company"})
                            }}>
           
                            Company
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "Company" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "Company"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.PotentialMeetingSortData("Title");
                              this.setState({ PMSortedColumn : "Title"})
                            }}>

                            Title
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "Title"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.PotentialMeetingSortData("EngagamentStatusID");
                              this.setState({ PMSortedColumn : "EngagamentStatusID"})
                            }}>
               
                            Engagement Status
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "EngagamentStatusID" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "EngagamentStatusID"  ? "active" : null} />
                          </span>
                        
                        </TableCell>
                        <TableCell               onClick={() => {
                              this.PotentialMeetingSortData("MeetingSourceID");
                              this.setState({ PMSortedColumn : "MeetingSourceID"})
                            }}>
              
                            Meeting Sources
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "MeetingSourceID" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "MeetingSourceID"  ? "active" : null} />
                          </span>
                 
                        </TableCell>
                        <TableCell      onClick={() => {
                              this.PotentialMeetingSortData("MeetingOwnerID");
                              this.setState({ PMSortedColumn : "MeetingOwnerID"})
                            }}>
  
                            Owner
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "MeetingOwnerID" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "MeetingOwnerID"  ? "active" : null} />
                          </span>
        
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.PotentialMeetingSortData(
                                "ResponderEmailAccount"
                              );
                              this.setState({ PMSortedColumn : "ResponderEmailAccount"})
                            }}>
    
                            Reminder Email Account
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "ResponderEmailAccount" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "ResponderEmailAccount"  ? "active" : null} />
                          </span>
        
                        </TableCell>
                        <TableCell                   onClick={() => {
                              this.PotentialMeetingSortData("CreatedDate");
                              this.setState({ PMSortedColumn : "CreatedDate"})
                            }}>

                            Created Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.PMSortedBy === 1 && this.state.PMSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.PMSortedBy === -1 && this.state.PMSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.PotentialMeetingData &&
                        this.state.PotentialMeetingData?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.PotentialMeetingData &&
                        this.state?.PotentialMeetingData.map((row34) => (
                          <>

                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.ContactName}</TableCell>
                              <TableCell>{row34?.Email}</TableCell>
                              <TableCell>{row34?.Company}</TableCell>
                              <TableCell>{row34?.Title}</TableCell>
                              <TableCell>{row34?.EnguagementStatus}</TableCell>
                              <TableCell>{row34?.MeetingSource}</TableCell>
                              <TableCell>{row34?.Owner}</TableCell>
                              <TableCell>{row34?.ReminderEmailAccount}</TableCell>
                              <TableCell>
                                {moment(
                                  new Date(row34?.CreatedDate).toDateString()
                                ).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell>
                                <a className="btn-eyesicon"
                                  onClick={() => {
                                    this.PotentialMeetingsEdit(row34._id);
                                  }}
                                ><i class="la flaticon-edit-1 edit-icon"></i></a>
                              </TableCell>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      <Table size="small" aria-label="purchases">
                                        <TableHead></TableHead>
                                        {this.state.PMNotes.map((note) =>
                                          note?.PotentialMeetingID ==
                                            row34?._id ? (
                                            <TableRow>
                                              <TableCell><strong>{note?.Title}</strong></TableCell>
                                              <TableCell> <strong>{moment(
                                                new Date(note?.CreatedDate).toDateString()
                                              ).format("MM/DD/YYYY")}</strong>      </TableCell>
                                              <TableCell scope="row">
                                                {note.Note}
                                              </TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                            </TableRow>
                                          ) : null
                                        )}
                                        {!this.state?.PMNotes.some(
                                          (note) =>
                                            note?.PotentialMeetingID === row34?._id
                                        ) && (
                                            <TableRow>
                                              <TableCell colSpan={4}>
                                              No notes available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </Table>
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.PMSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.PMCountPage}
                    onChange={this.HandleChangePagePotentialMeeting}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.PMRlen == 0
                        ? 0
                        : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.PMPage * this.state.PMRowsPerPage >
                        this.state.PMRlen
                        ? this.state.PMRlen
                        : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                      of {this.state.PMRlen} entries (filtered from{" "}
                      {this.state.PMFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row pb-2 px-3">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.PMRlen == 0
                        ? 0
                        : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.PMRowsPerPage >
                        this.state.PMRlen
                        ? this.state.PMRlen
                        : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                      of {this.state.PMRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.PMCountPage}
                      onChange={this.HandleChangePagePotentialMeeting}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Meeting Table*/}

            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                    Meetings
                  </h3>
                </div>

                <div className="col text-right">
                  <button onClick={() => { this.MeetingsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedMeeting}
                      value={this.state.MRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchMeeting"
                      onKeyPress={(event) => this.RequestSearchMeeting(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell  onClick={() => {
                              this.MeetingSortData("FirstName");
                              this.setState({ MeetingSortedColumn : "FirstName"})

                            }}>
                            Contact Name
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "FirstName" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "FirstName"  ? "active" : null} />
                          </span>
                  
                        </TableCell>
                        <TableCell       onClick={() => {
                              this.MeetingSortData("Email");
                              this.setState({ MeetingSortedColumn : "Email"})

                            }}>
     
                            Email
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "Email" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "Email"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.MeetingSortData("Company");
                              this.setState({ MeetingSortedColumn : "Company"})
                            }}>
         
                            Company
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "Company" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "Company"  ? "active" : null} />
                          </span>
               
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.MeetingSortData("Title");
                              this.setState({ MeetingSortedColumn : "Title"})

                            }}>

                            Title
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "Title"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell      onClick={() => {
                              this.MeetingSortData("Status");
                              this.setState({ MeetingSortedColumn : "Status"})

                            }}>
                 
                            Meeting Status
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "Status" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "Status"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell                             onClick={() => {
                              this.MeetingSortData("MeetingSourceName");
                              this.setState({ MeetingSortedColumn : "MeetingSourceName"})

                            }}>
       
                            Meeting Sources
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "MeetingSourceName" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "MeetingSourceName"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell                  onClick={() => {
                              this.MeetingSortData("MeetingOwnerName");
                              this.setState({ MeetingSortedColumn : "MeetingOwnerName"})

                            }}>
    
                            Owner
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "MeetingOwnerName" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "MeetingOwnerName"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell   onClick={() => {
                              this.MeetingSortData("EventStartDt");
                              this.setState({ MeetingSortedColumn : "EventStartDt"})

                            }}>
 
                            Meeting Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "EventStartDt" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "EventStartDt"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.MeetingSortData("CreatedDt");
                              this.setState({ MeetingSortedColumn : "CreatedDt"})

                            }}>

                            Meeting Set Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.MSortedBy === 1 && this.state.MeetingSortedColumn === "CreatedDt" ? "active" : null} />
                          <ArrowDownward  className={this.state.MSortedBy === -1 && this.state.MeetingSortedColumn === "CreatedDt"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.MeetingData &&
                        this.state.MeetingData?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.MeetingData &&
                        this.state?.MeetingData.map((row34) => (
                          <>

                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting1(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.ContactName}</TableCell>
                            <TableCell>{row34?.Email}</TableCell>
                            <TableCell>{row34?.Company}</TableCell>
                            <TableCell>{row34?.Title}</TableCell>
                            <TableCell>{row34?.MeetingStatus}</TableCell>
                            <TableCell>{row34?.MeetingSource}</TableCell>
                            <TableCell>{row34?.MeetingOwner}</TableCell>
                            <TableCell>{row34?.MeetingDate != null ? moment(row34?.MeetingDate).format("MM/DD/YYYY hh:mm A"): ""}</TableCell>
                            <TableCell>{row34?.MeetingSetDate != null? moment(row34?.MeetingSetDate).format("MM/DD/YYYY"): ""}</TableCell>
                            <TableCell>
                              <a className="btn-eyesicon"
                                onClick={() => {
                                  this.MeetingsEdit(row34?._id);
                                }}
                              ><i class="la flaticon-edit-1 edit-icon"></i></a>
                            </TableCell>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      <Table size="small" aria-label="purchases">
                                        <TableHead></TableHead>
                                        {this.state.MNotes.map((note) =>
                                          note?.MeetingID ==
                                            row34?._id ? (
                                            <TableRow>
                                              <TableCell><strong>{note?.Title}</strong></TableCell>
                                              <TableCell> <strong>{moment(
                                                new Date(note?.CreatedDate).toDateString()
                                              ).format("MM/DD/YYYY")}</strong>      </TableCell>
                                              <TableCell scope="row">
                                                {note.Note}
                                              </TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                            </TableRow>
                                          ) : null
                                        )}
                                        {!this.state?.MNotes.some(
                                          (note) =>
                                            note?.MeetingID === row34?._id
                                        ) && (
                                            <TableRow>
                                              <TableCell colSpan={4}>
                                                No data available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </Table>
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.MSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.MCountPage}
                    onChange={this.HandleChangePageMeeting}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.MRlen == 0
                        ? 0
                        : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.MPage * this.state.MRowsPerPage >
                        this.state.MRlen
                        ? this.state.MRlen
                        : this.state.MPage * this.state.MRowsPerPage}{" "}
                      of {this.state.MRlen} entries (filtered from{" "}
                      {this.state.MFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.MRlen == 0
                        ? 0
                        : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.MRowsPerPage >
                        this.state.MRlen
                        ? this.state.MRlen
                        : this.state.MPage * this.state.MRowsPerPage}{" "}
                      of {this.state.MRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.MCountPage}
                      onChange={this.HandleChangePageMeeting}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Step History */}
            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 py-4  mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                  Step History
                  </h3>
                </div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedStepHistory}
                      value={this.state.STHRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchStepHistory"
                      onKeyPress={(event) => this.RequestSearchStepHistory(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell onClick={() => {
                              this.StepHistorySortData("CampaignName");
                              this.setState({ StepHistorySortedColumn : "CampaignName"})
                            }}>
         
                            Campaign Name
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "CampaignName" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "CampaignName"  ? "active" : null} />
                          </span>
                 
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.StepHistorySortData("DayOfSendMail");
                              this.setState({ StepHistorySortedColumn : "DayOfSendMail"})
                            }}>
                 
                            Days
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "DayOfSendMail" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "DayOfSendMail"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.StepHistorySortData("Subject");
                              this.setState({ StepHistorySortedColumn : "Subject"})
                            }}>
                         
                            Subject
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "Subject" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "Subject"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.StepHistorySortData("TotalSent");
                              this.setState({ StepHistorySortedColumn : "TotalSent"})
                            }}>
     
                            Sent
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "TotalSent" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "TotalSent"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.StepHistorySortData("TotalOpen");
                              this.setState({ StepHistorySortedColumn : "TotalOpen"})
                            }}>
      
                            Open
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "TotalOpen" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "TotalOpen"  ? "active" : null} />
                          </span>
                 
                        </TableCell>
                        <TableCell onClick={() => {
                              this.StepHistorySortData("TotalReply");
                              this.setState({ StepHistorySortedColumn : "TotalReply"})
                            }}>
                        
                      
                            Reply
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "TotalReply" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "TotalReply"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.StepHistorySortData("TotalSoftBounce");
                              this.setState({ StepHistorySortedColumn : "TotalSoftBounce"})
                            }}>
              Soft Bounce
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "TotalSoftBounce" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "TotalSoftBounce"  ? "active" : null} />
                          </span>
                    
                        </TableCell>
                        <TableCell onClick={() => {
                              this.StepHistorySortData("TotalHardBounce");
                              this.setState({ StepHistorySortedColumn : "TotalHardBounce"})
                            }}>
           
                            Hard Bounce
                            <span className="shorting">
                         <ArrowUpward className={this.state.STHSortedBy === 1 && this.state.StepHistorySortedColumn === "TotalHardBounce" ? "active" : null} />
                          <ArrowDownward  className={this.state.STHSortedBy === -1 && this.state.StepHistorySortedColumn === "TotalHardBounce"  ? "active" : null} />
                          </span>
                        </TableCell>
 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.STHRows &&
                        this.state?.STHRows.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.STHRows &&
                        this.state?.STHRows.map((row34) => (
                          <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th":
                                { border: 0 },
                            }}
                                                        >
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting1(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.CampaignName}</TableCell>
                            <TableCell>{row34?.DayOfSendMail}</TableCell>
                            <TableCell>{row34?.Subject}</TableCell>
                            <TableCell>{row34?.TotalSent}</TableCell>
                            <TableCell>{row34?.TotalOpen}</TableCell>
                            <TableCell>{row34?.TotalReply}</TableCell>
                            <TableCell>{row34?.TotalSoftBounce}</TableCell>
                            <TableCell>{row34?.TotalHardBounce}</TableCell>  
                            </TableRow>              
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                  <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      {this.state.STHData.length > 0 ? (
                                        <Table size="small" aria-label="purchases">
                                          <TableHead>
                                          <TableCell>
                          <a
                   
                          ><strong>
                    Subject
                    </strong> </a>
                        </TableCell>
                        <TableCell>
                          <a
                   
                          ><strong>
                    Body
                    </strong> </a>
                        </TableCell>
                                          </TableHead>
                                          {this.state.STHRows.map((note) =>
                                            note._id === row34._id ? (
                                              <TableRow>
                                                <TableCell><strong>{note?.Subject}</strong></TableCell>
                                            
                                                <TableCell  style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                               }}><div dangerouslySetInnerHTML={{ __html: note?.Body }} /></TableCell>
                                             
                                              </TableRow>
                                            ) : null
                                          )}

                                          {!this.state.STHData.some(
                                            (note) =>
                                            note?._id === row34?._id
                                          )
                                          }
                                        </Table>
                                      ) : (
                                        <p className="text-center">No data available in table</p>
                                      )}
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>
                           
                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.STHSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.STHCountPage}
                    onChange={this.HandleChangePageStepHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.STHRlen == 0
                        ? 0
                        : (this.state.STHPage - 1) * this.state.STHRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.STHPage * this.state.STHRowsPerPage >
                        this.state.STHRlen
                        ? this.state.STHRlen
                        : this.state.STHPage * this.state.STHRowsPerPage}{" "}
                      of {this.state.STHRlen} entries (filtered from{" "}
                      {this.state.STHFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.STHRlen == 0
                        ? 0
                        : (this.state.STHPage - 1) * this.state.STHRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.STHPage * this.state.STHRowsPerPage >
                        this.state.STHRlen
                        ? this.state.STHRlen
                        : this.state.STHPage * this.state.STHRowsPerPage}{" "}
                      of {this.state.STHRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.STHCountPage}
                      onChange={this.HandleChangePageStepHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Campaign Table*/}

            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                    Campaign History
                  </h3>
                </div>
                <div className="col text-right">
                  <button
                    onClick={() => { this.CampaignAdd() }}
                    className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedCampaignHistory}
                      value={this.state.CAMRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchcampaigns"
                      onKeyPress={(event) =>
                        this.RequestSearchCampaignHistory(event)
                      }
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell onClick={() => {
                          this.CampaignHistorySortData("CampaignName")
                          this.setState({ CAMSortedColumn : "CampaignName"})
                        }}>Name
                           <span className="shorting">
                         <ArrowUpward className={this.state.CAMSortedBy === 1 && this.state.CAMSortedColumn === "CampaignName" ? "active" : null} />
                          <ArrowDownward  className={this.state.CAMSortedBy === -1 && this.state.CAMSortedColumn === "CampaignName"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                          this.CampaignHistorySortData("CreatedDate")
                          this.setState({ CAMSortedColumn : "CreatedDate"})
                        }}>Created Date
                           <span className="shorting">
                         <ArrowUpward className={this.state.CAMSortedBy === 1 && this.state.CAMSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.CAMSortedBy === -1 && this.state.CAMSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.CampaignHistoryData &&
                        this.state.CampaignHistoryData.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.CampaignHistoryData &&
                        this.state.CampaignHistoryData.map((row34) => (
                          <TableRow>
                            <TableCell>{row34?.CampaignName}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row34?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a className="btn-eyesicon"
                                onClick={() => {
                                  this.EditCampaignHistory(row34.CampaignID);
                                }}
                              ><i class="la flaticon-edit-1 edit-icon"></i></a>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.CAMSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.CAMCountPage}
                    onChange={this.HandleChangePageCampaignHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing
                      {this.state.CAMRlen == 0
                        ? 0
                        : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CAMPage * this.state.CAMRowsPerPage >
                        this.state.CAMRlen
                        ? this.state.CAMRlen
                        : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                      of {this.state.CAMRlen} entries (filtered from{" "}
                      {this.state.CAMFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CAMRlen == 0
                        ? 0
                        : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CAMPage * this.state.CAMRowsPerPage >
                        this.state.CAMRlen
                        ? this.state.CAMRlen
                        : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                      of {this.state.CAMRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CAMCountPage}
                      onChange={this.HandleChangePageCampaignHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Lists table*/}

            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">Lists</h3>
                </div>
                <div className="col text-right">
                  <button onClick={() => { this.ListsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedList}
                      value={this.state.RowsPerPageL}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchlist"
                      onKeyPress={(event) => this.RequestSearchList(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell onClick={() => {
                              this.ListsSortData("Name");
                              this.setState({ ListsSortedColumn : "Name"})
                            }}>
                          
                            Name
                            <span className="shorting">
                         <ArrowUpward className={this.state.SortedByL === 1 && this.state.ListsSortedColumn === "Name" ? "active" : null} />
                          <ArrowDownward  className={this.state.SortedByL === -1 && this.state.ListsSortedColumn === "Name"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.ListsSortData("CreatedDate");
                              this.setState({ ListsSortedColumn : "CreatedDate"})
                            }}>
                    
                            Created Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.SortedByL === 1 && this.state.ListsSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.SortedByL === -1 && this.state.ListsSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ListRows && this.state.ListRows.length === 0 ? (
                        <p className="text-center">No data available in the table</p>
                      ) : (
                        this.state.ListRows &&
                        this.state.ListRows.map((row34) => {
                          // Extracting only the file name from the full Name using a dynamic regular expression
                          const fileNameMatch = row34 && row34?.Name.match(/^([a-zA-Z0-9_\s]+?)\d+\.(csv)$/);
                          const fileName = fileNameMatch ? fileNameMatch[1] : '';

                          return (
                            <TableRow key={row34?._id}>
                              <TableCell>{row34?.Name}</TableCell>
                              <TableCell>
                                {row34 &&
                                  moment.utc(new Date(row34?.CreatedDate)).format(
                                    "MM/DD/YYYY"
                                  )}
                              </TableCell>
                              <TableCell>
                                <a
                                  onClick={() => {
                                    this.ListsEdit(row34 && row34._id);
                                  }}
                                >
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}


                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.SflagL ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.LCountPage}
                    onChange={this.HandleChangePageList}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing
                      {this.state.LRlen == 0
                        ? 0
                        : (this.state.PageL - 1) * this.state.RowsPerPageL +
                        1}
                      to
                      {this.state.PageL * this.state.RowsPerPageL >
                        this.state.LRlen
                        ? this.state.LRlen
                        : this.state.PageL * this.state.RowsPerPageL}{" "}
                      of {this.state.LRlen} entries (filtered from{" "}
                      {this.state.LFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.LRlen == 0
                        ? 0
                        : (this.state.PageL - 1) * this.state.RowsPerPageL + 1}{" "}
                      to{" "}
                      {this.state.PageL * this.state.RowsPerPageL > this.state.LRlen
                        ? this.state.LRlen
                        : this.state.PageL * this.state.RowsPerPageL}{" "}
                      of {this.state.LRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.LCountPage}
                      onChange={this.HandleChangePageList}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* contact status history table*/}
            <div className="bg-white  mt-3">
              <div className="row align-items-center p-3 py-4 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                    Contact Status History
                  </h3>
                </div>
                <div className="col text-right"></div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedContactStatusHistory}
                      value={this.state.HRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="Search"
                      id="HSearch"
                      onKeyPress={(event) =>
                        this.RequestSearchContactHistory(event)
                      }
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell onClick={() => {
                              this.ContactStatusHistorySortData("p1Status");
                              this.setState({ HSortedColumn : "p1Status"})
                            }}>
          
                            Old Status
                            <span className="shorting">
                         <ArrowUpward className={this.state.HSortedBy === 1 && this.state.HSortedColumn === "p1Status" ? "active" : null} />
                          <ArrowDownward  className={this.state.HSortedBy === -1 && this.state.HSortedColumn === "p1Status"  ? "active" : null} />
                          </span>
                        </TableCell>
                        <TableCell onClick={() => {
                              this.ContactStatusHistorySortData("p2Status");
                              this.setState({ HSortedColumn : "p2Status"})
                            }}>
                         
                            New Status
                            <span className="shorting">
                         <ArrowUpward className={this.state.HSortedBy === 1 && this.state.HSortedColumn === "p2Status" ? "active" : null} />
                          <ArrowDownward  className={this.state.HSortedBy === -1 && this.state.HSortedColumn === "p2Status"  ? "active" : null} />
                          </span>
                   
                        </TableCell>
                        <TableCell  onClick={() => {
                              this.ContactStatusHistorySortData("ChangedDate");
                              this.setState({ HSortedColumn : "ChangedDate"})
                            }}>
            
                            Change Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.HSortedBy === 1 && this.state.HSortedColumn === "ChangedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.HSortedBy === -1 && this.state.HSortedColumn === "ChangedDate"  ? "active" : null} />
                          </span>
                 
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.HRows?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.HRows?.map((row) => (
                          <TableRow>
                            <TableCell>{row?.p2Status}</TableCell>
                            <TableCell>{row?.p1Status}</TableCell>
                            <TableCell>{
                              row?.ChangedDate == null ? "" : moment.utc(
                                new Date(row?.ChangedDate)
                              ).format("MM/DD/YYYY")
                            }

                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.HSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    page={this.state.HPage}
                    count={this.state.HCountPage}
                    onChange={this.HandleChangePageContactStatusHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.HRlen == 0
                        ? 0
                        : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                        ? this.state.HRlen
                        : this.state.HPage * this.state.HRowsPerPage}{" "}
                      of {this.state.HRlen} entries (filtered from{" "}
                      {this.state.HFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.HRlen == 0
                        ? 0
                        : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                        ? this.state.HRlen
                        : this.state.HPage * this.state.HRowsPerPage}{" "}
                      of {this.state.HRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      page={this.state.HPage}
                      count={this.state.HCountPage}
                      onChange={this.HandleChangePageContactStatusHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Call history Table*/}
            <div className="mt-3 bg-white">
              <div className="row align-items-center p-3 py-4 pb-0 mx-0">
                <div className="">
                  <h3 className=" float-left mb-0 addpotential_header xs-headertitle">
                    Call History
                  </h3>
                </div>
              </div>
              <div className="row pt-4 px-3">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedCallHistory}
                      value={this.state.CRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchcallhistory"
                      onKeyPress={(event) => this.RequestSearchCallHistory(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div class='px-3'>
              <div className="table-bordered border-0">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                   
              
                        <TableCell         onClick={() => {
                              this.CallHistorySortData("Status");
                            this.setState({  CallHistorySortedColumn : "Status"})

                            }}>
               
                            Result
                            <span className="shorting">
                         <ArrowUpward className={this.state.CSortedBy === 1 && this.state.CallHistorySortedColumn === "Status" ? "active" : null} />
                          <ArrowDownward  className={this.state.CSortedBy === -1 && this.state.CallHistorySortedColumn === "Status"  ? "active" : null} />
                          </span>
                     
                        </TableCell>
                        <TableCell        onClick={() => {
                              this.CallHistorySortData("Duration");
                              this.setState({  CallHistorySortedColumn : "Duration"})
                            }}>
               
                            Duration
                            <span className="shorting">
                         <ArrowUpward className={this.state.CSortedBy === 1 && this.state.CallHistorySortedColumn === "Duration" ? "active" : null} />
                          <ArrowDownward  className={this.state.CSortedBy === -1 && this.state.CallHistorySortedColumn === "Duration"  ? "active" : null} />
                          </span>
                       
                        </TableCell>
                        <TableCell       onClick={() => {
                              this.CallHistorySortData(
                                "RecordingFileCreatedDate"
                              );
                              this.setState({  CallHistorySortedColumn : "RecordingFileCreatedDate"})
                            }}>
                 
                            Call Time / Date
                            <span className="shorting">
                         <ArrowUpward className={this.state.CSortedBy === 1 && this.state.CallHistorySortedColumn === "RecordingFileCreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.CSortedBy === -1 && this.state.CallHistorySortedColumn === "RecordingFileCreatedDate"  ? "active" : null} />
                          </span>
                      
                        </TableCell>
                        <TableCell         onClick={() => {
                              this.CallHistorySortData("RecordingFileName");
                              this.setState({  CallHistorySortedColumn : "RecordingFileName"})
                            }}>
                    
                            Recording
                            <span className="shorting">
                         <ArrowUpward className={this.state.CSortedBy === 1 && this.state.CallHistorySortedColumn === "RecordingFileName" ? "active" : null} />
                          <ArrowDownward  className={this.state.CSortedBy === -1 && this.state.CallHistorySortedColumn === "RecordingFileName"  ? "active" : null} />
                          </span>
                      
                        </TableCell>
                        <TableCell    onClick={() => {
                              this.CallHistorySortData("Note");
                              this.setState({  CallHistorySortedColumn : "Note"})

                            }}>
                    
                            Note
                            <span className="shorting">
                         <ArrowUpward className={this.state.CSortedBy === 1 && this.state.CallHistorySortedColumn === "Note" ? "active" : null} />
                          <ArrowDownward  className={this.state.CSortedBy === -1 && this.state.CallHistorySortedColumn === "Note"  ? "active" : null} />
                          </span>
                    
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  {this.state?.CallHistoryData && this.state.CallHistoryData.length === 0 ? (
    <p className="text-center">No data available in table</p>
  ) : (
    this.state?.CallHistoryData &&
    this.state?.CallHistoryData.map((row34) => {
      const changedDate = row34?.CreatedDate
        ? new Date(row34.CreatedDate).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'UTC',
          })
        : '';
      return (
        <TableRow key={row34.id}>
          {/* <TableCell>{row34?.ColdCallersName}</TableCell>
          <TableCell>{row34?.Title}</TableCell>
          <TableCell>{row34?.Company}</TableCell> */}
          <TableCell>{row34?.Status}</TableCell>
          <TableCell>{row34?.Duration1}</TableCell>
   
          <TableCell>{row34.PSTCreatedDate + " PT"}</TableCell>
          {
            row34?.Duration != 0 ? <TableCell><audio controls style={{ outline: 'none' }} type='audio/mp3'><source src={CommonConstants.CallRecoingURL + row34?.RecordingFileName + ".mp3"} /></audio></TableCell> : <TableCell></TableCell>
          }
          {/* <TableCell>{row34?.RecordingFileCreatedDate}</TableCell>
          <TableCell>{row34?.RecordingFileName}</TableCell> */}
          <TableCell>{row34?.Note}</TableCell>
        </TableRow>
      );
    })
  )}
</TableBody>

                  </Table>
                </TableContainer>
              </div>
              </div>
              {this.state.MSflag ? (
                <div class="row pb-2 px-3">
                  <Pagination
                    component="div"
                    count={this.state.CCountPage}
                    onChange={this.HandleChangePageCallHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CRlen == 0
                        ? 0
                        : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CPage * this.state.CRowsPerPage >
                        this.state.CRlen
                        ? this.state.CRlen
                        : this.state.CPage * this.state.CRowsPerPage}{" "}
                      of {this.state.CRlen} entries (filtered from{" "}
                      {this.state.CFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row px-3 pb-2">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CRlen == 0
                        ? 0
                        : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.CRowsPerPage >
                        this.state.CRlen
                        ? this.state.CRlen
                        : this.state.CPage * this.state.CRowsPerPage}{" "}
                      of {this.state.CRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CCountPage}
                      onChange={this.HandleChangePageCallHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Contact Activity */}
            <div class="mt-3 bg-white px-3 pb-3">
              <div class="row border-bottom mb-4">
                <div class="col-md-12">
                  <h4 className="headertitlebd xs-headertitle pl-0">Contact Activity</h4>
                </div>
              </div>
              
             {/* Full Contact Status Activity */}

             {this.state.ContactStatusAllActivity?.length > 0 ? 
              this.state.ContactStatusAllActivity?.map((value) => {
                const changedDate = value?.CreatedDate ? new Date(value?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                if(value.IsContatEmailBlacklIstFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div> 
                  )
                }
                if(value.IsProspectStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Status</b> Update : -{" "}
                      <b>{value?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsContactDomainBlacklistedFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact Domain</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsPotentialMeetingFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
Potential Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsMeetingFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
 Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsMeetingStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Meeting Status</b> Update : -{" "}
                      <b>{value?.MeetingStatus}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                
                if(value.IsProspectCampaignStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Campaign Status</b> Update : -{" "}
                      <b>{value?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsSentEmailFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Sent</b> email : -{" "}
                      <b>{value?.Campaign}</b> :- <b>{value?.StepNumber}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsExternalDomainTrackFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>External track </b> domain : -{" "}
                      <b>{value?.ExternelDomainClickTrackDoamin}</b> 
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsContatCallHistoryFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Call duration </b>  : -{" "}{value?.Duration1} <b>{value?.Status}</b> 
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                
              }) : null
             }
              {/* List Activity */}
              {
                this.state?.IsListFalg === true ? 
                this.state?.ListData?.map((item) => {
                  const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact</b> Created via : - <b>List Upload</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
                }) : <>
          
              
                <div class="row mt-4">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                      </span>
                    </div>
         

                    <div class="col-md-8">
                      <b>Contact</b> Created via : - <b>Manually</b>
                    </div>
                    <div class="col-md-3">{this.state.CreatedDate ? new Date(this.state.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : ""}</div>
                  </div>
                </>
              }
    
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedViewContactPage = connect(
  mapState,
  actionCreators
)(ViewContactPage);
export { connectedViewContactPage as ViewContactPage };
