import React, { useEffect, useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");
import Popup from "reactjs-popup";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import StyleHeader from "../StickyHeader/StickyHeader";

export default function AllClientPotentialMeeting({ FromDates, ToDates }) {
  const [Open, SetOpen] = React.useState(false);
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [Notes, SetNotes] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [status, SetStatus] = React.useState([]);
  const [ShowPopupImport, SetShowPopupImport] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [ExportMailsend, SetExportMailsend] = React.useState(false);
  const [exClientIDs, SetExClientIDs] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  // First useEffect for setting document title and user details
  useEffect(() => {
    document.title = `Client Potential Meetings | SalesHive`;
    var GetAccountCatelist = GetUserDetails();
    if (GetAccountCatelist != null) {
      SetClientID(GetAccountCatelist.ClientID);
      SetUserID(GetAccountCatelist.ParentUserID);
      SetCUserID(GetAccountCatelist.ChildUserID);
      SetUserEmail(GetAccountCatelist.Username);
    }
  }, []);

  // Second useEffect for fetching client potential list
  useEffect(() => {
    var GetAccountCatelist = GetUserDetails();

    // Ensure that GetAccountCatelist is not null before proceeding
    // if (GetAccountCatelist != null) {
    //   GetClientPotentialList(GetAccountCatelist.ParentUserID);
    // }

    Promise.all([
      GetClientPotentialList(
        GetAccountCatelist.ParentUserID,
        FromDates,
        ToDates
      ),
    ])
      .then(() => {
        // Both requests completed, set loader to false
        //SetIsLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
      });
  }, [Search, Page, RowsPerPage, FromDates, ToDates]);

  //get potential meeting
  const GetClientPotentialList = (UrID, FromDateMeeting, ToDateMeeting) => {
    SetIsLoading(true);
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UrID,
      ToDate: ToDateMeeting,
      FromDate: FromDateMeeting,
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/ClientPotentialMeetingGet",
      method: "POST",
      data: str_in,
    });
    Rows1.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetNotes(Result.data.MeetingNotes);
      SetExClientIDs(Result.data.exportClientIds);
      SetIsLoading(false);
      setIsDataLoaded(true);
    }).catch((error) => {
      SetIsLoading(false);
      setIsDataLoaded(true);
    });
  };

  //Get Notes
  const GetNotes = (ID) => {
    SetIsLoading(true);
    var InputParameter = {
      ID: ID,
    };
    const AccountCategoriesList = Axios({
      url: CommonConstants.MOL_APIURL + "/client_linkedin_followup/NotesGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetNotes(Result.data.Data);
      SetIsLoading(false);
    }).catch((error) => {
      SetIsLoading(false);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //delete potential meeting
  const DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a potential meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/DeletePotentialMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Pontential meeting deleted successfully.",
                "success"
              );

              GetClientPotentialList(UserID, FromDates, ToDates);
              //  history.push("/clientpotentialmeetings");
              SetPage(1);
              SetSearch("");
              document.getElementById("Search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display Rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  //render on view Page
  const ViewPage = (id) => {
    history.push({
      pathname: "/viewpotentialmeeting",
      state: { data: id, Pagename: "clientPotenial" },
    });
  };

  //render on Add Page
  const AddHandle = () => {
    history.push({
      pathname: "/addpotentialmeeting",
      state: { Pagename: "clientPotenial" },
    });
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "clientPotenial" },
      });
    }
  };

  //Reset view
  const ResetView = () => {
    GetClientPotentialList(UserID);
    document.getElementById("resetview").style.display = "none";
  };

  const FromValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var Email = document.getElementById("Username").value.trim();
    let IsExist = await EmailValidationCheck(Email);
    if (Email == "") {
      FormIsValid = false;
      Errors["Username"] = "Please enter email.";
    }

    if (IsExist == true) {
      FormIsValid = false;
      Errors["Username"] = "invalid email.";
    }

    SetErrors(Errors);

    return FormIsValid;
  };

  // find exits Email
  const EmailValidationCheck = async (Email) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Username"] = "invalid email.";
      SetErrors(Errors);
      return true;
    } else {
      return false;
    }
  };

  // handle change
  const HandleEmailCheck = (Field, e) => {
    var Email = document.getElementById("Username").value.trim();
    SetErrors("");
    SetFields(Email);
    if (Fields.Username != "") {
      var Email = document.getElementById("Username").value.trim();
      EmailValidationCheck(Email);
    } else {
      Errors.Username = "";
    }
  };

  // Exports csv for contacts
  const ExportsToContacts = async (e) => {
    e.preventDefault();
    // e.currentTarget.disabled = true;
    SetExportMailsend(true);
    var FinalValidation = await FromValidation();
    if (FinalValidation == true) {
      var ExportEmails = document.getElementById("Username").value;
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      var ClientIDs = [];
      Rows.map((item) => {
        ClientIDs.push(item.ClientID);
      });
      var tempClientArray = ClientIDs.filter(onlyUnique);
      var ClientIdString = tempClientArray.join(",");
      ClientIdString = exClientIDs;
      var ExportData = {
        UserID: UserID,
        ClientIDs: ClientIdString,
        Email: ExportEmails,
        PageName: "AllClientPotentialMeeting",
        CreatedDate: new Date(),
        CreatedBy: UserID,
        ToDate: ToDates,
        FromDate: FromDates,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/AllClientPotentialExport",
        method: "POST",
        data: ExportData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Potential Meetings <br /> You will get file soon on Submitted
              Email.
            </div>,
            "For Exports Client Potential Meeting  "
          );
          SetShowPopupImport(false);
          SetExportMailsend(false);
        } else {
          toast.error(res.data.Message);
          SetExportMailsend(false);
        }
      });
    } else {
      SetExportMailsend(false);
      // document.getElementById("submitModel").disabled = false;
    }
  };

  //Get sort field data
  const SortData = (Field) => {
    const SearchedVal = document.getElementById("Search").value;
    setSortedColumn(Field);
    SetIsLoading(true);
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
   
    SetSortField(Field)
    SetSflag(SearchedVal !== "");
   
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        Archive: false,
        Sort: true,
        Field: Field,
        Sortby: SortBy,
        Type: "User",
        UserID: UserID,
        ToDate: ToDates,
        FromDate: FromDates,
      };
      const ClientPotentialGet = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/ClientPotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      ClientPotentialGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetExClientIDs(Result.data.exportClientIds);
        SetIsLoading(false);
      }).catch((error) => {
        SetIsLoading(false);
      });
   
  };

  // meetings add
  const MeetingsConvertAdd = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to convert potential meeting into meeting?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var str_in = {
          id: ids,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingByID",
          method: "POST",
          data: str_in,
        }).then((res) => {
          history.push({
            pathname: "/addbookedmeeting",
            state: {
              Pagename: "Potential",
              data: res.data[0],
              BackPage: "/bookedmeetings",
            },
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row mx-0">
        <div className="col"></div>
      </div>
      <div className="bg-white p-3 ">
        <div className="listing-li float-right ">
          <ul class="my-0 pt-1">
            <li>
              <a
                onClick={() => SetShowPopupImport(true)}
                className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
              >
                <i class="la la-icon-font-size-13 la-download"></i>
                <span>Export</span>
              </a>
            </li>
            <Popup open={ShowPopupImport}>
              <div>
                <div className="modal-black"></div>
                <div className="filterPopup largerPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className="w-100 d-flex px-3">
                        <h5 className="mb-0">Email</h5>
                        <button
                          className="close"
                          onClick={() => {
                            SetShowPopupImport(false);
                          }}
                        >
                          <span aria-hidden="true" class="la la-remove"></span>
                        </button>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="modal-body">
                        <div className="col-sm-8 mb-1 px-0">
                          <div class="row mb-3">
                            <label class="col-lg-5 col-form-label text-left pl-5">
                              Email
                            </label>
                            <div class="col-lg-7">
                              <input
                                class="form-control m-input"
                                id="Username"
                                data-val="true"
                                data-val-remote="Title already exist."
                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                data-val-required="Please enter Email "
                                onBlur={(e) => HandleEmailCheck(e, "Username")}
                                name="Username"
                                defaultValue={UserEmail}
                                type="text"
                              />
                              <span style={{ color: "red" }}>
                                {Errors["Username"]}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top modal-content text-right">
                        <div class="row">
                          <div class="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              class="btn btn-secondary mr-2"
                              onClick={() => {
                                SetShowPopupImport(false);
                              }}
                            >
                              cancel
                            </a>
                            <button
                              id="submitModel"
                              disabled={ExportMailsend}
                              onClick={(e) => ExportsToContacts(e)}
                              class="btn btn-primary btn-lightgreen mr-1"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            <li>
              <a
                onClick={AddHandle}
                className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
              >
                <i class="la la-icon-font-size-13 la-plus"></i>
                <span>Add</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="row pt-2 ">
          <div className="col-sm-12 col-md-6 pl-0">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>

          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="Search"
                id="Search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />

            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Notes</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Convert</TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ClientName");
                        setSortedColumn("ClientName");
                      }}
                    >
                      Client Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "ClientName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "ClientName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ResponderName");
                        setSortedColumn("ResponderName");
                      }}
                    >
                      Responder
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "ResponderName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "ResponderName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Company");
                        setSortedColumn("Company");
                      }}
                    >
                      Company
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Company"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Company"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ContactName");
                        setSortedColumn("ContactName");
                      }}
                    >
                      Contact Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "ContactName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "ContactName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Email");
                        setSortedColumn("Email");
                      }}
                    >
                      Email
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Email"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Email"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Domain");
                        setSortedColumn("Domain");
                      }}
                    >
                      Domain
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Domain"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Domain"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Title");
                        setSortedColumn("Title");
                      }}
                    >
                      Title
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Title"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Title"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("LinkedInUrl");
                        setSortedColumn("LinkedInUrl");
                      }}
                    >
                      LinkedInUrl
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "LinkedInUrl"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "LinkedInUrl"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("MeetingSourceName");
                        setSortedColumn("MeetingSourceName");
                      }}
                    >
                      Meeting Source Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            sortedColumn === "MeetingSourceName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            sortedColumn === "MeetingSourceName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("MeetingOwnerName");
                        setSortedColumn("MeetingOwnerName");
                      }}
                    >
                      Owner Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            sortedColumn === "MeetingOwnerName"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            sortedColumn === "MeetingOwnerName"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("ReminderEmailAccount");
                        setSortedColumn("ReminderEmailAccount");
                      }}
                    >
                      Reminder Email Account
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            sortedColumn === "ReminderEmailAccount"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            sortedColumn === "ReminderEmailAccount"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("EngagamentStatus");
                        setSortedColumn("EngagamentStatus");
                      }}
                    >
                      Engagement Status
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            sortedColumn === "EngagamentStatus"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            sortedColumn === "EngagamentStatus"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("DirectPhone");
                        setSortedColumn("DirectPhone");
                      }}
                    >
                      Direct Phone
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "DirectPhone"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "DirectPhone"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("DirectPhoneExt");
                        setSortedColumn("DirectPhoneExt");
                      }}
                    >
                      Direct Phone Ext
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "DirectPhoneExt"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "DirectPhoneExt"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CompanyPhone");
                        setSortedColumn("CompanyPhone");
                      }}
                    >
                      Company Phone
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "CompanyPhone"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "CompanyPhone"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("LastStatusChangeDate");
                        setSortedColumn("LastStatusChangeDate");
                      }}
                    >
                      Last Status Change Date
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 &&
                            sortedColumn === "LastStatusChangeDate"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 &&
                            sortedColumn === "LastStatusChangeDate"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("CreatedDate");
                        setSortedColumn("CreatedDate");
                      }}
                    >
                      Created Date
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "CreatedDate"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "CreatedDate"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </a>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((row) => (
                    <>
                      <TableRow key={row._id}>
                        <TableCell align="center">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              SetOpen((prev) => ({
                                // ...prev,
                                [row._id]: !prev[row._id],
                              }));
                              GetNotes(row._id);
                            }}
                          >
                            {Open[row._id] ? (
                              <i
                                className="fa fa-minus-circle minusl blueplus"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-plus-circle plusbl blueplus"
                                aria-hidden="true"
                              ></i>
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/viewpotentialmeeting?id=${row?._id}&pagename=clientPotenial`}
                            onClick={() => {
                              e.preventDefault();
                              ViewPage(row?._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteClientName(row?._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                        <TableCell scope="row">
                          <a
                            onClick={() => {
                              MeetingsConvertAdd(row._id);
                            }}
                            className="btn-exchangeicon"
                          >
                            <i className="la la-exchange"></i>
                          </a>
                        </TableCell>
                        <TableCell>{row?.ClientName}</TableCell>
                        <TableCell>{row?.ResponderName}</TableCell>
                        <TableCell>{row?.Company}</TableCell>
                        <TableCell>
                          <a
                            href={`/viewcontacts?id=${
                              row?.ProspectPotentialDetails?.ProspectID
                            }&email=${encodeURIComponent(
                              row?.Email
                            )}&pagename=clientPotenial`}
                            onClick={(e) => {
                              e.preventDefault();
                              EditContactHandle(
                                row?.ProspectPotentialDetails?.ProspectID,
                                row?.Email
                              );
                            }}
                          >
                            <u>{row?.ContactName}</u>
                          </a>
                        </TableCell>
                        <TableCell>{row?.Email}</TableCell>
                        <TableCell>
                          {row?.Email?.substring(
                            row?.Email?.lastIndexOf("@") + 1
                          )}
                        </TableCell>
                        <TableCell>{row?.Title}</TableCell>
                        <TableCell>{row?.LinkedInUrl}</TableCell>
                        <TableCell>{row?.MeetingSourceName}</TableCell>
                        <TableCell>{row?.MeetingOwnerName}</TableCell>
                        <TableCell>{row?.ReminderEmailAccount}</TableCell>
                        <TableCell className="labeltds" scope="row">
                          {row?.EngagamentStatus}
                        </TableCell>
                        <TableCell>{row?.DirectPhone}</TableCell>
                        <TableCell>{row?.DirectPhoneExt}</TableCell>
                        <TableCell>{row?.CompanyPhone}</TableCell>
                        <TableCell>
                          {row?.LastStatusChangeDate != null
                            ? moment(
                                new Date(row?.LastStatusChangeDate)
                              ).format("MM/DD/YYYY")
                            : ""}
                        </TableCell>

                        <TableCell>
                          {row?.CreatedDate != null
                            ? moment(new Date(row?.CreatedDate)).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </TableCell>
                        <TableCell>
                          <a
                            href={`/viewpotentialmeeting?id=${row?._id}&pagename=clientPotenial`}
                            onClick={() => {
                              e.preventDefault();
                              ViewPage(row?._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteClientName(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={Open[row._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {Open[row._id] && (
                              <Box margin={1} className="innertables">
                                <Table size="small" aria-label="purchases">
                                  {Notes.map((note) =>
                                    note.PotentialMeetingID === row._id ? (
                                      <TableRow key={note._id}>
                                        <TableCell className="font-weight-bold">
                                          {note?.Title}
                                        </TableCell>
                                        <TableCell className="labeltds font-weight-bold">
                                          {moment(note?.CreatedDate).format(
                                            "MM/DD/YYYY"
                                          )}
                                        </TableCell>
                                        <TableCell scope="row">
                                          {note?.Note}
                                        </TableCell>
                                        {/* <TableCell className="labeltds"></TableCell>
                                      <TableCell className="labeltds"></TableCell> */}
                                      </TableRow>
                                    ) : null
                                  )}

                                  {!Notes.some(
                                    (note) =>
                                      note.PotentialMeetingID === row._id
                                  ) && (
                                    <TableRow>
                                      <TableCell colSpan={4}>
                                        No data available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Table>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <>
            <div className="row">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
              <div className="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                  {Rlen} entries (filtered from {Flen} total entries)
                </p>
              </div>
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    onChange={ChangeRowSelected}
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>

              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="Search"
                    id="Search"
                    onKeyPress={RequestSearch}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Notes</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Convert</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("ClientName");
                          }}
                        >
                          Client Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Company");
                          }}
                        >
                          Company{" "}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("ContactName");
                          }}
                        >
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Email");
                          }}
                        >
                          Email{" "}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Domain");
                          }}
                        >
                          Domain{" "}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("Title");
                          }}
                        >
                          Title{" "}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("LinkedInUrl");
                          }}
                        >
                          LinkedInUrl
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("MeetingSourceName");
                          }}
                        >
                          Meeting Source Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("MeetingOwnerName");
                          }}
                        >
                          Owner Name
                        </a>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <a
                          onClick={() => {
                            SortData("ReminderEmailAccount");
                          }}
                        >
                          Reminder Email Account
                        </a>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <a
                          onClick={() => {
                            SortData("EngagamentStatus");
                          }}
                        >
                          Engagement Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("DirectPhone");
                          }}
                        >
                          Direct Phone
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("DirectPhoneExt");
                          }}
                        >
                          Direct Phone Ext
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            SortData("CompanyPhone");
                          }}
                        >
                          Company Phone
                        </a>
                      </TableCell>
                      <TableCell>Last Status Change Date</TableCell>

                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <>
                          <TableRow key={row.PotentialMeetingID}>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                  SetOpen((prev) => ({
                                    ...prev,
                                    [row.PotentialMeetingID]:
                                      !prev[row.PotentialMeetingID],
                                  }));
                                  //GetNotes(row.PotentialMeetingID);
                                }}
                              >
                                {Open[row.PotentialMeetingID] ? (
                                  <i
                                    className="fa fa-minus-circle minusl blueplus"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl blueplus"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  ViewPage(row.PotentialMeetingID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-eye edit-icon"></i>
                              </a>
                              <a
                                onClick={() => {
                                  DeleteClientName(row.PotentialMeetingID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                            <TableCell scope="row">
                              <a
                                onClick={() => {
                                  MeetingsConvertAdd(row.PotentialMeetingID);
                                }}
                                className="btn-exchangeicon"
                              >
                                <i className="la la-exchange"></i>
                              </a>
                            </TableCell>
                            <TableCell>{row?.ClientName}</TableCell>
                            <TableCell>{row?.Company}</TableCell>
                            <TableCell> {row?.ContactName}</TableCell>
                            <TableCell>{row?.Email}</TableCell>
                            <TableCell>
                              {row?.Email.substring(
                                row.Email.lastIndexOf("@") + 1
                              )}
                            </TableCell>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.LinkedInUrl}</TableCell>
                            <TableCell>{row?.MeetingSourceName}</TableCell>
                            <TableCell>{row?.MeetingOwnerName}</TableCell>
                            <TableCell>{row?.ReminderEmailAccount}</TableCell>
                            <TableCell className="labeltds" scope="row">
                              {row?.EngagamentStatus}
                            </TableCell>
                            <TableCell>{row?.DirectPhone}</TableCell>
                            <TableCell>{row?.DirectPhoneExt}</TableCell>
                            <TableCell>{row?.CompanyPhone}</TableCell>
                            <TableCell>
                              {row?.LastUpdatedDate != null
                                ? moment(new Date(row?.LastUpdatedDate)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>

                            <TableCell>
                              {row?.CreatedDate != null
                                ? moment(new Date(row?.CreatedDate)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  ViewPage(row.PotentialMeetingID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-eye edit-icon"></i>
                              </a>
                              <a
                                onClick={() => {
                                  DeleteClientName(row.PotentialMeetingID);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row.PotentialMeetingID]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row.PotentialMeetingID] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      {Notes.map((note) =>
                                        note.PotentialMeetingID ===
                                        row.PotentialMeetingID ? (
                                          <TableRow
                                            key={note.PotentialMeetingID}
                                          >
                                            <TableCell className="font-weight-bold">
                                              {note.Title}
                                            </TableCell>
                                            <TableCell className="labeltds font-weight-bold">
                                              {moment(
                                                new Date(
                                                  note?.CreatedDate
                                                ).toDateString()
                                              ).format("MM/DD/YYYY")}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                            <TableCell className="labeltds"></TableCell>
                                            <TableCell className="labeltds"></TableCell>
                                          </TableRow>
                                        ) : null
                                      )}

                                      {!Notes.some(
                                        (note) =>
                                          note.PotentialMeetingID ===
                                          row.PotentialMeetingID
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
