import React, {useState, useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import {AddEditDialingSets } from '../../../user/DialingSetsPage/AddEditDialingSets/AddEditDialingSets.jsx';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import StarIcon from "@material-ui/icons/Star";
import { CommonConstants } from "../../../_constants/common.constants.js";
import { GetUserDetails } from "../../../_helpers/Utility.js";
import { history } from "../../../_helpers/index.js";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader.js";
import { toast } from "react-toastify";

export default function CollapsibleTable() {
  const urlParams = window.location.pathname;
  const NavigationParams = urlParams === "/defaultphonesettings" ? "Default" : ""
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isAddingAndEdit, setIsAddingAndEdit] = useState(false);
  const [dialingsetsIdForEdit, setDialingsetsIdForEdit] = useState(null);
  const [isEditMode, setisEditMode] = useState(false);
  const [updatechild, setupdatechild] = useState(false); // State to hold data from the child
  const [PageTypeParams, SetPageTypeParams] = useState(false);
  useEffect(() => {
    document.title = 'Dialing Sets | SalesHive';
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

  
    DialingSetsGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage,updatechild]);

  //dispositions status get list
  const DialingSetsGet = (CID,UID,URole) => {
  
    let DispositionsStatusData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      PageType:NavigationParams
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsGet",
      method: "POST",
      data: DispositionsStatusData,
    });
    ApiResponse.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };
  // Callback function to update parent state
 const UpdateFromChild = (value) => {
    if(value == true){
      DialingSetsGet(ClientID,UserID,Role);
      setisEditMode(false)
      setIsAddingAndEdit(false)
    }
  }
  // Search details
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true);
      }
    }
  };
  // Delete function
  const DeleteBtn = (id,StatusOrderArray) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a dialing sets status.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        
      
        let ContactTagData = {
          _id: id,
          StatusOrderDetails:StatusOrderArray,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role: Role,
          ClientID:ClientID,
          UserID:UserID,
          PageType:NavigationParams
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsDelete",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Dialing Sets deleted successfully.",
                "success"
              );
              SetIsLoading(true);
              DialingSetsGet(ClientID,UserID,Role);
              SetPage(1)
              SetSearch("")
              document.getElementById("search").value = ""
              // props.updateFromChild(true)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // change page
  const HandleChangePage = (event,NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
       SetIsLoading(true);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    SetIsLoading(true);
  };

  // edit button
  const EditBtn = (id) => {
    setDialingsetsIdForEdit(id); // Set the disposition ID to edit
    setIsAddingAndEdit(true); // Set the flag to indicate adding/editing
    setisEditMode(true)
  };
    // add button
    const AddBtn = (id) => {
      if(Rows.length > 0 && NavigationParams === "Default"){
        toast.success("Default dialing set has already been added.");
      }else{
        setDialingsetsIdForEdit(null); // Set the disposition ID to edit
        setIsAddingAndEdit(true); // Set the flag to indicate adding/editing
        setisEditMode(false)
      }

    };

     //get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");

  let InputParameter = {
    Page: Page,
    RowsPerPage: RowsPerPage,
    Search: SearchedVal,
    Sort: true,
    Field: Field,
    SortBy: SortBy,
    Type: "User",
    ClientID: ClientID,
    UserID: UserID,
    Role: Role,
   PageType: NavigationParams
  };
  
  const ApiResponse = Axios({
    url:CommonConstants.MOL_APIURL +"/dialingsets/DialingSetsGet",
    method: "POST",
    data: InputParameter,
  });
  ApiResponse.then((Result) => {
    SetData(Result.data.PageData);
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    SetPage(1)
    SetIsLoading(false);
  });


};

const CopyBtn = async (id) => {
  let ScriptData = {
      ID: id,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      PageType:NavigationParams
  };
  const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsCopy",
      method: "POST",
      data: ScriptData,
  });
  ApiResponse.then((result) => {
      if (result.data.StatusMessage === "SUCCESS") {
          toast.success(
              <div className="toastsize">
                  Copy Dialing Sets
                  <br />
                   Dialing Sets
                  copied successfully.
              </div>
          );
          SetIsLoading(true);
          DialingSetsGet(ClientID, UserID, Role);
      } else {
          toast.error(
              <div className="toastsize">
                  Copy Dialing Sets
                  <br />
                  Failed to copy Dialing Sets.
              </div>
          );
      }
  });
}

const MakeDefaultBtn = async (id) => {
  let ScriptData = {
      ID: id,
      ClientID: ClientID,
      Role: Role,
      LastUpdatedBy:CUserID
  };
  const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsMakeDefaultUpdate",
      method: "POST",
      data: ScriptData,
  });
  ApiResponse.then((result) => {
      if (result.data.StatusMessage === "SUCCESS") {
          toast.success(
              <div className="toastsize">
                  Dialing Sets
                  <br />
                   Dialing Sets make default updated successfully.
              </div>
          );
          SetIsLoading(true);
          DialingSetsGet(ClientID, UserID, Role);
      } else {
          toast.error(
              <div className="toastsize">
                   Dialing Sets
                  <br />
                  Failed to make default Dialing Sets.
              </div>
          );
      }
  });
}

  return (
    <>
       {IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

        {
          isAddingAndEdit ? 
          (       <AddEditDialingSets
                isEditMode={isEditMode}
                dialingsetsIdForEdit ={dialingsetsIdForEdit}
                onUpdate={UpdateFromChild}
              />) : (<>   <div className="row mx-0 align-items-center border-bottom">
        <div className="col px-0">
          <h4 className="headertitle xs-headertitle float-left padx-0">Dialing Sets</h4>
        </div>
          <div className="col">
                      <div className="listing-li float-right">
                        <ul class='my-0'>
                          <li>
                            <a
                              onClick={() => {
                                AddBtn();
                      }}
                              // onClick={this.AddBtn}
                              className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                            >
                              <i className="la la-icon-font-size-13 la-plus"></i>
                              <span>Add</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
      </div>

      <div class=''>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-4 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
        <Table className="table-ref" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {
                      SortData("Name");
                      setSortedColumn("Name");
                    }}>
                        Name
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Name" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "Name"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("CreatedDate");
                      setSortedColumn("CreatedDate");
                    }}>
                         Created Date
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("StatusOrderDetails");
                      setSortedColumn("StatusOrderDetails");
                    }}>
                       Disposition Count
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "StatusOrderDetails" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "StatusOrderDetails"  ? "active" : null} />
                          </span>
                </TableCell>
 

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
              Rows?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row?.Name}</TableCell>
                  <TableCell> {moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}</TableCell>
                  <TableCell>{row?.StatusOrderDetails}</TableCell>
      
                  <TableCell>
                    <a
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                      title="Edit"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
  onClick={() => {
    if (!row.IsMakeDefault) { // Only delete if IsMakeDefault is false
      DeleteBtn(row._id, row.StatusOrderDetails);
    }
  }}
  className={`btn-eyesicon ${row.IsMakeDefault ? "disabled-delete" : ""}`} // Add class for styling if disabled
 title="Delete"
  style={{
    cursor: row.IsMakeDefault ? "not-allowed" : "pointer",
    pointerEvents: row.IsMakeDefault ? "none" : "auto", // Prevent click if IsMakeDefault is true
  }}
>
  <i className="la flaticon-delete-1 delete-icon"></i>
</a>
                    <a
                                                                            onClick={() => {
                                                                                CopyBtn(row._id);
                                                                            }}
                                                                            className="btn-eyesicon"
                                                                            title="Duplicate"
                                                                        >
                                                                            <i className="la flaticon-add add-icon edit-icon"></i>
                                                                        </a>
                                                                       
                                                                        {row.IsMakeDefault ? (
  <a
    onClick={() => {
      const defaultCount = Rows?.filter((item) => item.IsMakeDefault === true).length;
      
      if (defaultCount > 1) {
        MakeDefaultBtn(row._id); // Allow action if more than 1 default exists
      }
    }}
           title="Make Default"
    style={{
      cursor: Rows?.filter((item) => item.IsMakeDefault === true).length === 1 ? "not-allowed" : "pointer",
      pointerEvents: Rows?.filter((item) => item.IsMakeDefault === true).length === 1 ? "none" : "auto",
    }}
  >
    <StarIcon color={Rows?.filter((item) => item.IsMakeDefault === true).length === 1 ? "disabled" : "primary"} />
  </a>
) : (
  <a
    onClick={() => {
      MakeDefaultBtn(row._id);
    }}
           title="Make Default"
  >
    <StarIcon color="secondary" />
  </a>
)}
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      </div>
     
        <div className="row pb-2 mx-0">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
        </div></>)  
        }
   
    </>
  );
}