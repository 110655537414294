import React from 'react';
import { connect } from 'react-redux';
import Axios from "axios";
import { toast } from "react-toastify";

import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Accountcategory from '../../_components/clients/accountclientstatus/accountcategory/accountcategory';
//import CollapsibleTable from '../_components/usertable';
import Box from "@material-ui/core/Box";
const Fileupload = require("../../_helpers/fileupload");
import Footer from "../../_components/user/footer/footer";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import loadingicon from "../../images/loading.gif";



class CAccountCategoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      Columns: [],
      Reset: false,
      CsvData: [],
      DropBoxData: [],
      FileName: "",
      ExportData: [],
      CName: "",
      TotalDisplayCount: false,
      TotalResult: 0,
      TotalDuplicate: 0,
      TotalError: 0,
      TotalSuccess: 0,
      Role: null,
      ListUpdate: false,
      DropBoxData2: [],
      Categories: "not mapped",
      Domain: "not mapped",
      CategoryIndex: -1,
      DomainIndex: -1,
      RawDropDownData:[],
      BtnDisabled:false,
      UpdateKey:0
    };
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.SaveCsv = this.SaveCsv.bind(this);
    this.AddAccountCategory = this.AddAccountCategory.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
    this.ReloadPage = this.ReloadPage.bind(this);
  }

  componentDidMount() {
      window.addEventListener('storage', (event) => {
              
        if (event.key === 'clientChanged') {
          window.location.reload();
        }
      });
  
    document.getElementById("hidelod").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.PUserID = Details.ParentUserID;
      this.state.Role = Details.Role;
    }

    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

    var InputParameter = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_categories/AccountCategoriesExport",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      this.setState({ ExportData: Res.data });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //this.loadPage();
  }
  //Reload data
  ReloadPage() {
    this.setState({ ListUpdate: !this.state.ListUpdate });
  }
  //reset csv
  ResetCsv() {
    this.setState({ Reset: !this.state.Reset });
  }

  //change dropdown
  // HandleOnDrop(Data, FileInfo) {
  //   var FileName = FileInfo.name;
  //   this.setState({ Files: FileInfo });
  //   var sep = FileName.split(".");
  //   if (sep[1] != "csv") {
  //     this.setState({ Reset: true });
  //     this.ResetCsv();
  //   } else {
  //     var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
  //     this.setState({ FileName: csvfile });
  //     const filteredData = Data.filter(row => !row.data.every(item => item.trim() === ''))
  //     
  //     this.setState({ CsvData: filteredData });
  //     this.setState({ RawDropDownData: filteredData[0].data });
  //     this.setState({ DropBoxData: filteredData[0].data });
  //     this.setState({ DropBoxData2: filteredData[0].data });
  //     
  //     if (filteredData[0].data.includes('Account Category')) {
  //       this.setState({Categories: "Account Category"})
  //     } else {
  //       this.setState({Categories: "not mapped"})
  //     }

  //     if (filteredData[0].data.includes('Email Domain')) {
  //       this.setState({Domain: "Email Domain"})
  //     } else {
  //       this.setState({Domain: "not mapped"})
  //     }
  //   }
  // }
  HandleOnDrop(Data, FileInfo) {
    const { name } = FileInfo;
    const FileName = name;
    const sep = FileName.split(".");
    
    this.setState({ Files: FileInfo });
  
    if (sep[sep.length-1].toLowerCase() !== "csv") {
      this.setState({ Reset: true });
      this.ResetCsv();
    } else {
      const csvfile = `${sep.slice(0, -1)}${new Date().getTime()}.${sep[sep.length-1]}`;
      this.setState({
        FileName: csvfile,
        Reset: false,
      });
  
      const filteredData = Data.filter(row => !row.data.every(item => item.trim() === ''));
      let DropDownData = filteredData[0]?.data.filter(item => item !== "");

      
      this.setState({
        CsvData: filteredData,
        RawDropDownData: DropDownData || [],
        DropBoxData: DropDownData || [],
        DropBoxData2: DropDownData || [],
        Categories: DropDownData.includes('Account Category') ? 'Account Category' : 'not mapped',
        Domain: DropDownData.includes('Email Domain') ? 'Email Domain' : 'not mapped',
      });
     
      if(DropDownData.includes('Account Category') || this.state.DropBoxData.findIndex((item) =>new RegExp("^\\s*Account\\s+Category\\s*$", "i").test(item.trim().toLowerCase()))){
        let AccountCategoryIndex =  this.state.DropBoxData.findIndex((item) =>new RegExp("^\\s*Account\\s+Category\\s*$", "i").test(item.trim().toLowerCase()))? this.state.DropBoxData.findIndex((item) =>
        new RegExp("^\\s*Account\\s+Category\\s*$", "i").test(item.trim().toLowerCase())
      ) : -1
        let MainState = DropDownData;
        
        this.setState({ CategoryIndex: AccountCategoryIndex });
        this.setState({ Categories: MainState[AccountCategoryIndex] });
        const filteredItems = MainState.filter((item) => item !== MainState[AccountCategoryIndex]);
        this.setState({ DropBoxData2: filteredItems });
      }
      if(DropDownData.includes('Email Domain') || this.state.DropBoxData.findIndex((item) =>new RegExp("^\\s*Email\\s+Domain\\s*$", "i").test(item.trim().toLowerCase()))){
        var EmailDomainIndex =this.state.DropBoxData.findIndex((item) =>new RegExp("^\\s*Email\\s+Domain\\s*$", "i").test(item.trim().toLowerCase()))? this.state.DropBoxData.findIndex((item) =>
        new RegExp("^\\s*Email\\s+Domain\\s*$", "i").test(item.trim().toLowerCase())
      ) : -1
        let MainState = DropDownData;
        
        this.setState({ DomainIndex:EmailDomainIndex });
        this.setState({ Domain: MainState[EmailDomainIndex]});
        const filteredItems = MainState.filter((item) => item !== MainState[EmailDomainIndex]);
        this.setState({ DropBoxData: filteredItems });
      }
      
    }
  }
  

  HandleOnError(Err, File, InputElem, Reason) {}

  HandleOnRemoveFile(Data) {}

    //Hide used option
    //  hideCSVHeaderOptions = () => {
    //   var $select = $(".csvcontent");
    //   $select.find("option").show();
    //   $select.each(function () {
    //     var $this = $(this);
    //     var value = $this.val();
    //     var $options = $this
    //       .parents("#divAccountSelection")
    //       .find(".csvcontent")
    //       .not(this)
    //       .find("option");
    //     var $option = $options.filter('[value="' + value + '"]');
    //     if (value) {
    //       $option.hide();
    //     }
    //   });
    // };
  
    // $("select").on("change", function () {
    //   var $this = $(this);
    //   var value = $this.val();
    //   var Name = $(this).attr("id");
      
    //   if (Name == "Account Category") {
    //     SetAccountName(value);
    //   } else if (Name == "EmailDomains") {
    //     SetAccountNameLong(value);
    //   } else if (Name == "EmailDomains") {
    //     SetEmailDomains(value);
    //   } etEmployeeCount(value);
    //   }
    //   this.hideCSVHeaderOptions();
    // });

  //import csv
  ImportCsv() {
    this.setState({ ListUpdate: !this.state.ListUpdate });
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("AccountCategory").disabled = false;
    document.getElementById("EmailDomain").disabled = false;
    this.setState({BtnDisabled:false})
    this.setState({CsvData:[]})
    this.setState({ Reset: false });
  }

  //map csv
  MapCsv() {
    document.getElementById("hidelod").style.display = "block";
    if (this.state.CsvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      document.getElementById("AccountCategory").disabled = false;
      document.getElementById("EmailDomain").disabled = false;
      this.setState({BtnDisabled:false})
      // this.setState({CategoryIndex:-1})
      // this.setState({DomainIndex:-1})
      // this.setState({Categories:"not mapped"});
      // this.setState({Domain:"not mapped"});
      this.setState({ TotalDisplayCount: false });
      this.state.DropBoxData.map((dddd, index) => {
        this.ResetCsv();
        document.getElementById("hidelod").style.display = "none";
      });
    } else {
      this.ResetCsv();
      toast.error(<div className="toastsize">Please select file.</div>)
      document.getElementById("hidelod").style.display = "none";
    }
  }

  //cancle Csv
  CancleCsv() {
    this.ResetCsv();
    this.setState({ TotalDisplayCount: false });
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("AccountCategory").disabled = false;
    document.getElementById("EmailDomain").disabled = false;
    this.setState({BtnDisabled:false})
  }

  //add account category
  SaveCsv() {
    this.setState({BtnDisabled:true})
    var AccountCategory = document.getElementById("AccountCategory").value;
    var EmailDomain = document.getElementById("EmailDomain").value;
    
    if (this.state.CategoryIndex == -1) {
      toast.error(
        <div className="toastsize">
          SalesHive AccountCategory
          <br />
          Account Category is required!
        </div>
      );
    this.setState({BtnDisabled:false})

    } else {
      document.getElementById("hidelod").style.display = "block";
      Fileupload.getBase64(this.state.Files, (Result) => {
        var RreglarString = Result;
        var Splirs = RreglarString.split("base64,");
        var Base64 = Splirs[1];
        var InputParameter = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserAccountCategories",
          File: Base64,
          ImageName: this.state.FileName,
          FileData: this.state.CsvData,
          Name: this.state.CategoryIndex,
          Email:this.state. DomainIndex,
          ClientID: this.state.ClientID,
          UserID: this.state.PUserID,
          CreatedDate:new Date(),
          Role:this.state.Role
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/account_categories/ImportCSVListData",
          method: "POST",
          data: InputParameter,
        }).then((Res) => {
          
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                SalesHive Account Category
                <br />
                SalesHive AccountCategories added successfully.
              </div>
            );
            // history.push("/list");

            this.setState({ ListUpdate: true });
            document.getElementById("hidelod").style.display = "none";
            this.setState({ TotalDisplayCount: true });
            this.setState({ TotalResult: Res.data.Total });
            this.setState({ TotalDuplicate: Res.data.TotalDuplication });
            this.setState({ TotalError: Res.data.TotalError });
            this.setState({ TotalSuccess: Res.data.TotalSuccess });
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_csv").style.display = "block";
            document.getElementById("AccountCategory").disabled = true;
            document.getElementById("EmailDomain").disabled = true;
            this.setState({BtnDisabled:true})
            this.setState({UpdateKey:this.state.UpdateKey + 1})
            this.setState({ CsvData: [] });
            //this.ReloadPage();
          } else {
            toast.error(Res.data.Message);
            this.setState({BtnDisabled:true})

          }
        });
      });
    }
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  //Add function
  AddAccountCategory() {
    history.push("/CAddAccountCategory");
  }
  ExportCsv() {
    toast.success("Data exported successfully.");
  }

  //Account category map dropdown
  AccountCategoryDropDownHandler() {
    var AccountCategoryIndex = document.getElementById("AccountCategory").value;
    let MainState = this.state.RawDropDownData;
    this.setState({ CategoryIndex: MainState.indexOf(AccountCategoryIndex) });
    this.setState({ Categories: AccountCategoryIndex });
    const filteredItems = MainState.filter((item) => item !== AccountCategoryIndex);
    
    this.setState({ DropBoxData2: filteredItems });
  }
//Email Domain map dropdown
EmailDomainDropDownHandler() {
  var EmailDomainIndex = document.getElementById("EmailDomain").value;
  let MainState = this.state.RawDropDownData;
  this.setState({ DomainIndex: MainState.indexOf(EmailDomainIndex) });
  this.setState({ Domain: EmailDomainIndex});
  
  const filteredItems = MainState.filter((item) => item !== EmailDomainIndex);
  

  this.setState({ DropBoxData: filteredItems });
}

  render() {
    

    return (
      <>
        <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle ">
                  Account Categories
                </h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right pb-1">
                  <ul className="px-2 my-0">
                    <li className="px-0">
                      <a
                        onClick={this.ImportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      <CSVDownloader
                        className="px-0"
                        data={this.state.ExportData}
                        filename={this.state.CName + "-AccountCategory"}
                        bom={true}
                      >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i className="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li className="px-0">
                      <a
                        onClick={this.AddAccountCategory}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="whiterow border-bottom-0 bg-white py-4" id="import_csv">
              <div className="row pt-3">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Import Account Category
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      className="px-0"
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={this.HandleOnDrop}
                      onError={this.HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={this.HandleOnRemoveFile}
                      isReset={this.state.Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add account category data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 ">
                  <div className="alignright">
                    <a
                      onClick={this.MapCsv}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a onClick={this.CancleCsv} className="btn btn-secondary">
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="portletbody p-0 my-0 border-bottom-0 pb-3 bg-white mt-0" id="map_attribute">
              <div className="row col-xs-12 mx-0">
              <h4 className="headertitle xs-headertitle float-left pl-0">Map attributes</h4>
              </div>
              <div className="col-xs-12" id="divAccountSelection">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="AccountCategory">
                          Account Category
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="AccountCategory"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        onChange={() => {
                          this.AccountCategoryDropDownHandler();
                        }}
                        value={this.state.Categories}
                      >
                        <option value="AccountCategorymapped">
                          Not Mapped
                        </option>
                        {this.state.DropBoxData.map((dbdata, index) => (
                          <option key={index} value={dbdata}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="EmailDomain">
                          Email Domain
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x"></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="EmailDomain"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        onChange={() => {
                          this.EmailDomainDropDownHandler();
                        }}
                        value={this.state.Domain}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {this.state.DropBoxData2.map((dbdata, index) => (
                          <option value={dbdata}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                  disabled={this.state.BtnDisabled}
                    onClick={this.SaveCsv}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>

            {this.state.TotalDisplayCount == true ? (
              <div className="portletbody px-4" id=" ">
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Results</h4>
                </div>

                <div className="col-xs-12">
                  <div className="row colfive">
                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-primary">
                                {this.state.TotalResult}
                              </span>
                            </div>
                            <div className="box-inner-title">
                              <string className="clr-sky">Total</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-success">
                                {this.state.TotalSuccess}
                              </span>
                            </div>
                            <div className="box-inner-title">
                              <string className="clr-sky">Total success</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-danger">
                                {this.state.TotalError}
                              </span>
                            </div>
                            <div className="box-inner-title">
                              <string className="clr-sky">Total error</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-warning">
                                {this.state.TotalDuplicate}
                              </span>
                            </div>
                            <div className="box-inner-title">
                              <string className="clr-sky">Total Duplicate</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="paddcols bg-white">
              <Box className="px-3">
                <div className="row">
                  <div className="col">
                    <Accountcategory
                    key={this.state.UpdateKey}
                      listupdate={this.state.ListUpdate}
                      updateFromChild={this.UpdateFromChild}
                    />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCAccountCategoryPage = connect(mapState, actionCreators)(CAccountCategoryPage);
export { connectedCAccountCategoryPage as CAccountCategoryPage };