import React from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'
import Footer from '../../_components/user/footer/footer'
import EmailAccountTable from '../../_components/clients/emailaccounts/EmailAccountTable'
import CollapsibleTable from '../../_components/usertable'

import { history } from '../../_helpers'

class CEmailAccountsPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ImportProps: false,
      Images: [],
      maxNumber: 69
    }
  }
  componentDidMount() {
    window.addEventListener('storage', (event) => {
            
      if (event.key === 'clientChanged') {
        window.location.reload();
      }
    });
}

  onChange = (imageList, addUpdateIndex) => {
    this.setState({ Images: imageList })
  }

  hideimportcsvaccount () {
    this.setState({ ImportProps: false })
    document.getElementById('import_account').style.display = 'none'
  }

  showimportcsvaccount () {
    this.setState({ ImportProps: true })
    document.getElementById('import_account').style.display = 'block';
    document.getElementById("total_div_display").style.display = "none";
  }

  // email account page
  EmailAccountPage () {
    history.push('/CAddEmailAccounts')
  }

  render () {
    return (
      <>
        <div className='adminmain mheight'>
          <Sidebar className='' />

          <div className='bodyhome'>
            <div className='row align-items-center'>
              <div className='col'>
                <h4 className='headertitle lg-headertitle pl-0 mb-0'>Email Accounts</h4>
              </div>
              <div className='col'>
                <div className='listing-li float-right padb-15'>
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          this.showimportcsvaccount()
                        }}
                        className='btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air'
                      >
                        <i class='la la-icon-font-size-13 la-upload'></i>
                        <span>Import</span>
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => {
                          this.EmailAccountPage()
                        }}
                        className='btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air'
                      >
                        <i class='la la-icon-font-size-13 la-plus'></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class='bg-white'>
              <div className='row mx-0 pb-3'>
                <div className='col px-0'>
                  <EmailAccountTable ImportOpenCsv={this.state.ImportProps} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.authentication
  return { loggingIn }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedCEmailAccountsPage = connect(
  mapState,
  actionCreators
)(CEmailAccountsPage)
export { connectedCEmailAccountsPage as CEmailAccountsPage }
